import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment'
import { ClickStream } from '../models/click-stream';
import { apiEndpointURI, guestApiEndPointURL } from '../utilities/constants/api-endpoints-uri';
import { DeviceDetectorService } from 'ngx-device-detector';
import { clickStreamConsts } from '../utilities/constants/click-stream-const';
import { CommonService } from './common.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClickstreamService {
  CLICK_STREAM_URL: string = environment.API_END_POINT + apiEndpointURI.getClickStreamURI;
  CLICK_STREAM_GUEST_URL = environment.API_END_POINT + guestApiEndPointURL.getClickStreamURI;
  CLICK_STREAM_SOCIAL_LOGIN_URL = environment.API_END_POINT + guestApiEndPointURL.clickstreamSocialLogingURI;

  constructor(
    public http: HttpClient,
    private deviceService: DeviceDetectorService,
    private commonService: CommonService
  ) { }

  getDeviceInfo(): ClickStream {
    const deviceInfo = new ClickStream();
    deviceInfo.mobileDeviceModel = this.deviceService.getDeviceInfo().device;
    deviceInfo.screenResolution = String(window.innerWidth);
    deviceInfo.isMobileUser = this.deviceService.isMobile ? 'Y' : 'N';
    deviceInfo.mobileDeviceOS = this.deviceService.getDeviceInfo().os;
    return deviceInfo;
  }

  getUserInfo(): ClickStream {
    const userId = localStorage.getItem('userid') || '';
    const userInfo = new ClickStream();
    userInfo.userloggedIn = userId ? 'Y' : 'N';
    userInfo.appFlag = 'Y';
    return userInfo;
  }

  getPageInfo(): ClickStream {
    const pageInfo = new ClickStream();
    pageInfo.pageName = localStorage.getItem('currentPageName');
    pageInfo.sourcePageName = localStorage.getItem('previousPageName');
    return pageInfo;
  }

  shortListClickstreamLogging(
    sectionName?: string,
    functionalityName?: string,
    idpcInfo?: any
  ): Subscription {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    clickstream.sectionName = sectionName || undefined;
    clickstream.functionalityName = functionalityName || undefined;
    clickstream.actionType = clickStreamConsts.interaction;
    clickstream.eventType = clickStreamConsts.shortListed;
    clickstream.CTATitle = clickStreamConsts.ctaTitleFav;
    clickstream.sourcePageName = this.commonService.previousPageName;
    clickstream.idpcCourseId = idpcInfo?.courseId || undefined;
    clickstream.idpcCourseName = idpcInfo?.courseTitle || undefined;
    clickstream.idpcInstitutionId = idpcInfo?.institutionId || undefined;
    clickstream.idpcInstitutionName = idpcInfo?.institutionName || undefined;
    return this.clickStreamLogging(clickstream).subscribe();
  }

  toolTipClickstreamLogging(clickstreamInfo: any): Subscription {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    clickstream.sectionName = clickstreamInfo.sectionName;
    clickstream.functionalityName = clickstreamInfo.functionalityName;
    clickstream.CTATitle = clickstreamInfo.CTATitle;
    clickstream.eventType = clickStreamConsts.tooltipClicked;
    clickstream.actionType = clickStreamConsts.interaction;
    clickstream.sourcePageName = this.commonService.previousPageName;
    return this.clickStreamLogging(clickstream).subscribe();
  }

  shareClickstreamLogging(idpcInfo): Subscription {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    //clickstream.sectionName = clickstreamInfo.sectionName;
    // clickstream.functionalityName = clickstreamInfo.functionalityName;
    clickstream.CTATitle = clickStreamConsts.shareLabel;
    clickstream.eventType = clickStreamConsts.buttonClicked;
    clickstream.actionType = clickStreamConsts.interaction;
    clickstream.sourcePageName = this.commonService.previousPageName;
    clickstream.idpcCourseId = idpcInfo?.courseId || undefined;
    clickstream.idpcCourseName = idpcInfo?.courseTitle || undefined;
    clickstream.idpcInstitutionId = idpcInfo?.idpcInstitutionId || idpcInfo?.institutionId || undefined;
    clickstream.idpcInstitutionName = idpcInfo?.idpcInstitutionName || idpcInfo?.institutionName || undefined;
    return this.clickStreamLogging(clickstream).subscribe();
  }

  clickStreamLogForIP(institutionInfo: any) {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    clickstream.sectionName = undefined;
    clickstream.functionalityName = undefined;
    clickstream.actionType = clickStreamConsts.engagement;
    clickstream.eventType = clickStreamConsts.institutionProfileViewed;
    clickstream.idpcInstitutionId = institutionInfo?.idpcInstitutionId || undefined;
    clickstream.sourcePageName = this.commonService.previousPageName;
    clickstream.orderItemId = institutionInfo?.orderItemId || undefined;
    clickstream.subOrderItemId = institutionInfo?.subOrderItemId || undefined;
    const clickstream$ = this.clickStreamLogging(clickstream).subscribe().add(() => {
      clickstream$?.unsubscribe();
    })
  }

  visitWebsiteClickStreamLogging(CTATitle?, enquiryDetails?): Subscription {
    const clickstreamInfo = new ClickStream();
    clickstreamInfo.pageName = this.commonService.currentPageName;
    clickstreamInfo.sectionName = '';
    clickstreamInfo.functionalityName = '';
    clickstreamInfo.actionType = clickStreamConsts.interaction;
    clickstreamInfo.CTATitle = CTATitle || undefined;
    clickstreamInfo.eventType = clickStreamConsts.visitWebsite;
    clickstreamInfo.subOrderItemId = enquiryDetails?.institutionDetails?.subOrderItemId || undefined;
    clickstreamInfo.idpcCourseId = enquiryDetails?.courseId || undefined;
    clickstreamInfo.idpcCourseName = enquiryDetails?.courseName || undefined;
    clickstreamInfo.idpcInstitutionId = enquiryDetails?.institutionDetails?.institutionId || undefined;
    return this.clickStreamLogging(clickstreamInfo).subscribe();
  }

  helpfulClickStreamLogging(CTATitle?, institutionId?, courseId?): Subscription {
    const clickstreamInfo = new ClickStream();
    clickstreamInfo.pageName = this.commonService.currentPageName;
    clickstreamInfo.sectionName = '';
    clickstreamInfo.functionalityName = 'Read Reviews';
    clickstreamInfo.actionType = clickStreamConsts.interaction;
    clickstreamInfo.CTATitle = CTATitle || undefined;
    clickstreamInfo.eventType = clickStreamConsts.buttonClicked;
    clickstreamInfo.sourcePageName = this.commonService.previousPageName;
    //clickstreamInfo.subOrderItemId = enquiryDetails?.institutionDetails?.subOrderItemId || undefined;
    clickstreamInfo.idpcCourseId = courseId || undefined;
    //clickstreamInfo.idpcCourseName = enquiryDetails?.courseName || undefined;
    clickstreamInfo.idpcInstitutionId = institutionId || undefined;
    return this.clickStreamLogging(clickstreamInfo).subscribe();
  }

  bookEventClickStreamLogging(eventType: string, CTATitle?: string, uniCourseInfo?: any): Subscription {
    const clickstreamInfo = new ClickStream();
    clickstreamInfo.pageName = this.commonService.currentPageName;
    clickstreamInfo.sourcePageName = this.commonService.previousPageName;
    clickstreamInfo.sectionName = clickStreamConsts.functionalityName.openDay;
    clickstreamInfo.functionalityName = clickStreamConsts.functionalityName.openDay;
    clickstreamInfo.actionType = clickStreamConsts.interaction;
    clickstreamInfo.institutionCountryName = clickStreamConsts.institutionCountryName;
    clickstreamInfo.isAdvertiser = "Y";

    clickstreamInfo.eventType = eventType;
    clickstreamInfo.CTATitle = CTATitle || undefined;

    clickstreamInfo.orderItemId = uniCourseInfo?.orderItemId || undefined;
    clickstreamInfo.subOrderItemId = uniCourseInfo?.subOrderItemId || undefined;
    clickstreamInfo.idpcCourseId = uniCourseInfo?.idpcCourseId || undefined;
    clickstreamInfo.idpcCourseName = uniCourseInfo?.idpcCourseName || undefined;
    clickstreamInfo.idpcInstitutionId = uniCourseInfo?.idpcInstitutionId || uniCourseInfo?.institutionId || undefined;
    clickstreamInfo.studyLevelId = uniCourseInfo?.studyLevelId || undefined;
    clickstreamInfo.studyLevel = uniCourseInfo?.studyLevel || undefined;
    clickstreamInfo.eventId = uniCourseInfo?.eventId || undefined;
    clickstreamInfo.interestedIntakeYear = uniCourseInfo?.interestedIntakeYear || undefined;
    return this.clickStreamLogging(clickstreamInfo).subscribe();
  }

  enquiryFormClickedClickstreamLogging(
    eventType?: string,
    CTATitle?: string,
    enquiryDetails?: any
  ) {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    clickstream.sectionName = '';
    clickstream.functionalityName = '';
    clickstream.actionType = clickStreamConsts.interaction;
    clickstream.eventType = eventType || undefined;
    clickstream.CTATitle = CTATitle || undefined;
    clickstream.sourcePageName = this.commonService.previousPageName;
    clickstream.subOrderItemId = enquiryDetails?.institutionDetails?.subOrderItemId || enquiryDetails?.subOrderItemId || undefined;
    clickstream.idpcCourseId = enquiryDetails?.courseId || undefined;
    clickstream.idpcCourseName = enquiryDetails?.courseName || undefined;
    clickstream.idpcInstitutionId = enquiryDetails?.institutionDetails?.institutionId || enquiryDetails?.institutionId || undefined;
    return this.clickStreamLogging(clickstream).subscribe();
  }

  enquiryFormSubmittedClickstreamLogging(
    CTATitle?: string,
    clickStreamCourseInfo?: any
  ) {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    clickstream.sectionName = '';
    clickstream.functionalityName = '';
    clickstream.actionType = clickStreamConsts.interaction;
    clickstream.eventType = clickStreamConsts.enquiryFormSubmitted;
    clickstream.CTATitle = CTATitle || '';
    clickstream.interestedIntakeMonth = '';
    clickstream.interestedIntakeYear = clickStreamCourseInfo?.interestedIntakeYear || '';
    clickstream.studyLevelId = clickStreamCourseInfo?.studyLevelId;
    clickstream.studyLevel = clickStreamCourseInfo?.studyLevel;
    clickstream.childStudyLevelId = '';
    clickstream.childStudyLevel = '';
    clickstream.ldcs1Code = clickStreamCourseInfo?.ldcs1;
    clickstream.ldcs1CodeDesc = clickStreamCourseInfo?.ldcs1CodeDesc;
    clickstream.ldcs2Code = clickStreamCourseInfo?.ldcs2;
    clickstream.ldcs2CodeDesc = clickStreamCourseInfo?.ldcs2CodeDesc;
    clickstream.ldcs3Code = clickStreamCourseInfo?.ldcs3;
    clickstream.ldcs3CodeDesc = clickStreamCourseInfo?.ldcs3CodeDesc;
    clickstream.browseNodeSubjects = '';
    clickstream.countryOfResidence = '';
    clickstream.countryOfCitizenship = '';
    clickstream.surveyConsent = '';
    clickstream.newsletterConsent = '';
    clickstream.solusConsent = '';
    clickstream.subOrderItemId = clickStreamCourseInfo?.subOrderItemId || undefined;
    clickstream.idpcCourseId = clickStreamCourseInfo?.courseId || undefined;
    clickstream.idpcCourseName = clickStreamCourseInfo?.courseName || undefined;
    clickstream.idpcInstitutionId = clickStreamCourseInfo?.institutionId || undefined;

    clickstream.sourcePageName = this.commonService.previousPageName;
    return this.clickStreamLogging(clickstream).subscribe();
  }

  clickStreamLoggingForCD(eventType: string, clickStreamCourseInfo?: any): Subscription {
    const clickstream = new ClickStream();
    switch (eventType) {
      case clickStreamConsts.courseViewed:    //CourseViewed
        clickstream.pageName = this.commonService.currentPageName;
        //clickstream.sectionName = '';
        //clickstream.functionalityName = '';
        clickstream.actionType = clickStreamConsts.engagement;
        clickstream.eventType = clickStreamConsts.courseViewed;
        clickstream.idpcInstitutionId = clickStreamCourseInfo?.idpcInstitutionId;
        clickstream.idpcCourseId = clickStreamCourseInfo?.idpcCourseId;
        clickstream.idpcCourseName = clickStreamCourseInfo?.idpcCourseName;
        clickstream.ldcs1Code = clickStreamCourseInfo?.ldcs1;
        clickstream.ldcs1CodeDesc = clickStreamCourseInfo?.ldcs1CodeDesc;
        clickstream.ldcs2Code = clickStreamCourseInfo?.ldcs2;
        clickstream.ldcs2CodeDesc = clickStreamCourseInfo?.ldcs2CodeDesc;
        clickstream.ldcs3Code = clickStreamCourseInfo?.ldcs3;
        clickstream.ldcs3CodeDesc = clickStreamCourseInfo?.ldcs3CodeDesc;
        clickstream.browseNodeSubjects = '';
        clickstream.studyLevelId = clickStreamCourseInfo?.studyLevelId;
        clickstream.studyLevel = clickStreamCourseInfo?.studyLevel;
        clickstream.sourcePageName = this.commonService.previousPageName;
        break;
    }
    return this.clickStreamLogging(clickstream).subscribe(); // Click Stream Logging
  }

  clickStreamLoggingForSR(eventType: string, clickstream: ClickStream, clickstreamLog?: any, filterByObj?: any, sectionName?: string): Subscription {
    const pageInfo = new ClickStream();
    pageInfo.pageName = clickStreamConsts.searchResultPage;
    pageInfo.sortOption = clickstreamLog?.sortBy;
    pageInfo.searchKeyword = clickstream?.searchKeyword;
    pageInfo.studyLevelId = clickstreamLog?.studyLevelId;
    pageInfo.studyLevel = clickstreamLog?.studyLevelDesc;
    pageInfo.studyModeId = clickstreamLog?.studyModeId;
    pageInfo.studyMode = clickstreamLog?.studyModeDesc;
    pageInfo.paginationNumber = clickstream?.paginationNumber;
    pageInfo.sourcePageName = this.commonService?.previousPageName;
    //////---- For Future use----------//////
    // pageInfo.CREDestinations = '';
    pageInfo.CRERegionCity = clickstreamLog?.CRERegionCity;
    switch (eventType) {
      case clickStreamConsts.searchResultsViewed: //search Result Viewed
        pageInfo.sectionName = sectionName;
        pageInfo.functionalityName = sectionName === clickStreamConsts.courseTap ? clickStreamConsts?.functionalityCourseSR : clickStreamConsts.functionalityUniversitySR;
        pageInfo.eventType = clickStreamConsts.searchResultsViewed;
        pageInfo.actionType = clickStreamConsts.engagement;
        pageInfo.locationType = clickstreamLog?.locationTypeDesc;
        pageInfo.UCASPoints = filterByObj?.score;
        pageInfo.SRInstitutionIDOrder = clickstream?.SRInstitutionIDOrder;
        //pageInfo.UCASCode = '';
        //pageInfo.viewedPercentage = '';
        break;

      case clickStreamConsts.providerResultsViewed: //provider Result Viewed
        pageInfo.sectionName = sectionName;
        pageInfo.functionalityName = clickStreamConsts?.functionalityProviderR;
        pageInfo.eventType = clickStreamConsts.providerResultsViewed;
        pageInfo.actionType = clickStreamConsts.engagement;
        //pageInfo.idpInstitutionId = '';
        pageInfo.idpcInstitutionId = clickstreamLog?.idpcInstitutionId;
        pageInfo.idpcInstitutionName = clickstreamLog?.idpcInstitutionName;
        //pageInfo.viewedPercentage = '';
        break;
    }
    return this.clickStreamLogging(pageInfo).subscribe(); // Click Stream Logging
  }

  clickStreamLoggingForEvents(clickstreamLog?: any): Subscription {
    const pageInfo = new ClickStream();
    pageInfo.eventType = clickStreamConsts.pageViewed;
    pageInfo.sortOption = clickstreamLog?.sortBy;
    pageInfo.studyLevelId = clickstreamLog?.studyLevelId;
    pageInfo.studyLevel = clickstreamLog?.studyLevelDesc;
    pageInfo.paginationNumber = clickstreamLog?.paginationNumber;

    pageInfo.sourcePageName = this.commonService?.previousPageName;
    pageInfo.pageName = clickStreamConsts.opendayLandingPage;

    pageInfo.locationType = clickstreamLog?.locationTypeDesc;
    pageInfo.eventMode = clickstreamLog?.eventMode;
    pageInfo.month = clickstreamLog?.month;
    pageInfo.year = clickstreamLog?.year;
    pageInfo.CRERegionCity = clickstreamLog?.CRERegionCity;
    return this.clickStreamLogging(pageInfo).subscribe(); // Click Stream Logging
  }

  onBoardingClickstreamLogging(
    sectionName?: string,
    functionalityName?: string,
    CTATitle?: string,
    cs?: ClickStream,
    eventType?: string
  ) {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    clickstream.sectionName = sectionName || undefined;
    clickstream.functionalityName = functionalityName || undefined;
    clickstream.actionType = clickStreamConsts.interaction;
    clickstream.eventType = eventType || clickStreamConsts.buttonClicked;
    clickstream.CTATitle = CTATitle || undefined;
    clickstream.pushNotificationConsent = cs?.pushNotificationConsent;
    clickstream.interestedIntakeYear = cs?.interestedIntakeYear;
    clickstream.interestedStudyLevel = cs?.interestedStudyLevel;
    clickstream.interestedSubjectArea = cs?.interestedSubjectArea;
    clickstream.sourcePageName = this.commonService.previousPageName;
    clickstream.newsletterConsent = cs?.newsletterConsent || undefined;
    clickstream.solusConsent = cs?.solusConsent || undefined;
    clickstream.surveyConsent = cs?.surveyConsent || undefined;
    clickstream.idpcInstitutionId = cs?.idpcInstitutionId || undefined;
    clickstream.idpcCourseId = cs?.idpcCourseId || undefined;
    clickstream.idpcCourseName = cs?.idpcCourseName || undefined;
    clickstream.institutionCountryName = cs?.institutionCountryName || clickStreamConsts?.institutionCountryName || undefined;
    clickstream.eventId = cs?.eventId || undefined;
    clickstream.orderItemId = cs?.orderItemId || undefined;
    clickstream.subOrderItemId = cs?.subOrderItemId || undefined;
    clickstream.isAdvertiser = cs?.isAdvertiser || undefined;
    clickstream.studyLevel = cs?.studyLevel || undefined;
    clickstream.studyLevelId = cs?.studyLevelId || undefined;
    const clickstream$ = this.clickStreamLogging(clickstream).subscribe().add(() => {
      clickstream$.unsubscribe();
    });
  }

  pageViewedClickstreamLogging(
    sectionName?: string,
    functionalityName?: string,
    CTATitle?: string,
    SRInstitutionIDOrder?: string
  ): Subscription {
    const clickstream = new ClickStream();
    clickstream.sectionName = sectionName || undefined;
    clickstream.functionalityName = functionalityName || undefined;
    clickstream.actionType = clickStreamConsts.engagement;
    clickstream.eventType = clickStreamConsts.pageViewed;
    clickstream.CTATitle = CTATitle || undefined;
    clickstream.SRInstitutionIDOrder = SRInstitutionIDOrder || undefined;
    clickstream.pageName = this.commonService.currentPageName || localStorage.getItem('currentPageName');
    clickstream.sourcePageName = this.commonService.previousPageName || localStorage.getItem('previousPageName');
    return this.clickStreamLogging(clickstream).pipe(take(1)).subscribe();
  }

  viewedClickstreamLogging(
    sectionName?: string,
    functionalityName?: string,
    pageName?: string,
    CTATitle?: string,
    careerCat?: string,
    careerSubCat?: string,
    matchCatPercent?: string,
    matchSubCatPercent?: string,
    sortOption?: string,
    jobProfileName?: string,
    jobProfileMatchPercent?: string
  ): Subscription {
    const clickstream = new ClickStream();
    clickstream.pageName = this.commonService.currentPageName;
    clickstream.sectionName = sectionName || undefined;
    clickstream.functionalityName = functionalityName || undefined;
    clickstream.actionType = clickStreamConsts.engagement;
    clickstream.eventType = pageName || clickStreamConsts.pageViewed;
    clickstream.CTATitle = CTATitle || undefined;
    clickstream.careerParentCategory = careerCat || undefined;
    clickstream.careerChildCategory = careerSubCat || undefined;
    clickstream.carrerParentCategoryMatchPercent = matchCatPercent || undefined;
    clickstream.careerChildCategoryMatchPercent = matchSubCatPercent || undefined;
    clickstream.sourcePageName = this.commonService.previousPageName;
    clickstream.sortOption = sortOption || undefined;
    clickstream.jobProfileName = jobProfileName || undefined;
    clickstream.jobProfileMatchPercent = jobProfileMatchPercent || undefined;
    return this.clickStreamLogging(clickstream).subscribe();
  }

  signUpClickstreamLogging(): Subscription {
    const clickstreamInfo = new ClickStream();
    clickstreamInfo.pageName = clickStreamConsts.signUpPageTwo;
    //clickstreamInfo.sectionName = '';
    clickstreamInfo.functionalityName = clickStreamConsts.functionalitySignUp;
    clickstreamInfo.actionType = clickStreamConsts.interaction;
    clickstreamInfo.CTATitle = 'Continue';
    clickstreamInfo.eventType = clickStreamConsts.signedUp;
    clickstreamInfo.sourcePageName = this.commonService.previousPageName;
    clickstreamInfo.userSource = clickStreamConsts.userSource;
    return this.clickStreamLogging(clickstreamInfo).subscribe();
  }

  logButtonClickedEvent(CTATitle?: string,
    eventType?: string,
    careerCat?: string,
    careerSubCat?: string,
    matchCatPercent?: string,
    matchSubCatPercent?: string,
    functionalityName?: string,
    searchKeyword?: string,
    jobProfileName?: string,
    jobProfileMatchPercent?: string
  ) {
    const clickstreamInfo = new ClickStream();
    // clickstreamInfo.sectionName = '';
    // clickstreamInfo.functionalityName = "";
    clickstreamInfo.CTATitle = CTATitle;
    clickstreamInfo.functionalityName = functionalityName || undefined;
    clickstreamInfo.actionType = clickStreamConsts.interaction;
    clickstreamInfo.eventType = eventType || clickStreamConsts.buttonClicked;
    clickstreamInfo.careerParentCategory = careerCat || undefined;
    clickstreamInfo.careerChildCategory = careerSubCat || undefined;
    clickstreamInfo.carrerParentCategoryMatchPercent = matchCatPercent || undefined;
    clickstreamInfo.careerChildCategoryMatchPercent = matchSubCatPercent || undefined;
    clickstreamInfo.searchKeyword = searchKeyword || undefined;
    clickstreamInfo.jobProfileMatchPercent = jobProfileMatchPercent || undefined;
    clickstreamInfo.jobProfileName = jobProfileName || undefined;
    clickstreamInfo.pageName = this.commonService.currentPageName || localStorage.getItem('currentPageName');
    clickstreamInfo.sourcePageName = this.commonService.previousPageName || localStorage.getItem('previousPageName');
    return this.clickStreamLogging(clickstreamInfo).subscribe();
  }

  async logSocialLogin(signedInMethod: string, ctaTitle: string, failureReason?: string, isSignedUp: boolean = false) {
    const clickstreamData = new ClickStream();
    clickstreamData.CTATitle = ctaTitle;
    clickstreamData.actionType = clickStreamConsts.interaction;

    if (isSignedUp) {
      clickstreamData.eventType = clickStreamConsts.eventType.signedUp;
      clickstreamData.signedupMethod = signedInMethod;
      clickstreamData.signupFailureReason = failureReason;
    } else {
      clickstreamData.eventType = clickStreamConsts.eventType.signedIn;
      clickstreamData.signedInMethod = signedInMethod;
      clickstreamData.signinFailureReason = failureReason;
    }
    return this.clickStreamLogging({
      ...clickstreamData, ...this.getPageInfo()
    }).subscribe();
  }

  logClickStream(clickStreamData?: ClickStream) {
    const params = new HttpParams();
    clickStreamData.pageName = this.commonService.currentPageName || localStorage.getItem('currentPageName');
    clickStreamData.sourcePageName = this.commonService.previousPageName || localStorage.getItem('previousPageName');
    const inPayLoad = {
      affiliateId: environment.affiliateId,
      ...clickStreamData,
      ...JSON.parse(JSON.stringify(this.getDeviceInfo())),
      ...JSON.parse(JSON.stringify(this.getUserInfo()))
    };
    const headers = new HttpHeaders({
      'x-api-key': environment.xapikey
    });
    const options = {
      headers, params
    };
    const apiUrl = localStorage.getItem("userid") ? this.CLICK_STREAM_URL : this.CLICK_STREAM_GUEST_URL;
    return this.http.post(apiUrl, inPayLoad, options).subscribe();
  }

  //
  logCareersEvents(eventType: string,
    actionType: string,
    CTATitle?: string,
    sectionName?: string,
    functionalityName?: string,
    ldcs1Code?: string,
    description?: string,
    studyLevelId?: string,
    studyLevel?: string) {
    const clickstreamInfo = new ClickStream();
    clickstreamInfo.sectionName = sectionName || '';
    clickstreamInfo.functionalityName = functionalityName || "";
    clickstreamInfo.CTATitle = CTATitle;
    clickstreamInfo.actionType = actionType || clickStreamConsts.interaction;
    clickstreamInfo.eventType = eventType || clickStreamConsts.buttonClicked;
    clickstreamInfo.ldcs1Code = ldcs1Code || undefined;
    clickstreamInfo.ldcs1CodeDesc = description || undefined;
    clickstreamInfo.studyLevelId = studyLevelId || undefined;
    clickstreamInfo.studyLevel = studyLevel || undefined;
    clickstreamInfo.pageName = this.commonService.currentPageName || localStorage.getItem('currentPageName');
    clickstreamInfo.sourcePageName = this.commonService.previousPageName || localStorage.getItem('previousPageName');
    return this.clickStreamLogging(clickstreamInfo).subscribe();
  }

  logForgotPasswordEvents(eventType: string, CTATitle: string, sectionName?: string) {
    const clickStream = new ClickStream();
    clickStream.actionType = clickStreamConsts.actionType.interaction;
    clickStream.CTATitle = CTATitle;
    clickStream.eventType = eventType;
    clickStream.sectionName = sectionName || undefined;
    clickStream.functionalityName = clickStreamConsts.functionalityName.forgotPassword;
    clickStream.pageName = this.commonService.currentPageName || localStorage.getItem('currentPageName');
    clickStream.sourcePageName = this.commonService.previousPageName || localStorage.getItem('previousPageName');
    return this.clickStreamLogging(clickStream).subscribe();
  }

  logEvents(clickstreamInfo: ClickStream) {
    clickstreamInfo.pageName = this.commonService.currentPageName || localStorage.getItem('currentPageName');
    clickstreamInfo.sourcePageName = this.commonService.previousPageName || localStorage.getItem('previousPageName');
    return this.clickStreamLogging(clickstreamInfo).subscribe();
  }

  // New global method
  logClickstreamEvent(eventType: string, actionType: string, CTATitle?: string, sectionName?: string, functionalityName?: string) {
    const clickstreamData = new ClickStream();
    clickstreamData.actionType = actionType || undefined;
    clickstreamData.eventType = eventType || undefined;
    clickstreamData.sectionName = sectionName || undefined;
    clickstreamData.functionalityName = functionalityName || undefined;
    clickstreamData.CTATitle = CTATitle || undefined;
    return this.log(clickstreamData).subscribe();
  }

  log(clickstreamData?: ClickStream): Observable<any> {
    const apiUrl = localStorage.getItem("userid") ? this.CLICK_STREAM_URL : this.CLICK_STREAM_GUEST_URL;
    const inPayLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      ...this.getPageInfo(),
      ...this.getDeviceInfo(),
      ...this.getUserInfo(),
      ...clickstreamData
    };
    const headers = new HttpHeaders({
      'x-api-key': environment.xapikey
    });
    const params = new HttpParams();
    const options = {
      headers, params
    };
    return this.http.post(apiUrl, inPayLoad, options);
  }
  // New global method

  clickStreamLogging(pageInfo?: ClickStream): Observable<any> {
    const params = new HttpParams();
    const inPayLoad = {
      affiliateId: environment.affiliateId,
      appFlag: environment.appFlag,
      ...JSON.parse(JSON.stringify(pageInfo)),
      ...JSON.parse(JSON.stringify(this.getDeviceInfo())),
      ...JSON.parse(JSON.stringify(this.getUserInfo()))
    };
    const headers = new HttpHeaders({
      'x-api-key': environment.xapikey
    });
    const options = {
      headers, params
    };
    const apiUrl = localStorage.getItem("userid") ? this.CLICK_STREAM_URL : this.CLICK_STREAM_GUEST_URL;
    return this.http.post(apiUrl, inPayLoad, options);
  }
}
