
const constantVariables = {
  appVersion: '6.1',
  dev: {
    xapikey: 'ZW3W0MfCUB1RHrD1t7DoR7X7TfBvKhs2aA2dqUzw',
    contentfulAccessToken: '6RkX8v3pK3WqwFBBYpJ-YBm3fBQPw85a3fdZ_V8FIPk',
    encyptPasswordToken: 'whatuni12345@#&!',
    //userPoolId: 'eu-west-2_3tKyEjHDc',
    //userPoolWebClientId: '734a39aspdtam2maledc4lbcft'

    //userPoolId: 'eu-west-2_UppV3WhLx',
    //userPoolWebClientId: '5tgbf3oeqqv4qap4vqvr3g56fv'

    userPoolId: 'eu-west-2_FUKmcZjeN',
    userPoolWebClientId: 'o86bfdt9vches8sqgtcd6nasg',
    adjustApiToken: '3p4nbi6ftn28'
  },
  stg: {
    xapikey: '6bKsBP4Huv8gE6G5Yg0zl5pAMdrx1Qfv72NYAgsa',
    contentfulAccessToken: '6RkX8v3pK3WqwFBBYpJ-YBm3fBQPw85a3fdZ_V8FIPk',
    encyptPasswordToken: 'whatuni12345@#&!',
    userPoolId: 'eu-west-2_n95p810XO',
    userPoolWebClientId: '13k2779npn3qo0583j09enmekr',
    adjustApiToken: '3p4nbi6ftn28'
  },
  uat: {
    xapikey: '5c6pBOKvTU5lV1izi4P6d5NnNP3yMyoF7WFmhjmF',
    contentfulAccessToken: '6RkX8v3pK3WqwFBBYpJ-YBm3fBQPw85a3fdZ_V8FIPk',
    encyptPasswordToken: 'whatuni12345@#&!',
    userPoolId: 'eu-west-2_gXOTqmYpb',
    userPoolWebClientId: '2m176qsmj4tpqohfvmt3heb3au',
    adjustApiToken: '3p4nbi6ftn28'
  },
  prod: {
    xapikey: 'AIdIi2E0Es9ueeN54mFUb3RqlLWZsP9R8mglP6mv',
    contentfulAccessToken: '6RkX8v3pK3WqwFBBYpJ-YBm3fBQPw85a3fdZ_V8FIPk',
    encyptPasswordToken: 'whatuni12345@#&!',
    userPoolId: 'eu-west-2_jhGePXpSv',
    userPoolWebClientId: '196nhbh03i0m6l7208r8an175b',
    adjustApiToken: '3p4nbi6ftn28'
  },

  firebaseEvents: {
    screenview: 'screenview',
    tutorial_skipped: 'tutorial_skipped',
    tutorial_complete: 'tutorial_complete',
    add_to_wishlist: 'add_to_wishlist',
    ecommerce_purchase: 'ecommerce_purchase',
    engagement_getprospectus: 'engagement_getprospectus',
    interactionexternal_getprospectus: 'interactionexternal_getprospectus',
    read_reviews_clicked: 'read_reviews_clicked',
    reviews_thumbsup: 'reviews_thumbsup',
    share_icon: 'share_icon',
    opendays_universityclicked: 'opendays_universityclicked',
    interaction_book_event: 'interaction_book_event',
    opendays_monthfilterselected: 'opendays_monthfilterselected',
    opendays_filter: 'opendays_filter',
    googlesigninsignup_clicked: 'googlesigninsignup_clicked',
    fbsigninsignup_clicked: 'fbsigninsignup_clicked',
    applesignin_success: 'applesignin_success',
    fbsignin_success: 'fbsignin_success',
    fbsignup_success: 'fbsignup_success',
    googlesignin_success: 'googlesignin_success',
    googlesignup_success: 'googlesignup_success',
    pfpagemobilenumber_verified: 'pfpagemobilenumber_verified',
    pfpagemobilenumber_submitted: 'pfpagemobilenumber_submitted',
    careermatcher_startquiz: 'careermatcher_startquiz',
    careermatcher_quizcompletion: 'careermatcher_quizcompletion',
    signin_click: 'signin_click',
    applesigninsignup_clicked: 'applesigninsignup_clicked',
    signin_submit: 'signin_submit',
    signup_submit: 'signup_submit'

  },
  uxcamconsts: {
    welcomePage: "welcome",
    onBoardingYOE: "On boarding year of entry",
    onBoardingType: "On boarding study level",
    onBoardingSubject: "On boarding subjects",
    home: "Home",
    courseDetailPage: "Course detail",
    searchResultPage: "Search results page",
    userProfile: "User profile",
    coldStartPage: "Cold start search page",
    shortListPage: "Shortlist page"
  }
};

export const constants = constantVariables;
