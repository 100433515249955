import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment'
import { apiEndpointURI, guestApiEndPointURL } from '../utilities/constants/api-endpoints-uri';
import { EventFilter } from '../models/filter.model';
import { map, take , shareReplay } from 'rxjs/operators';

import { EnquiryData } from '../models/enquiry.model';
import { ClickstreamService } from './clickstream.service';
import { ClickStream } from '../models/click-stream';
import { clickStreamConsts } from '../utilities/constants/click-stream-const';

@Injectable({
  providedIn: 'root'
})
export class EventApiService {
  appliedEventFilters = new EventFilter();

  EVENT_DETAIL_URL: string = environment.API_END_POINT + apiEndpointURI.getEventsDetailUrl;
  GUEST_EVENT_DETAIL_URL: string = environment.API_END_POINT + guestApiEndPointURL.getEventsDetailUrl;
  EVENT_SECTION_URL: string = environment.API_END_POINT + apiEndpointURI.getOpendaySection;
  GUEST_EVENT_SECTION_URL: string = environment.API_END_POINT + guestApiEndPointURL.getOpendaySection;

  // openday landing page
  EVENT_SEARCH_URL: string = environment.API_END_POINT + apiEndpointURI.getEventSearchUrl;
  GUEST_EVENT_SERCH_URL: string = environment.API_END_POINT + guestApiEndPointURL.getEventSearchUrl;
  FEATURE_EVENT_DETAIL_URL: string = environment.API_END_POINT + apiEndpointURI.getOpenDayFeatureEventUrl;
  GUEST_FEATURE_EVENT_DETAIL_URL: string = environment.API_END_POINT + guestApiEndPointURL.getOpenDayFeatureEventUrl;
  EVENT_AJAX_URL: string = environment.API_END_POINT + apiEndpointURI.getEventsAjaxUrl;
  GUEST_EVENT_AJAX_URL: string = environment.API_END_POINT + guestApiEndPointURL.getEventsAjaxUrl;
  EVENT_FILTER_URL: string = environment.API_END_POINT + apiEndpointURI.getEventsFilterUrl;
  GUEST_EVENT_FILTER_URL: string = environment.API_END_POINT + guestApiEndPointURL.getEventsFilterUrl;
  EVENT_FILTER_COUNT_URL: string = environment.API_END_POINT + apiEndpointURI.getEventFilterCountUrl;
  GUEST_EVENT_FILTER_COUNT_URL: string = environment.API_END_POINT + guestApiEndPointURL.getEventFilterCountUrl;
  RECOMMENDED_EVENT: string = environment.API_END_POINT + apiEndpointURI.recommendedEvent;

  cachedRecommendedEvents$: Observable<any>;

  getActualEventSubmitData = ({ institutionId, eventCalendarItemId, orderItemId, subOrderItemId, keyProfileId }: EnquiryData): EnquiryData => {
    return {
      institutionId,
      eventCalendarItemId,
      orderItemId,
      subOrderItemId,
      keyProfileId
    }
  };

  constructor(public http: HttpClient, private clickstreamService: ClickstreamService) { }

  getEventDetails(institutionId: string, page: number): Observable<any> {
    let url: string;
    url = localStorage.getItem('userid') ? this.EVENT_DETAIL_URL : this.GUEST_EVENT_DETAIL_URL;
    url = url.replace('institution_id', institutionId);
    const params = new HttpParams().set("appFlag", environment.appFlag).set('affiliateId', environment.affiliateId)
    const options = {
      params
    };
    return this.http.get(url + '/' + page?.toString(), options);
  }

  getEventSectionDetail(institutionId: any, courseId?: any, orderItemId?: any): Observable<any> {
    // const url:string;
    const url = localStorage.getItem('userid') ? this.EVENT_SECTION_URL : this.GUEST_EVENT_SECTION_URL;
    let params = new HttpParams().set("appFlag", environment.appFlag).set('affiliateId', environment.affiliateId)
    if (institutionId)
      params = params.set("institutionId", institutionId);

    if (courseId)
      params = params.set("courseId", courseId);

    if (orderItemId)
      params = params.set("orderItemId", orderItemId);

    const options = {
      params
    };
    return this.http.get(url, options);
  }

  getEventSearchDetails(pageNo: number | number[], month: any): Observable<any> {
    const url = localStorage.getItem('userid') ? this.EVENT_SEARCH_URL : this.GUEST_EVENT_SERCH_URL;
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      month,
      "regionId": this.appliedEventFilters?.region?.commaSeperatedId || "",
      "locationTypeId": this.appliedEventFilters?.locationType?.commaSeperatedId || "",
      "eventTypeId": this.appliedEventFilters?.eventType.commaSeperatedId || ""
    }
    if (typeof pageNo === 'number') {
      return this.http.post(url, {
        "pageNo": pageNo?.toString(), ...bodyJson
      });
    } else {
      let objArr: Array<Observable<any>> = [];
      pageNo.forEach((pNo: number) => {
        objArr = [...objArr, this.http.post(url, {
          "pageNo": pNo?.toString(), ...bodyJson
        })]
      })
      return forkJoin(objArr).pipe(map((res: any) => {
        const firstObj = res[0];
        res?.forEach((resultObj: any, idx: number) => {
          if (idx !== 0)
            firstObj.searchResults = [...firstObj.searchResults, ...resultObj.searchResults];
        });
        return firstObj;
      }));
    }
  }

  getFeatureEventDetails(): Observable<any> {
    const url: string = localStorage.getItem('userid') ? this.FEATURE_EVENT_DETAIL_URL : this.GUEST_FEATURE_EVENT_DETAIL_URL
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
    const options = {
      params
    };
    return this.http.get(url, options);
  }

  getEventAjaxDetails(keyword: string): Observable<any> {
    const url = localStorage.getItem('userid') ? this.EVENT_AJAX_URL : this.GUEST_EVENT_AJAX_URL;
    const bodyJson = {
      keyword,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag
    }
    return this.http.post(url, bodyJson);
  }

  getEventFilterDetails(month: any): Observable<any> {
    const url = localStorage.getItem('userid') ? this.EVENT_FILTER_URL : this.GUEST_EVENT_FILTER_URL;
    const bodyJson = {
      "regionId": "",
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "locationTypeId": "",
      "eventTypeId": "",
      month
    }
    return this.http.post(url, bodyJson);
  }

  getEventFilterCount(filterData: any, month: any): Observable<any> {
    const url = localStorage.getItem('userid') ? this.EVENT_FILTER_COUNT_URL : this.GUEST_EVENT_FILTER_COUNT_URL;
    const bodyJson = {
      "regionId": filterData.region?.commaSeperatedId || '',
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "locationTypeId": filterData.locationType?.commaSeperatedId || '',
      "eventTypeId": filterData.eventType?.commaSeperatedId || '',
      month
    }
    return this.http.post(url, bodyJson);
  }

  getRecommendedEvents(eventCalendarItemId: number): Observable<any> {
    const url = localStorage.getItem('userid') ? this.RECOMMENDED_EVENT : this.GUEST_EVENT_DETAIL_URL;
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
      .set('eventCalendarItemId', eventCalendarItemId)
    const options = {
      params
    };
    return this.http.get(url, options);
  }

  getCachedRecommendedEvents(eventCalendarItemId: number) {
    this.cachedRecommendedEvents$ = this.getRecommendedEvents(eventCalendarItemId).pipe(shareReplay(1));
    return this.cachedRecommendedEvents$;
  }

  logInteractionClickstream(eventType: string, CTATitle?: string, sectionName?: string, uniCourseInfo?: any, moreInfo?: any) {
    const clickstreamData: ClickStream = new ClickStream();
    clickstreamData.functionalityName = clickStreamConsts.functionalityName.openDay;
    clickstreamData.actionType = clickStreamConsts.actionType.interaction;
    clickstreamData.institutionCountryName = clickStreamConsts.institutionCountryName;
    clickstreamData.isAdvertiser = "Y";
    clickstreamData.eventType = eventType || undefined;
    clickstreamData.sectionName = sectionName || undefined;
    clickstreamData.CTATitle = CTATitle || undefined;

    clickstreamData.eventId = uniCourseInfo?.eventId || undefined;
    clickstreamData.idpcInstitutionId = uniCourseInfo?.idpcInstitutionId || undefined;
    clickstreamData.orderItemId = uniCourseInfo?.orderItemId || undefined;
    clickstreamData.subOrderItemId = uniCourseInfo?.subOrderItemId || undefined;

    clickstreamData.idpcCourseId = uniCourseInfo?.idpcCourseId || undefined;
    clickstreamData.idpcCourseName = uniCourseInfo?.idpcCourseName || undefined;
    clickstreamData.studyLevel = uniCourseInfo?.studyLevel || undefined;
    clickstreamData.studyLevelId = uniCourseInfo?.studyLevelId || undefined;
    clickstreamData.childStudyLevel = uniCourseInfo?.childStudyLevel || undefined;
    clickstreamData.childStudyLevelId = uniCourseInfo?.childStudyLevelId || undefined;

    clickstreamData.interestedIntakeYear = moreInfo?.interestedIntakeYear || undefined;
    return this.clickstreamService.log(clickstreamData).pipe(take(1)).subscribe();
  }
}
