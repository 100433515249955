import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

declare let MCEPlugin;

@Injectable({
  providedIn: 'root'
})

export class AcousticService {
  constructor (private platform: Platform) { }

  addEvent (action?: string, custom1?: string, custom2?: string, custom3?: string, custom4?: string) {
    const sendImmediate = true;
    const eventData = {
      "type": "custom",
      "name": action,
      "timestamp": new Date(),
      "attributes": {
        "customData1": custom1,
        "customData2": custom2,
        "customData3": custom3,
        "otherCustomData": custom4
      }
    }
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb'))
      MCEPlugin.queueAddEvent(eventData, sendImmediate);
  }

  // registerAction() {
  //   var action = {
  //     "label": "Go to Search",
  //     "type": "custom",
  //     "inputRequired": false,
  //     "value": null,
  //     "destructive": false,
  //     "platform": "IOS",
  //     "description": "Open the Search Page in the App",
  //     "placeholder": null,
  //     "id": 11304,
  //     "customProperties": [
  //       {
  //         "id": "hasLoyaltyId",
  //         "inputRequired": true,
  //         "type": "boolean",
  //         "placeholder": "Does the customer have a loyalty ID?",
  //         "value": true
  //       }
  //     ],
  //     "authenticationRequired": false,
  //     "activationMode": true
  //   }
  //   if (!this.platform.is('desktop')) {
  //     MCEPlugin.registerAction('demonstration', action);
  //   }
  // }
}
