import { Injectable } from '@angular/core';
import { MODALS } from '../enums/modals.enum';
import { ModalController } from '@ionic/angular';
import { CourseSearchComponent } from '../components/course-search/course-search.component';
import { CommonService } from './common.service';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from '../components/update-password/update-password.component';
import { Subject } from 'rxjs';
import { FavouriteUniCourses } from '../pages/favourites/models/favourites.model';
import { FavouritesEditComponent } from '../modals/favourites-edit/favourites-edit.component';
import { EnquirySuccessModalComponent } from '../modals/enquiry-success-modal/enquiry-success-modal.component';
import { EnquiryModalComponent } from '../modals/enquiry-modal/enquiry-modal.component';
import { WelcomePage } from '../pages/welcome/welcome.page';
import { EnquiryData, EnquiryTypes } from '../models/enquiry.model';
import { OpenDayBookedSuggestionsComponent } from '../modals/open-day-booked-suggestions/open-day-booked-suggestions.component';
import { AnymoreFinal5OptionsComponent } from '../pages/favourites/components/anymore-final5-options/anymore-final5-options.component';
import { SuggestionFavouritesComponent } from '../pages/comparison/components/suggestion-favourites/suggestion-favourites.component';

@Injectable({
  providedIn: 'root'
})
export class WuniModalControllerService {
  private activeModals: string[] = [];

  emittedData: Subject<{ modalId: MODALS, data?: any }> = new Subject();

  constructor(private modalCtrl: ModalController,
    private commonService: CommonService) {
  }

  public openModal(modalName: MODALS, modalId?: string, data?: any, sourcePage?: string) {
    this.activeModals.push(modalId || modalName);
    switch (modalName) {
      case MODALS.WELCOME:
        this.openWelcomeModal(data, null, sourcePage);
        break;
      case MODALS.COURSE_SEARCH:
        this.openCourseSearchModal(data, sourcePage);
        break;
      case MODALS.FORGOT_PASSWORD:
        this.openForgotPasswordModal(data, sourcePage);
        break;
      case MODALS.UPDATE_PASSWORD:
        this.openUpdatePasswordModal(data, sourcePage);
        break;
      case MODALS.COURSE_SEARCH_SELECT:
        this.openCourseSearcSelecthModal(data);
        break;
      case MODALS.FAVOURITES_EDIT:
        this.openFavouritesEditModal(data);
        break;
      case MODALS.OPEN_DAY_BOOKING_YOE:
        this.openOpenDayBookingYOE(data);
        break;
      case MODALS.OPEN_DAY_BOOKING_SUGGESTION:
        this.openOpenDayBookingSuggestion(data);
        break;
      case MODALS.OPEN_DAY_BOOKING_SUCCESS:
        this.openOpenDayBookingSuccess(data);
        break;
      case MODALS.FINAL5_REMOVE:
        this.openFinal5Remove(data, modalId);
        break;
      case MODALS.COMPARISON_SUGGESTION:
        this.openComparisonSuggestedUniCourses(data);
        break;
    }
  }

  public openOpenDayBookingForm(enquiryData: EnquiryData, otherData?: any) {
    const uid = localStorage.getItem('userid') ? localStorage.getItem('userid') : '';
    if (uid) {
      this.openOpenDayBookingYOE({ enquiryType: EnquiryTypes.book_event, enquiryData });
      this.activeModals.push(MODALS.OPEN_DAY_BOOKING_YOE);
    } else {
      this.openWelcomeModal({ enquiryType: EnquiryTypes.book_event, enquiryData }, otherData);
      this.activeModals.push(MODALS.WELCOME);
    }
  }

  private async openWelcomeModal(enquiryDetailes: { enquiryType: EnquiryTypes, enquiryData: EnquiryData }, otherData?: any, sourcePage?: string) {
    const modal = await this.modalCtrl.create({
      component: WelcomePage,
      cssClass: 'my-modal-styles',
      id: MODALS.WELCOME,
      componentProps: {
        enquiryType: enquiryDetailes.enquiryType,
        enquiryData: enquiryDetailes.enquiryData,
        otherData
      }
    })
    modal.onWillDismiss().then((res: any) => {
      if (sourcePage)
        this.commonService.setCurrentPage(sourcePage);
      if (res?.data?.dismissed)
        this.emittedData.next({ modalId: MODALS.WELCOME, data: res.data });
      switch (res?.data?.enquiryType) {
        case EnquiryTypes.book_event:
          this.openModal(MODALS.OPEN_DAY_BOOKING_YOE, null, { enquiryType: EnquiryTypes.book_event, enquiryData: res?.data?.enquiryData });
          break;
        // case EnquiryTypes.final_5:
        //   this.emittedData.next({ modalId: MODALS.WELCOME, data: res.data });
        //   break;
      }
    })
    return await modal.present();
  }

  async openCourseSearchModal(data: any, sourcePage?: string) {
    const modal = await this.modalCtrl.create({
      component: CourseSearchComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.COURSE_SEARCH,
      componentProps: {
        institutionId: data?.institutionId,
        totalCourseCount: data?.courseCount,
        subject: data?.subject,
        categoryData: data
      }
    });
    modal.onWillDismiss().then(() => {
      if (sourcePage)
        this.commonService.setCurrentPage(sourcePage);
      this.modalCtrl.dismiss();
    })
    await modal.present();
  }

  private async openForgotPasswordModal(data: any, sourcePage?: string) {
    const modal = await this.modalCtrl.create({
      component: ForgotPasswordComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.FORGOT_PASSWORD,
      componentProps: {
        isModal: true
      }
    });
    modal.onWillDismiss().then((res: any) => {
      if (sourcePage)
        this.commonService.setCurrentPage(sourcePage);
      if (res.data?.updatePassStatus)
        this.emittedData.next({ modalId: MODALS.FORGOT_PASSWORD, data: res.data });
    })
    await modal.present();
  }

  private async openUpdatePasswordModal(data: any, sourcePage?: string) {
    const modal = await this.modalCtrl.create({
      component: UpdatePasswordComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.UPDATE_PASSWORD,
      componentProps: {
        isModal: true
      }
    });
    modal.onWillDismiss().then((res: any) => {
      if (sourcePage)
        this.commonService.setCurrentPage(sourcePage);
      if (res.data?.updatePassStatus)
        this.dismiss(MODALS.FORGOT_PASSWORD, { ...res.data });
    })
    await modal.present();
  }

  private async openCourseSearcSelecthModal(favouriteUniCourses?: FavouriteUniCourses) {
    const modal = await this.modalCtrl.create({
      component: CourseSearchComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.COURSE_SEARCH_SELECT,
      componentProps: {
        institutionId: favouriteUniCourses.institutionId,
        totalCourseCount: favouriteUniCourses?.courseCount,
        subject: favouriteUniCourses?.courseName,
        isFavouritesPage: true,
        favCourseList: favouriteUniCourses?.courseIdList || [],
        institutionName: favouriteUniCourses?.institutionName
      }
    });
    modal.onWillDismiss().then((res) => {
      this.modalCtrl.dismiss();
      if (res?.data)
        this.emittedData.next({ modalId: MODALS.COURSE_SEARCH_SELECT, data: res?.data });
    })
    await modal.present();
  }

  private async openFavouritesEditModal(favouritesUniCourse: FavouriteUniCourses | any) {
    const modal = await this.modalCtrl.create({
      component: FavouritesEditComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.FAVOURITES_EDIT,
      componentProps: favouritesUniCourse?.mode ? { mode: favouritesUniCourse?.mode } : {
        thirtyFirstFavouriteObj: favouritesUniCourse
      }
    });
    modal.onWillDismiss().then(() => {
      this.modalCtrl.dismiss();
      this.emittedData.next({ modalId: favouritesUniCourse?.mode ? MODALS.FAVOURITES_EDIT : MODALS.FAVOURITES_LIMIT_EECEEDS });
    })
    await modal.present();
  }

  private async openOpenDayBookingYOE(data: any) {
    const modal = await this.modalCtrl.create({
      component: EnquiryModalComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.OPEN_DAY_BOOKING_YOE,
      componentProps: {
        enquiryType: data?.enquiryType,
        enquiryData: data?.enquiryData
      }
    });
    modal.onWillDismiss().then(() => {
      this.modalCtrl.dismiss();
      this.emittedData.next({ modalId: MODALS.FAVOURITES_LIMIT_EECEEDS });
    })
    await modal.present();
  }

  private async openOpenDayBookingSuggestion(data: any) {
    const modal = await this.modalCtrl.create({
      component: OpenDayBookedSuggestionsComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.OPEN_DAY_BOOKING_SUGGESTION,
      componentProps: {
        enquiryType: data?.enquiryType,
        enquiryData: data?.enquiryData,
        enquiryDetails: data?.enquiryDetails
      }
    });
    modal.onWillDismiss().then(() => {
      this.modalCtrl.dismiss();
      this.emittedData.next({ modalId: MODALS.FAVOURITES_LIMIT_EECEEDS });
    })
    await modal.present();
  }

  private async openOpenDayBookingSuccess(data: { enquiryType: EnquiryTypes, enquiryData: EnquiryData, enquiryDetails: any, hasNoRecommendedEvents?: boolean }) {
    const modal = await this.modalCtrl.create({
      component: EnquirySuccessModalComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.OPEN_DAY_BOOKING_SUCCESS,
      componentProps: {
        enquiryType: data?.enquiryType,
        enquiryData: data?.enquiryData,
        enquiryDetails: data?.enquiryDetails,
        hasNoRecommendedEvents: data?.hasNoRecommendedEvents
      }
    });
    modal.onWillDismiss().then(() => {
      this.modalCtrl.dismiss();
      this.emittedData.next({ modalId: MODALS.FAVOURITES_LIMIT_EECEEDS });
    })
    await modal.present();
  }

  private async openFinal5Remove(data: any, id: string) {
    const modal = await this.modalCtrl.create({
      component: AnymoreFinal5OptionsComponent,
      cssClass: 'my-sheet-modal_h516',
      breakpoints: [0, 0.6],
      initialBreakpoint: 0.6,
      id: 'Final5_remove',
      componentProps: {
        mode: id ? 'removeMultiInfo' : 'removeInfo',
        final5_list: data
      }
    });
    modal.onWillDismiss().then(res => {
      console.log(res, 'onWillDismiss')
      this.modalCtrl.dismiss();
      this.emittedData.next({ modalId: MODALS.FINAL5_REMOVE, data: res.data });
    })
    await modal.present();
  }

  private async openComparisonSuggestedUniCourses(data?: any) {
    const modal = await this.modalCtrl.create({
      component: SuggestionFavouritesComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.COMPARISON_SUGGESTION,
      componentProps: {

      }
    });
    modal.onWillDismiss().then(res => {
      console.log(res, 'onWillDismiss')
      this.modalCtrl.dismiss();
      // this.emittedData.next({ modalId: MODALS.FINAL5_REMOVE, data: res.data });
    })
    await modal.present();
  }

  // Close all opened modals through this service
  public closeAllModals() {
    while (this.activeModals?.length)
      this.modalCtrl.dismiss(null, null, this.activeModals.pop());
  }

  public dismiss(modalId?: string, data?: any) {
    this.modalCtrl.dismiss(data, null, modalId);
    if (!modalId)
      return
    this.activeModals.forEach((activeModalId, index) => {
      if (activeModalId === modalId)
        this.activeModals.splice(index, 1);
    });
  }
}
