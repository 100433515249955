import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MODALS } from '../../enums/modals.enum';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';
import { EventApiService } from '../../services/eventApi.service';
import { EnquiryData, EnquiryType, EnquiryTypes } from '../../models/enquiry.model';
import { ApiService } from '../../services/api.service';
import { staggerAnimationForOpenDay } from '../../animations/tiles-list.animation';
import { heightAnimateInOut } from '../../animations/success-msg.animation';
import { OpenDayEVentSuggestionTile } from '../../models/index.model';
import { clickStreamConsts } from 'src/app/utilities/constants/click-stream-const';

interface RecommendedEvent {
  "institutionName": string,
  "institutionId": number,
  "eventDate": string,
  "eventLocation": string,
  "eventCalendarItemId": number,
  "totalEvent"?: number
}

@Component({
  selector: 'app-open-day-booked-suggestions',
  templateUrl: './open-day-booked-suggestions.component.html',
  styleUrls: ['./open-day-booked-suggestions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [staggerAnimationForOpenDay, heightAnimateInOut]
})
export class OpenDayBookedSuggestionsComponent implements OnInit {
  @Input() enquiryType: EnquiryType;
  @Input() enquiryData: EnquiryData;
  @Input() enquiryDetails: any;
  @ViewChild(IonContent) content: IonContent;
  enquiryTypes = EnquiryTypes;
  apiUrl: string = '/interactions/events/success-page';
  openDaySuggestions$: Observable<RecommendedEvent[]>;
  openDaySubmitAndSuggestionsData$: Observable<{ recommendedEvents?: any[], submitRes?: any }>;
  recommendedEvents: any;
  submitOpenDayEvent$: Observable<any>;
  viewAllFlag = false;
  submitEventsList: OpenDayEVentSuggestionTile[] = [];
  pipedSelectedEventsUniName: string = '';
  alreadyProsEnquiredFlag = false;
  constructor(private wuniModalCtrl: WuniModalControllerService,
    private eventApiService: EventApiService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService) { }

  ngOnInit() {
    this.submitOpenDayEventAndGetSuggestions();
  }

  submitOpenDayEventAndGetSuggestions() {
    this.submitOpenDayEvent();
    this.getRecommendedEvents();
    this.openDaySubmitAndSuggestionsData$ = forkJoin({ submitRes: this.submitOpenDayEvent$, recommendedEvents: this.openDaySuggestions$ }).pipe(
      map((res) => {
        return res;
      })
    )
  }

  submitOpenDayEvent(): void {
    const data = {
      "recommendationPageFlag": "N"
    }
    this.submitOpenDayEvent$ = this.apiService.submitInteraction(this.apiUrl, [this.eventApiService.getActualEventSubmitData(this.enquiryData)], data).pipe(
      map((res: any) => {
        this.wuniModalCtrl.emittedData.next({ modalId: MODALS.OPEN_DAY_BOOKING_SUGGESTION, data: { isSubmitted: true, eventCalendarItemId: [this.enquiryData.eventCalendarItemId] } });
        if (res?.pageName) {
          if (this.enquiryType === EnquiryTypes.book_event && !res?.alreadyOpendayEnquiredFlag) {
            const uniCourseInfo = {
              eventId: this.enquiryData?.eventCalendarItemId,
              idpcInstitutionId: this.enquiryData?.institutionId,
              orderItemId: this.enquiryData?.orderItemId,
              subOrderItemId: this.enquiryData?.subOrderItemId,
              idpcCourseId: this.enquiryData?.courseId,
              idpcCourseName: this.enquiryData?.courseName,
              studyLevel: this.enquiryData?.studyLevel || this.enquiryDetails?.clickStreamData?.studyLevel,
              studyLevelId: this.enquiryData?.studyLevelId || this.enquiryDetails?.clickStreamData?.studyLevelId
            }
            this.eventApiService.logInteractionClickstream(clickStreamConsts.eventType.bookEventSubmitted, "Continue", 'Open day suggestions', uniCourseInfo, { interestedIntakeYear: this.enquiryData?.interestedIntakeYear });
          }
          res?.moduleComponents[0]?.fieldComponents?.forEach((ele: any) => {
            ele.selectValue = !!(ele?.fieldValue === 'Y' || ele?.selectValue);
          });
          res.userPreferences = res?.moduleComponents[0];
          return res;
        }
      }),
      catchError(() => {
        return of({ hasError: true });
      })
    );
  }

  getRecommendedEvents() {
    this.openDaySuggestions$ = this.eventApiService.cachedRecommendedEvents$.pipe(
      map((res: any) => {
        this.recommendedEvents = res?.recommendedEvents;
        this.recommendedEvents?.forEach((element: any) => {
          element.viewAllFlag = false;
          element?.events?.map((ele) => {
            ele.hcCollegeId = element?.hcCollegeId;
            ele.institutionId = element?.institutionId;
            ele.institutionName = element?.institutionName;
            ele.interestedIntakeYear = this.enquiryData?.interestedIntakeYear;
            return ele;
          })
        });
        return this.recommendedEvents;
      })
    );
  }

  modaldismiss() {
    this.eventApiService.logInteractionClickstream(clickStreamConsts.eventType.closeClicked, "close", "Open Day");
    this.wuniModalCtrl.closeAllModals();
  }

  bookOpenDay(openDayEvent: OpenDayEVentSuggestionTile) {
    if (openDayEvent.alreadyOpendayEnquiredFlag) {
      this.submitEventsList.push(openDayEvent);
    } else {
      let deleteIndex: number;
      for (let i = 0; i < this.submitEventsList.length; i++)
        if (this.submitEventsList[i].eventCalendarItemId === openDayEvent?.eventCalendarItemId) {
          deleteIndex = i;
          break;
        }
      this.submitEventsList.splice(deleteIndex, 1);
    }
    this.pipedSelectedEventsUniName = this.submitEventsList.map(res => res?.institutionName).join(' | ');
  }

  continue() {
    const data = {
      enquiryType: this.enquiryType,
      enquiryData: this.enquiryData,
      enquiryDetails: this.submitEventsList
    };
    this.wuniModalCtrl.openModal(MODALS.OPEN_DAY_BOOKING_SUCCESS, null, data);
  }

  viewMoreOrLess(eventSection: any) {
    eventSection.viewAllFlag = !eventSection.viewAllFlag;
    this.cdRef.markForCheck();
    return false;
  }
}
