import { Injectable } from '@angular/core';
import { MODALS } from '../../enums/modals.enum';
import { EnquiryData, EnquiryType, EnquiryTypes } from '../../models/enquiry.model';
import { AddressComponent } from 'user-profile';
import { EnquirySuccessModalComponent } from '../../modals/enquiry-success-modal/enquiry-success-modal.component';
import { ApiService } from '../../services/api.service';
import { ModalController } from '@ionic/angular';
import { WelcomePage } from '../../pages/welcome/welcome.page';
import { EnquiryModalComponent } from '../../modals/enquiry-modal/enquiry-modal.component';

@Injectable({
    providedIn: 'root'
  })

export class GetProspectus {
  enquiryData: EnquiryData;
  enquiryType: EnquiryType;
  previousModalId: MODALS;
  enquiryDetails: any;
  constructor (private modalCtrl: ModalController, private api: ApiService) {
    // this.getAdditionalEnquiryDetails();
  }
  async openGetProspectusModal(instid?:any, courseid?:any, instname?:any,coursename?:any) {
    const pageurl = courseid ? 'course-details/' + instid +'/'+courseid : '/university-profile/' + instid;
    const pagename = courseid ? 'course-details' : 'university-profile';
    this.enquiryData = {
      institutionId: instid,
      institutionName: instname,
      subOrderItemId: '',
      pageName: pagename,
      requestProspectusWebform: '',
      pageUrl: pageurl,
      subject: '',
      courseId: courseid || null,
      courseName: coursename
    }
    // this.logClickStream(clickStreamConsts.eventType.prospectusRequestedClicked);
    const uid = localStorage.getItem('userid') ? localStorage.getItem('userid') : '';
    const modal = await this.modalCtrl.create({
      component: uid ? EnquiryModalComponent : WelcomePage,
      cssClass: 'my-modal-styles',
      id: uid ? MODALS.ENQUIRY_YOE : MODALS.WELCOME,
      componentProps: {
        enquiryType: EnquiryTypes.get_prospectus,
        enquiryData: this.enquiryData
      }
    })
    modal.onWillDismiss().then(res => {
      this.modalCtrl.dismiss();
      // this.isLoaderEnabled = false;
      // if (res?.data?.isSubmitted) {
      //   this.isProspectusOrdered = true;
      //   if (res?.data?.preferenceDetails)
      //     this.getProspectusStatus.emit();

      //   this.commonService.favGetProspectusListner.next({
      //     ...res?.data, isProspectusSubmitted: res?.data?.isSubmitted
      //   });
      // }
      // this.cdRef.detectChanges();
    })
    return await modal.present();
  }
  async openAddressModal(instid?:any, courseid?:any, instname?:any, coursename?:any) {
    console.log('instuitution',instid, instname)
    console.log('coursr,',courseid,coursename)
    this.enquiryData = {
      institutionId: instid,
      institutionName: instname,
      subOrderItemId: '',
      pageName: 'university-profile',
      requestProspectusWebform: '',
      pageUrl: '',
      subject: '',
      courseId: courseid || null,
      courseName: coursename
    }
    this.enquiryType = EnquiryTypes.get_prospectus;
    console.log('enquirydet',this.enquiryData, this.enquiryType);
     await this.getAdditionalEnquiryDetails(this.enquiryData).then((res) => {
      this.enquiryDetails = res;
    });
    console.log('enqurie', JSON.stringify(this.enquiryDetails));

    const addressModal = await this.modalCtrl.create({
      component: AddressComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ADDRESS,
      mode: 'ios',
      animated: true,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData: this.enquiryData
      }

    })
    await addressModal.present();
    await addressModal.onWillDismiss().then(res => {
      if (res?.data?.dismissed)
        this.modalCtrl.dismiss({
          dismissed: res?.data?.dismissed, isSubmitted: res?.data?.isSubmitted
        });
      else if (res?.data)
        this.openEnquirySuccessModal(res?.data,this.enquiryData);
    })
  }

  async openEnquirySuccessModal(addressData?: any,enquiryData?:any) {
    enquiryData.institutionName = (this.enquiryDetails?.institutionDetails?.institutionName) || '';
    console.log('enquiry data', JSON.stringify(enquiryData));
    const modal = await this.modalCtrl.create({
      component: EnquirySuccessModalComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ENQUIRY_SUCCESS,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData,
        enquiryDetails: this.enquiryDetails,
        addressData
      }
    })
    await modal.present();
    await modal.onWillDismiss().then(res => {
      if (res?.data?.dismissed)
        this.modalCtrl.dismiss(res?.data, null, MODALS.ENQUIRY_YOE);
    })
  }

  modaldismiss(dismissed?: boolean) {
    // this.clickStreamService.logButtonClickedEvent("back");
    this.modalCtrl.dismiss({
      dismissed
    });
  }
  getAdditionalEnquiryDetails = (enquiryData) => {
     return new Promise((resolve) => {this.api.getEnquiryDetails(enquiryData).subscribe(res => {
      this.enquiryDetails = res?.enquiryDetails;
      console.log('enquirydeti',JSON.stringify(this.enquiryDetails))
        resolve(this.enquiryDetails);
    })
      // this.institutionName = this.enquiryDetails?.institutionDetails?.institutionName;
      // this.cdref.detectChanges();
      // this.courseName = this.enquiryDetails?.courseName;
      //this.textArea = "I read about " + (this.courseName ? this.courseName + " at " : "") + this.institutionName + " on Whatuni and want to know more about the " + (this.courseName ? "course" : "institution");
    })
  }
}
