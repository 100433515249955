
//import { constants } from '../../utilities/constants/constants';
import {constants} from '../app/utilities/constants/constants'

// -------------- DEV ---------------------
export const environment = {
  production: false,
  region: 'eu-west-2',
  contentfulEnvironment: 'wu_next_gen_app',
  API_END_POINT: 'https://api.app.dev.whatuni.com/wuapp',
  xapikey: constants.dev.xapikey,
  contentfulAccessToken: constants.dev.contentfulAccessToken,
  appFlag: 'Y',
  affiliateId: 220703,
  encyptPasswordToken: constants.dev.encyptPasswordToken,
  userPoolId: constants.dev.userPoolId,
  userPoolWebClientId: constants.dev.userPoolWebClientId,
  adjustAppToken: constants.dev.adjustApiToken,
  updatePasswordWesiteURL: 'https://mdev.whatuni.com/degrees/forgotPassword.html?skipcookieconsent=true',
  uxcamKey: 't5t9o4f0k3j7p0l',
  contactUsEmail: 'he_testing@idp.com',
  oAuth_Domain: 'dev-idpc-dom-user.auth.eu-west-2.amazoncognito.com',
  redirectSignIn: 'whatuni://localhost/federated-redirection',
  redirectSignOut: 'whatuni://localhost/welcome',
  dialCode: '+91',
  shareweburl: 'https://mtest.whatuni.com'
};

// // ---------------------------  STAG  -----------------------------------------
// export const environment = {
//   production: false,
//   region: 'eu-west-2',
//   contentfulEnvironment: 'wu_next_gen_app',
//   API_END_POINT: 'https://api.app.stg.whatuni.com/wuapp',
//   xapikey: constants.stg.xapikey,
//   contentfulAccessToken: constants.stg.contentfulAccessToken,
//   appFlag: 'Y',
//   affiliateId: 220703,
//   encyptPasswordToken: constants.stg.encyptPasswordToken,
//   userPoolId: constants.stg.userPoolId,
//   userPoolWebClientId: constants.stg.userPoolWebClientId,
//   adjustAppToken: constants.stg.adjustApiToken,
//   updatePasswordWesiteURL: 'https://mtest.whatuni.com/degrees/forgotPassword.html?skipcookieconsent=true',
//   uxcamKey: 't5t9o4f0k3j7p0l',
//   contactUsEmail: 'he_testing@idp.com',
//   oAuth_Domain: 'stg-idpc-dom-user.auth.eu-west-2.amazoncognito.com',
//   redirectSignIn: 'whatuni://localhost/federated-redirection',
//   redirectSignOut: 'whatuni://localhost/welcome',
//   dialCode: '+91',
//   shareweburl: 'https://mtest.whatuni.com',
//   accosticAndroidKey: 'gczbiPNIjO'
// };
