import { Injectable } from '@angular/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Platform } from '@ionic/angular';

declare let MCEPlugin;

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  constructor(private diagnostic: Diagnostic,
    private platform: Platform) { }

  openNotificationSettings() {
    this.diagnostic.switchToSettings();
  }

  notificationPermission() {
    const promise = new Promise((resolve, reject) => {
      if (this.platform.is('ios'))
        this.diagnostic.getRemoteNotificationsAuthorizationStatus().then((status) => {
          console.log(status, 'statusnotification permission');
          if (status === 'authorized')
            resolve({
              status
            });
          else if (status === 'denied')
            reject({
              status
            });
          // this.diagnostic.switchToSettings();
          else if (status === 'denied_always')
            reject({
              status
            });
        }).catch((e) => {
          console.error("The following error occurred: ", e);
          reject({
            status: e
          });
        });
      else if (this.platform.is('android'))
        this.diagnostic.isRemoteNotificationsEnabled().then((status) => {
          console.log(status, 'getstatus');
          if (localStorage.getItem('email') !== null)
            MCEPlugin.queueUpdateUserAttributes({
              email: localStorage.getItem('email'), updatedBy: 'Kalaiselvi', userId: '123', website: 'whatuni', dateUpdated: new Date().toDateString()
            });

          if (status)
            resolve({
              status
            });
          else
            reject({
              status
            });
        }).catch((e) => {
          console.error("The following error occurred: ", e);
          reject({
            status: e
          });
        });
    });
    return promise;
  }

  mcpluginCallbacks() {
    MCEPlugin.getAppKey(function (appKey) {
      console.log("AppKey is ", appKey);
      MCEPlugin.getRegistrationDetails(function (details) {
        if (typeof details.userId === "undefined" || typeof details.channelId === "undefined")

          MCEPlugin.setRegistrationCallback(function (detail) {
            if (detail)
              if (detail?.userId)
                localStorage.setItem('mobileUserId', detail.userId)

            console.log('detail', detail);
            console.log("data-->", details);
            console.log("UserId-------?" + details.userId);
            console.log("ChannelId-----> " + details.channelId);
          })
        // Not yet registered, no user/channel

        console.log("Push Details" + details);
        console.log("UserId is " + details.userId);
        console.log("ChannelId is " + details.channelId);

        if (details?.userId)
          localStorage.setItem('mobileUserId', details.userId)

        if (localStorage.getItem('email'))
          MCEPlugin.queueUpdateUserAttributes({ email: localStorage.getItem('email'), userId: '0', website: 'Whatuni', dateUpdated: new Date().toDateString() });
      });
      MCEPlugin.setAttributeQueueCallbacks(function () {
        console.log("attribute success");
      },
        function (error) {
          console.log("attribute failure", error)
        });
      MCEPlugin.attributeQueueSuccessCallback.then(result => {
        console.log(result, "attributeQueueSuccessCallback")
      }).catch(err => {
        console.log(err, "mce err")
      });

      MCEPlugin.attributeQueueFailureCallback.then(result => {
        console.log(result, "attributeQueueFailureCallback")
      })
    });
  }
}
