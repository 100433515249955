import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './guards/redirect.guard';
import { AuthGuard } from './services/auth.guard';
import { WelcomeGuard } from './services/welcome.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'welcome'
  },
  {
    path: 'federated-redirection',
    canActivate: [RedirectGuard],
    loadChildren: () =>
      import('./pages/redirection/redirection.module').then(
        (m) => m.RedirectionPageModule
      )
  },
  {
    path: 'no-connection',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorPageModule)
  },
  {
    path: 'tab',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
    canLoad: [WelcomeGuard]
  },
  {
    path: 'sign-up-screen1',
    loadChildren: () =>
      import('./pages/sign-up-screen1/sign-up.module').then(
        (m) => m.SignUpPageModule
      )
  },
  {
    path: 'sign-up-screen2',
    loadChildren: () =>
      import('./pages/sign-up-screen2/sign-up-screen2.module').then(
        (m) => m.SignUpScreen2PageModule
      )
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./pages/sign-in/sign-in.module').then((m) => m.SignInPageModule)
  },
  {
    path: 'on-boarding',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingPageModule
      )
  },
  {
    path: 'on-boarding-type',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/on-boarding-type/on-boarding-type.module').then(
        (m) => m.OnBoardingTypePageModule
      )
  },
  {
    path: 'on-boarding-subject',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/on-boarding-subject/on-boarding-subject.module').then(
        (m) => m.OnBoardingSubjectPageModule
      )
  },
  {
    path: 'on-boarding-preference',
    loadChildren: () =>
      import(
        './pages/on-boarding-preference/on-boarding-preference.module'
      ).then((m) => m.OnBoardingTypePageModule)
  },
  {
    path: 'course-details/:institutionId/:courseId',
    loadChildren: () =>
      import('./pages/course-details/course-details.module').then(
        (m) => m.CourseDetailsPageModule
      )
  },
  {
    path: 'university-profile/:institutionId',
    loadChildren: () =>
      import('./pages/university-profile/university-profile.module').then(
        (m) => m.UniversityProfilePageModule
      )
  },
  {
    path: 'profile-gallery',
    loadChildren: () =>
      import('./pages/profile-gallery/profile-gallery.module').then(
        (m) => m.ProfileGalleryPageModule
      )
  },
  {
    path: 'events',
    loadChildren: () =>
      import('./pages/events/events.module').then((m) => m.EventsPageModule)
  },
  {
    path: 'search-events',
    loadChildren: () =>
      import('./pages/search-events/search-events.module').then(
        (m) => m.SearchEventsPageModule
      )
  },
  {
    path: 'event-detail/:institutionId',
    loadChildren: () =>
      import('./pages/event-detail/event-detail.module').then(
        (m) => m.EventDetailPageModule
      )
  },
  {
    path: 'quiz-intro',
    loadChildren: () =>
      import('./pages/quiz-intro/quiz-intro.module').then(
        (m) => m.QuizIntroPageModule
      )
  },
  {
    path: 'quiz-results',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/quiz-results/quiz-results.module').then(
        (m) => m.QuizResultsPageModule
      )
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./pages/careers/careers.module').then((m) => m.CareersPageModule)
  },
  {
    path: 'careers/:subCategorySlugUrl',
    loadChildren: () =>
      import('./pages/careers-area/careers-area.module').then(
        (m) => m.CareerAreaPageModule
      )
  },
  {
    path: 'careers/:subCategorySlugUrl/:jobSlugUrl',
    loadChildren: () =>
      import('./pages/careers-job-role/careers-job-role.module').then(
        (m) => m.CareersJobRolePageModule
      )
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forget-password/forget-password.module').then(
        (m) => m.ForgetPasswordPageModule
      )
  },
  {
    path: 'update-password',
    loadChildren: () =>
      import('./pages/update-password/update-password.module').then(
        (m) => m.UpdatePasswordPageModule
      )
  },  {
    path: 'comparison',
    loadChildren: () => import('./pages/comparison/comparison.module').then(m => m.ComparisonPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
