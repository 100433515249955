import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { apiEndpointURI } from 'src/app/utilities/constants/api-endpoints-uri';
import { environment } from 'src/environments/environment';
import { CompareRequestBody } from '../models/comparison.model';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComparisonService {

  // [{headerInfo: {},institutionInfo: [], courseInfo: []},]
  GET_COMPARISON_UNI_COURSE_INFO = environment.API_END_POINT + apiEndpointURI.viewCompareSelected;
  compareRequestBody: CompareRequestBody = {} as CompareRequestBody;
  compareResponse = {
    "compareResponse": [
      {
        "institutionId": 123,
        "institutionName": "institution name",
        "institutionLogo": "institution logo",
        "finalShortlistingFlag": true,
        "finalChoiceId": 23,
        "finalChoicePosition": 2,
        "institutionInfo": {
          "studentRating": 7.8,
          "region": "London",
          "annualRent": 4567,
          "campus": "campus",
          "maleFemaleRatio": "30 : 40",
          "cugRanking" : "45th"
        },
        "courseInfo": {
          "ucasPoints": "230",
          "employmentRate": 4567,
          "averageSalary": 4567,
          "duration": "1 year",
          "qualification": "UG"
        }
      },
      {
        "institutionId": 103,
        "institutionName": "institution name",
        "institutionLogo": "institution logo",
        "finalShortlistingFlag": true,
        "finalChoiceId": 12,
        "finalChoicePosition": 3,
        "institutionInfo": {
          "studentRating": 7.4,
          "region": "London",
          "annualRent": 5678,
          "campus": "campus",
          "maleFemaleRatio": "30:40"
        },
        "courseInfo": {
          "ucasPoints": "230",
          "employmentRate": 4567,
          "averageSalary": 345123,
          "duration": "1 year",
          "qualification": "UG"
        }
      }
    ],
    "compareLabel": {
      "institution": {
        "studentRating": "WHATUNI STUDENT RATING",
        "region": "REGION",
        "annualRent": "ANNUAL RENT(AVERAGE)",
        "campus": "CAMPUS/ NON-CAMPUS",
        "cugRanking": "COMPLETE UNIVERSITY GUIDE RANKING",
        "maleFemaleRatio": "MALE: FEMALE"
      },
      "course": {
        "ucasPoints": "UCAS POINTS",
        "employmentRate": "EMPLOYMENT RATE",
        "averageSalary": "AVERAGE GRADUATE SALARY",
        "duration": "DURATION",
        "qualification": "QUALIFICATION"
      }
    },
    "compareHelpText": {
      "institution": {
        "studentRating": "Help text",
        "region": "Help text",
        "annualRent": "Help text",
        "campus": "Help text",
        "cugRanking": "Help text",
        "maleFemaleRatio": "Help text"
      },
      "course": {
        "ucasPoints": "Help text",
        "employmentRate": "Help text",
        "averageSalary": "Help text",
        "duration": "Help text",
        "qualification": "Help text"
      }
    }
  }

  constructor(private http: HttpClient) { }

  getComparisonUniCourseInfo(): Observable<any> {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)

    const options = {
      params
    };
    // return of(this.compareResponse).pipe(delay(4000));
    const payLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "compareRequestBody": this.compareRequestBody.compareRequestBody
    }
    return this.http.post(this.GET_COMPARISON_UNI_COURSE_INFO, payLoad);
    // return this.http.post(this.GET_COMPARISON_UNI_COURSE_INFO, options);
  }

}
