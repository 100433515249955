import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ModalController } from '@ionic/angular';
import { SignUpScreen2Page } from '../sign-up-screen2/sign-up-screen2.page';
import { ApiService } from '../../services/api.service'
import { Subscription } from 'rxjs';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { ClickstreamService } from '../../services/clickstream.service';
import { AnalyticsService } from '../../services/analytics.service';
import { EnquiryData, EnquiryTypes } from '../../models/enquiry.model';
import { MODALS } from '../../enums/modals.enum';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss']
})
export class SignUpPage implements OnInit {
  content_type = 'appModuleComponent';
  apiurl = '/sign-up/name';
  pageDetails: any;
  profileform: UntypedFormGroup;
  isLoaderEnabled: boolean = false;
  enquiryFlag: boolean = false;
  @Input() enquiryType: EnquiryTypes;
  @Input() enquiryData: EnquiryData;
  pageData: any = {
  };
  clickStreamPV$: Subscription;

  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private modalCtrl: ModalController,
    private apiService: ApiService,
    private clickstreamService: ClickstreamService,
    private analytics: AnalyticsService) {
    if (!this.commonService.userDetails.userName)
      this.router.navigate(['/']);
  }

  ngOnInit() {
    this.enquiryFlag = !!this.enquiryType;
    this.pageData.heading = this.commonService.getSignInSignUpModalsHeading('signUp', this.enquiryType);
    this.getContentData();
  }

  ionViewDidEnter() {
    this.commonService.setCurrentPage(clickStreamConsts.signUpPageOne);
    this.clickStreamPV$ = this.clickstreamService.pageViewedClickstreamLogging();
    this.analytics.uxCamScreenView(clickStreamConsts.signUpPageOne);
  }

  getContentData() {
    const formGroup: any = {
    };
    this.apiService.getContentfulDetails(this.content_type, 2, this.apiurl, this.enquiryData).subscribe(res => {
      this.pageDetails = res;
      res.fieldComponents.forEach(ele => {
        formGroup[ele.fieldName] = new UntypedFormControl('', [Validators.required, this.commonService.hasLetter, this.commonService.nameValidation, Validators.minLength(2)]);
      });
      this.profileform = this.formBuilder.group(formGroup);
    })
  }

  gotoSignUpScreen2() {
    this.isLoaderEnabled = true;
    this.commonService.userDetails.firstName = String(this.profileform.controls['firstName'].value.trim());
    this.commonService.userDetails.lastName = String(this.profileform.controls['lastName'].value.trim());
    if (this.enquiryFlag)
      this.enquiryFormModal();
    else this.router.navigate(['sign-up-screen2']);
  }

  async enquiryFormModal() {
    const modal = await this.modalCtrl.create({
      component: SignUpScreen2Page,
      cssClass: 'my-modal-styles',
      id: MODALS.SIGN_UP_2,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData: this.enquiryData
      }
    })

    modal.onWillDismiss().then(res => {
      this.isLoaderEnabled = false;
      if (res?.data?.dismissed)
        this.modaldismiss(res?.data?.dismissed, res?.data?.isSubmitted);
    })
    await modal.present();
  }

  modaldismiss(dismissed?: any, isSubmitted?: any) {
    this.modalCtrl.dismiss({
      dismissed, isSubmitted
    }, null, MODALS.SIGN_UP_1);
  }

  ionViewWillLeave() {
    this.clickStreamPV$?.unsubscribe();
  }

  ionViewDidLeave() {
    this.isLoaderEnabled = false;
  }
}
