import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
@Injectable({
  providedIn: 'root'
})

export class Inappbrowser {
    constructor (private iab: InAppBrowser, private plt: Platform, private statusBar: StatusBar){}
    openBrowser (url) {
      let closebuttoncolor;
      let buttonposition;
      let fullscreen;
      if (this.plt.is('android')) {
       closebuttoncolor='#4f378b';
       buttonposition = 'yes';
       fullscreen = 'no';
      } else {
        closebuttoncolor='#3460DC';
        fullscreen = 'yes';
      }
        const options: InAppBrowserOptions = {
            clearcache: 'no',
            location: 'yes',
            hardwareback: 'yes',
            closebuttoncaption: 'Done',
            toolbar: 'yes',
            hideurlbar: 'yes',
            hidespinner: 'no',
            toolbarcolor: '#f9f9f9',
            closebuttoncolor,
            toolbartranslucent: 'no',
            toolbarposition: 'top',
            hidenavigationbuttons: 'yes',
            fullscreen,
            presentationstyle: 'fullscreen',
            disallowoverscroll: 'no', //iOS only
            enableViewportScale: 'yes', //iOS only
            allowInlineMediaPlayback: 'no',//iOS only ,
            lefttoright: buttonposition
          }
          const browser = this.iab.create(url, '_blank', options)
    }
    openApp (url) {
      const options: InAppBrowserOptions = {
          clearcache: 'no',
          location: 'yes',
          hardwareback: 'yes',
          closebuttoncaption: 'Done',
          toolbar: 'yes',
          hideurlbar: 'no',
          hidespinner: 'no',
          toolbarcolor: '#f9f9f9',
          toolbartranslucent: 'no',
          toolbarposition: 'top',
          hidenavigationbuttons: 'yes',
          fullscreen: 'yes',
          presentationstyle: 'fullscreen',
          disallowoverscroll: 'no', //iOS only
          enableViewportScale: 'yes', //iOS only
          allowInlineMediaPlayback: 'no'//iOS only
        }
        this.iab.create(url, '_system', options)
  }
}