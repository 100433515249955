export const clickStreamConsts = {
  //Event Types
  eventType: {
    prospectusRequestedClicked: "ProspectusRequestClicked",
    prospectusWebFormClicked: "ProspectusWebFormClicked",
    prospectusRequested: "ProspectusRequested",
    signedIn: "SignedIn",
    closeClicked: "CloseClicked",
    signedUp: "SignedUp",
    resendCodeClicked: "ResendCodeClicked",
    otpVerificationSuccess: "OTPVerificationSuccess",
    otpVerificationFailed: "OTPVerificationFailed",
    forgotPasswordClicked: "ForgotPasswordClicked",
    sendVerificationCodeClicked: "SendVerificationCodeClicked",
    resendVerificationEmailClicked: "ResendVerificationEmailClicked",
    updatePasswordClicked: "UpdatePasswordClicked",
    buttonClicked: "ButtonClicked",
    tileClicked: "TileClicked",
    bookEventSubmitted: "BookEventSubmitted",
    bookEventClicked: "BookEventClicked",
    bookEventExternalLinkClicked: "BookEventExternalLinkClicked",
    addToFinal5Clicked: "AddToFinal5Clicked",
    addToFinal5UnClicked: "AddToFinal5UnClicked"
  },
  actionType: {
    interaction: "Interaction",
    engagement: "Engagement"
  },
  ctaTile: {
    facebook: "Continue with Facebook",
    google: "Continue with Google",
    apple: "Continue with Apple"
  },
  functionalityName: {
    forgotPassword: "Forgot Password",
    favourites: "Favourites",
    openDay: "Open Day",
    final5: "Final 5"
  },
  appOpenEvent: "AppOpened",
  appCloseEvent: "AppClosed",
  shortListed: "Shortlisted",
  courseViewed: "CourseViewed",
  pageViewed: "PageViewed",
  subscribeClicked: "SubscribeClicked",
  tooltipClicked: "TooltipClicked",
  providerResultsViewed: "ProviderResultsViewed",
  searchResultsViewed: "SearchResultsViewed",
  institutionProfileViewed: "InstitutionProfileViewed",
  buttonClicked: "ButtonClicked",
  closeClicked: "CloseClicked",
  careerResultsViewed: "CareerResultsViewed",
  careerBrowsePageLinkClicked: "CareerBrowsePageLinkClicked",
  fullAssessmentFormSubmitte: "FullAssessmentFormSubmitted",
  careerMatcherClicked: "CareerMatcherClicked",
  fullAssessmentClicked: "FullAssessmentClicked",
  signedUp: "SignedUp",
  enquiryFormClicked: "EnquiryFormClicked",
  enquiryWebFormClicked: "EnquiryWebFormClicked",
  enquiryFormSubmitted: "EnquiryFormSubmitted",
  enquiryFormButtonClicked: "GetMoreInfoClicked",
  visitWebsite: "VisitWebsite",
  shareLabel: "Share Icon",
  careerMatcher: "Career Matcher",
  careerTileClicked: "CareerTileClicked",
  jobProfileTileClicked: "JobProfileTileClicked",
  careerBrowsePageViewed: "CareerBrowsePageViewed",
  jobProfilePageViewed: "JobProfilePageViewed",
  courseTileClicked: "CourseTileClicked",
  searchResultsLinkClicked: "SearchResultsLinkClicked",

  //Action Types
  interaction: "Interaction",
  engagement: "Engagement",

  //Page Names
  welcomePage: "Welcome page",
  signInPage: "Sign in page",
  signUpPageOne: "Sign up name page",
  signUpPageTwo: "Sign up password page",
  forgotPassword: "Forgot password page",
  updatePassword: "Update password page",
  onBoardingYOE: "Onboarding year of entry page",
  onBoardingType: "Onboarding study level page",
  onBoardingSubject: "Onboarding subjects page",
  onBoardingPreference: "Onboarding preference page",
  home: "Home page",
  courseDetailPage: "Course details page",
  searchResultPage: "Search results page",
  userProfile: "User profile page",
  coldStartPage: "Cold start search page",
  shortListPage: "Shortlist page",
  favouritesFinal5Page: "Favourite Final 5 page",
  institutionProfilePage: "Institution profile page",
  institutionOpendayPage: "Institution open day detail page",
  opendayLandingPage: "Openday landing page",
  reviewLandingPage: "Read Reviews Listing Page",
  quizLandingPage: "Quiz Landing Page",
  quizResultPage: "Quiz Results Page",
  quizQuestionsPage: "Quiz Questions Page",
  careerBrowserPage: "Career Browser Page",
  careerAreaPage: "Career Area Page",
  jobRolePage: "Job Role Page",

  //Seaction names
  sectionKeystats: "Key Stats",
  sectionTutionFees: "Tution fees",
  clearingSection: "Clearing",
  favouritesSection: "Favourites",

  //CTA Titles
  ctaTitleFav: "Favourite",
  ctaTitleRequestInfo: "Request info",
  ctaTitleVisitWebsite: "Visit website",
  ctaTitleBookEvents: "Book",
  saveYourSettings: "Save your settings",
  ctaClearing: "Visit now",

  //Tabs
  universityTap: "University",
  courseTap: "Course",

  //Functionalities
  functionalitySignUp: "Sign Up",
  functionalityOnBoarding: "Onboarding",
  functionalityEnquiry: "enquiry",
  functionalityCourseSR: "Course search results",
  functionalityUniversitySR: "University search results",
  functionalityProviderR: "Provider results",
  userSource: "WU app",
  clearingFunstionality: "Clearing",
  institutionCountryName: "United Kingdom"
}