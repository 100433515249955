import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanLoad, CanActivate {
  constructor (private router: Router) { }
  canActivate (): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return localStorage.getItem('guestLogin') ? this.router.parseUrl('/') : true;
  }

  canLoad (): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userId: boolean = !!localStorage.getItem('userid');
    const guestLogin: boolean = !!localStorage.getItem('guestLogin');
    //If don't have userId means redirect to welcome screen
    if (!userId && !guestLogin)
      return this.router.parseUrl('/');
    return true;
  }
}
