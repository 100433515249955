import { Injectable } from '@angular/core';
import { ForgotPassOtpTriggeredStatus } from './forgot-password.model';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  getStorageKey(): string {
    return "forgotPassOtpTriggeredStatus" + (localStorage.getItem('email') ? localStorage.getItem('email')?.toLocaleLowerCase() : '');
  }

  isLastTriggerExpired() {
    const lastTriggeredDate: string | null = this.getPassOtpTriggeredHistory()?.lastTriggeredDate;
    if (!lastTriggeredDate)
      return true;
    const currentdate = new Date();
    const differenceInTime = currentdate.getTime() - new Date(lastTriggeredDate).getTime();
    // To calculate the no. of days between two dates
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    //To display the final no. of days (result)
    if (differenceInDays >= 1)
      return true;
    else
      return false;
  }

  getPassOtpTriggeredHistory(): null | ForgotPassOtpTriggeredStatus {
    const forgotPassOtpTriggeredStatus: null | ForgotPassOtpTriggeredStatus = JSON.parse(localStorage.getItem(this.getStorageKey()) || null);
    if (!forgotPassOtpTriggeredStatus)
      return null;
    return forgotPassOtpTriggeredStatus;
  }

  setOtpTriggerStatus() {
    const newTriggerCount = this.getPassOtpTriggeredHistory()?.triggeredCount ? (this.getPassOtpTriggeredHistory()?.triggeredCount + 1) : 1;
    const triggerObj: ForgotPassOtpTriggeredStatus = {
      lastTriggeredDate: (newTriggerCount - 1) === 4 ? (new Date().toString()) : (this.getPassOtpTriggeredHistory()?.lastTriggeredDate || (new Date().toString())),
      triggeredCount: newTriggerCount,
      email: localStorage.getItem('email') || ''
    }
    localStorage.setItem(this.getStorageKey(), JSON.stringify(triggerObj));
  }

  isOtpDayCountExceeds(): boolean {
    const forgotPassOtpTriggeredStatus = this.getPassOtpTriggeredHistory()?.triggeredCount || 0;
    if (forgotPassOtpTriggeredStatus >= 5)
      return true;
    return false;
  }

  isSameUser(): boolean {
    if (localStorage.getItem('email') === this.getPassOtpTriggeredHistory()?.email)
      return true;
    return false;
  }

  isOtpLimitExceeds(): boolean {
    if (!this.isSameUser() || this.isLastTriggerExpired()) {
      localStorage.removeItem(this.getStorageKey());
      return false;
    } else if (this.isOtpDayCountExceeds()) {
      return true;
    }
    return false;
  }
}
