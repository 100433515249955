import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { EnquiryOnboardingComponent } from '../../pages/enquiry-onboarding/enquiry-onboarding.component';
import { AnalyticsService } from '../../services/analytics.service';
import { ApiService } from '../../services/api.service';
import { ClickstreamService } from '../../services/clickstream.service';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { Inappbrowser } from '../../utilities/common/inappbrowser';
import { constants } from '../../utilities/constants/constants';
import { EnquiryData, EnquiryTypes } from '../../models/enquiry.model';
import { EnquiryModalComponent } from '../../modals/enquiry-modal/enquiry-modal.component';
import { WelcomePage } from '../../pages/welcome/welcome.page';
import { MODALS } from '../../enums/modals.enum';
import { GetProspectusFavContent } from '../../utilities/constants/modal.const';
import { Subscription } from 'rxjs';
import { ClickStream } from '../../models/click-stream';
import { SocialLoginState } from '../../models/social-login-state';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-added-your-favourites',
  templateUrl: './added-your-favourites.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddedYourFavouritesComponent implements OnInit {
  @Input() modalType: 'favourite_info' | 'favourite_success';
  @Input() enquiryData: EnquiryData;
  enquiryDetails: any;
  modalData = new ModalData();
  webFormUrl: string;
  suborderItemId: string;
  alreadyEnquiredFlag: boolean = false;
  showRequestInfoBtn: boolean = false;
  showGetProspectusBtn: boolean = false;
  alreadyProsEnquiredFlag: boolean = false;
  requestProspectusWebform: string;
  modal: IonModal;
  loadSkeleton: boolean = true;
  enquiry$: Subscription;
  getProspectusCTA = "Get prospectus"

  constructor(private modalCntrl: ModalController,
    private iab: Inappbrowser,
    private clickstreamService: ClickstreamService,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private modalCtrl: ModalController,
    private commonService: CommonService) { }

  ngOnInit() {
    this.getEnquiryDetails();
    SocialLoginState.clearIfTriggeredByis(EnquiryTypes.favourite);
  }

  getEnquiryDetails = () => {
    this.enquiry$ = this.apiService.getEnquiryDetails(this.enquiryData).subscribe(res => {
      this.loadSkeleton = false;
      this.enquiryDetails = res?.enquiryDetails;
      this.suborderItemId = this.enquiryDetails?.institutionDetails?.subOrderItemId;
      this.webFormUrl = this.enquiryDetails?.institutionDetails?.addresses?.emailWebform;
      this.requestProspectusWebform = this.enquiryDetails?.institutionDetails?.addresses?.requestProspectusWebform;
      this.alreadyEnquiredFlag = this.enquiryDetails?.alreadyEnquiredFlag;
      this.alreadyProsEnquiredFlag = this.enquiryDetails?.alreadyProsEnquiredFlag;
      this.showRequestInfoBtn = this.suborderItemId && !this.enquiryDetails?.institutionDetails?.hideRequestInfoFlag && (this.enquiryDetails?.institutionDetails?.addresses?.emailWebform || this.enquiryDetails?.institutionDetails?.addresses?.email);
      this.showGetProspectusBtn = this.suborderItemId && !this.enquiryDetails?.institutionDetails?.hideRequestProsFlag;
      this.enquiryData.subOrderItemId = this.suborderItemId;
      this.getModalHeight();
      this.getModalContent();
      this.cdRef.markForCheck();
    })
  }

  getModalHeight() {
    if ((this.showGetProspectusBtn && !this.alreadyProsEnquiredFlag) && (this.showRequestInfoBtn && !this.alreadyEnquiredFlag))
      this.modal.cssClass = 'modal-sm ' + (this.modalType === 'favourite_success' ? 'get_pros_modal_h600' : 'get_pros_modal');
    else if (this.showRequestInfoBtn && !this.alreadyEnquiredFlag)
      this.modal.cssClass = 'modal-sm ' + (this.modalType === 'favourite_success' ? 'sr_reqinfo_modal' : 'fav_reqinfo_modal');
    else if (this.showGetProspectusBtn && !this.alreadyProsEnquiredFlag)
      this.modal.cssClass = 'modal-sm ' + (this.modalType === 'favourite_success' ? 'reqinfo_modal_h515' : 'fav_reqinfo_modal');
  }

  getModalContent() {
    if (this.modalType === 'favourite_info') {
      this.modalData.title = this.alreadyEnquiredFlag ? 'Info already requested' : 'Added to your favourites';
      this.modalData.description = this.alreadyEnquiredFlag ? "Looks like you've already contacted the uni." : "Now's a good time to contact the uni for more details or ask them any questions you have.";
    }
    if (this.modalType === 'favourite_success') {
      this.modalData.title = 'Added to your favourites';
      this.modalData.viewLinkText = 'See favourites';
      this.modalData.description = (this.showRequestInfoBtn && !this.alreadyEnquiredFlag) ? "Need more info on the university or course? Click the button below to contact the uni directly." : '';
    }
    if (this.showGetProspectusBtn && !this.alreadyProsEnquiredFlag)
      this.modalData = GetProspectusFavContent;
  }

  openInappBrowser(eve) {
    this.iab.openBrowser(eve);
  }

  async openRequestInfoModal() {
    if (this.webFormUrl) {
      this.clickstreamService.enquiryFormClickedClickstreamLogging(clickStreamConsts.enquiryWebFormClicked, clickStreamConsts.ctaTitleRequestInfo, this.enquiryDetails);
      this.openInappBrowser(this.webFormUrl);
      const param = {
        coupon: 'interaction_webclick', location: this.enquiryData.institutionName, page_name: this.enquiryData.pageName, item_name: this.enquiryData.subject
      }
      this.analytics.logEvent(constants.firebaseEvents.ecommerce_purchase, param);
    } else {
      const param = {
        coupon: 'interaction_request_email', location: this.enquiryData.institutionName, page_name: this.enquiryData.pageName, item_name: this.enquiryData.subject
      }
      this.analytics.logEvent(constants.firebaseEvents.ecommerce_purchase, param);
      this.clickstreamService.enquiryFormClickedClickstreamLogging(clickStreamConsts.enquiryFormClicked, clickStreamConsts.ctaTitleRequestInfo, this.enquiryDetails);
      const modal = await this.modalCntrl.create({
        component: EnquiryOnboardingComponent,
        cssClass: 'my-modal-styles',
        id: MODALS.ENQUIRY_YOE,
        componentProps: {
          enquiryType: 'request_info',
          enquiryData: {
            'institutionId': this.enquiryData?.institutionId,
            'courseId': this.enquiryData?.courseId,
            'subOrderItemId': this.suborderItemId,
            'institutionName': this.enquiryData.institutionName,
            'pageName': this.enquiryData.pageName,
            'subject': this.enquiryData.subject
          }
        }
      })
      modal.onWillDismiss().then(res => {
        if (res?.data?.dismissed)
          this.modalDismiss();
        const requestinfoData = {
          "institutionId": this.enquiryData.institutionId,
          "disableRequestInfo": res?.data?.isSubmitted
        }
        this.commonService.emitRequestInfoDisableLister(requestinfoData);
        this.cdRef.markForCheck();
      })
      await modal.present();
    }
  }

  getProspectus() {
    const param = { 'item_category': this.enquiryData?.institutionName, "coupon": 'engagement_getprospectus', "location": this.enquiryData?.institutionName, "page_name": this.enquiryData?.pageName, "item_name": this.enquiryData?.subject }
    this.analytics.logEvent(constants.firebaseEvents.engagement_getprospectus, param);
    if (this.requestProspectusWebform) {
      this.openInAppBrowser(this.requestProspectusWebform)
      const param = { 'item_category': this.enquiryData?.institutionName, "coupon": 'interactionexternal_getprospectus', "location": this.enquiryData?.institutionName, "page_name": this.enquiryData?.pageName, "item_name": this.enquiryData?.subject }
      this.analytics.logEvent(constants.firebaseEvents.interactionexternal_getprospectus, param);
      this.logClickStream(clickStreamConsts.eventType.prospectusWebFormClicked);
    } else {
      this.openGetProspectusModal();
      this.logClickStream(clickStreamConsts.eventType.prospectusRequestedClicked);
    }
  }

  openInAppBrowser(url: string) {
    this.iab.openBrowser(url);
    this.cdRef.detectChanges();
  }

  async openGetProspectusModal() {
    const uid = localStorage.getItem('userid') ? localStorage.getItem('userid') : '';
    const modal = await this.modalCtrl.create({
      component: uid ? EnquiryModalComponent : WelcomePage,
      cssClass: 'my-modal-styles',
      id: uid ? MODALS.ENQUIRY_YOE : MODALS.WELCOME,
      componentProps: {
        enquiryType: EnquiryTypes.get_prospectus,
        enquiryData: this.enquiryData
      }
    })
    modal.onWillDismiss().then(res => {
      if (res?.data?.isSubmitted)
        this.modalCtrl.dismiss({
          ...res?.data, isProspectusSubmitted: res?.data?.isSubmitted
        }, null, MODALS.FAVOURITE);
      this.cdRef.detectChanges();
    })
    return await modal.present();
  }

  logClickStream(eventType: string) {
    const clickStreamData = new ClickStream();
    clickStreamData.sectionName = undefined;
    clickStreamData.functionalityName = undefined;
    clickStreamData.eventType = eventType;
    clickStreamData.actionType = clickStreamConsts.interaction;
    clickStreamData.CTATitle = this.getProspectusCTA;
    this.clickstreamService.enquiryFormClickedClickstreamLogging(eventType, this.getProspectusCTA, this.enquiryDetails)
  }

  modalDismiss(dismissed?) {
    this.modalCntrl.dismiss({
      dismissed
    }, null, MODALS.FAVOURITE);
    this.enquiry$?.unsubscribe();
  }
}

export class ModalData {
  title: string
  description: string
  viewLinkText?: string
}
