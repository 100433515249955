import { animate, query, stagger, style, transition, trigger } from "@angular/animations";

export const staggerAnimationForOpenDay = trigger('staggerOpenDay', [
    transition('* <=> *', [
        query('app-open-day-event-tile', [
            style({ opacity: 0, scale: 0.9 }),
            stagger(20, [
                animate('0.5s cubic-bezier(0.35, 0, 0.25, 1)',
                    style({ opacity: 1, scale: 1 }))
            ])
        ], { optional: true }
        )
    ])
]);