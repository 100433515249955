export enum SearhTab {
  Top,
  Course,
  University
}

export enum courseAndUniTab {
  Course,
  University
}

export enum SearchType {
  SUBJECT = 'SUBJECT',
  UNIVERSITY = 'UNIVERSITY'
}

export enum FavouriteTabs {
  Favourites = "favourites",
  Final5 = "final-five",
  Careers = "careers"
}