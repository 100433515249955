import { environment } from '../../environments/environment'

const awsconfig = {
  Auth: {
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    // For web view (or) localhost
    storage: localStorage,
    oauth: {
      domain: environment.oAuth_Domain,
      // For web view (or) localhost
      redirectSignIn: 'http://localhost:8100/federated-redirection',
      redirectSignOut: 'http://localhost:8100/welcome',
      responseType: "code"
    }
  }
};

export default awsconfig;
