import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Auth } from 'aws-amplify';
import { MODALS } from '../../enums/modals.enum';
import { ForgotPasswordService } from '../../pages/forget-password/forgot-password.service';
import { ClickstreamService } from '../../services/clickstream.service';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { CommonService } from '../../services/common.service';
import { heightAnimateInOut } from '../../animations/success-msg.animation';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [heightAnimateInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent {
  @Input() isModal = false;
  email: string;
  btnLoader = false;
  isLimitExceeded = false;
  sendEmailCta = 'Send email';

  constructor(private router: Router,
    private navCtrl: NavController,
    private cdRef: ChangeDetectorRef,
    private wuniModalCtrl: WuniModalControllerService,
    private clickstreamService: ClickstreamService,
    private commonService: CommonService,
    private forgotPasswordService: ForgotPasswordService) {
    this.email = localStorage.getItem('email');
  }

  ionViewDidEnter() {
    this.commonService.setCurrentPage(clickStreamConsts.forgotPassword);
  }

  navigateBack() {
    this.isModal ? this.wuniModalCtrl.dismiss(MODALS.FORGOT_PASSWORD) : this.navCtrl.back();
  }

  sendEmail() {
    this.isLimitExceeded = this.forgotPasswordService.isOtpLimitExceeds();
    if (this.isLimitExceeded)
      return
    this.btnLoader = true;
    Auth.forgotPassword(this.email)
      .then((data) => {
        this.forgotPasswordService.setOtpTriggerStatus();
        this.isModal ? this.wuniModalCtrl.openModal(MODALS.UPDATE_PASSWORD, null, null, clickStreamConsts.forgotPassword) : this.router.navigate(['update-password']);
        this.btnLoader = false;
        this.cdRef.markForCheck();
        this.clickstreamService.logForgotPasswordEvents(clickStreamConsts.eventType.sendVerificationCodeClicked, this.sendEmailCta);
      })
      .catch((err) => {
        if (err.code === "LimitExceededException")
          this.isLimitExceeded = true;
        if (err.code === "UserNotFoundException")
          this.isModal ? this.wuniModalCtrl.openModal(MODALS.UPDATE_PASSWORD) : this.router.navigate(['update-password']);

        this.btnLoader = false;
        this.cdRef.markForCheck();
      });
    this.cdRef.markForCheck();
  }
}
