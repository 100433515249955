import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Auth } from 'aws-amplify';

import { ClickstreamService } from '../../services/clickstream.service';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';
import { MODALS } from '../../enums/modals.enum';
import { CommonService } from '../../services/common.service';
import { ForgotPasswordService } from '../../pages/forget-password/forgot-password.service';
import { heightAnimateInOut } from '../../animations/success-msg.animation';
import { updatePassForm, updatePassFormFields } from '../../pages/forget-password/forgot-password.model';

type sentEmailStatus = null | 'initial' | 'resent';

@Component({
  selector: 'update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
  animations: [heightAnimateInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  @Input() isModal = false;
  email: string;
  errors = {
    'invalid': false,
    'codeExpired': false,
    'limitExceeded': false
  }
  emailSentType: sentEmailStatus = null;
  updatePasswordCTA = "Update password";
  resendCodeCTA = "Resend email";
  passValidators = [
    Validators.required,
    Validators.minLength(8),
    this.commonService.hasLowerCase,
    this.commonService.hasNumbers,
    this.commonService.hasUpperCase,
    this.commonService.hasSpecialChar
  ];
  updatePassForm: FormGroup<updatePassForm>;
  updatePassFormFields: updatePassFormFields[] = [
    {
      placeHolder: 'Verification code',
      formControlName: 'verificationCode',
      type: 'verificationCode',
      hidePass: true,
      length: 6
    },
    {
      placeHolder: 'New password',
      formControlName: 'password',
      type: 'password',
      hidePass: true,
      length: 20
    }
  ]
  errorsData: Array<{ error: string, errorMsg: string }> = [
    {
      error: 'upperCaseError',
      errorMsg: 'ABC'
    },
    {
      error: 'lowerCaseError',
      errorMsg: 'abc'
    },
    {
      error: 'numberError',
      errorMsg: '123'
    },
    {
      error: 'specialCharError',
      errorMsg: '!@%'
    },
    {
      error: 'minlength',
      errorMsg: '8 characters'
    }
  ];
  btnLoader = false;
  otpResendTimer: any;
  resendTimer: any;

  constructor(private commonService: CommonService,
    private navCtrl: NavController,
    private clickstreamService: ClickstreamService,
    private cdRef: ChangeDetectorRef,
    private wuniModalCtrl: WuniModalControllerService,
    private router: Router,
    private forgotPasswordService: ForgotPasswordService) {
  }

  ngOnInit() {
    this.updatePassForm = this.getFormGroup(this.updatePassFormFields);
    this.email = localStorage.getItem('email');
    if (!this.errors.limitExceeded) {
      this.setSentEmailStatus('initial');
      this.startResendCodeTimer(1);
    }
    this.updatePassForm.controls.verificationCode.valueChanges.subscribe(() => {
      this.errors.codeExpired = false;
      this.errors.invalid = false;
      this.cdRef.markForCheck();
    })
  }

  ionViewDidEnter() {
    this.commonService.setCurrentPage(clickStreamConsts.updatePassword);
  }

  getFormGroup(formFields: updatePassFormFields[]) {
    const formGroup: updatePassForm = {} as updatePassForm;
    formFields.forEach((field: updatePassFormFields) => {
      formGroup[field.formControlName] = new FormControl('', { nonNullable: true, validators: field.type === 'password' ? this.passValidators : [Validators.required, Validators.minLength(field.length), Validators.maxLength(field.length)] });
    });
    return new FormGroup<updatePassForm>(formGroup);
  }

  close() {
    if (this.isModal)
      this.wuniModalCtrl.dismiss(MODALS.UPDATE_PASSWORD, { updatePassStatus: 'closed' })
    else
      this.navCtrl.navigateRoot(['sign-in']);
  }

  preventOtherThanNumbers(event: any, formField: updatePassFormFields) {
    if (formField.formControlName === 'password')
      return
    const pattern = /^([0-9])$/;
    const inputChar = String.fromCharCode(event.charCode);
    const value = this.updatePassForm.controls[formField.formControlName].value.length
    if (!pattern.test(inputChar) || !(/^\d+$/.test(event.key)) || value >= formField?.length)
      event.preventDefault();
  }

  updatePassword() {
    this.btnLoader = true;
    Auth.forgotPasswordSubmit(this.email, this.updatePassForm.controls.verificationCode.value, this.updatePassForm.controls.password.value)
      .then((data) => {
        if (this.isModal) {
          this.wuniModalCtrl.dismiss(MODALS.UPDATE_PASSWORD, { updatePassStatus: 'updated' });
        }
        else {
          this.router.navigate(['sign-in']);
          this.wuniModalCtrl.emittedData.next({ modalId: MODALS.FORGOT_PASSWORD, data: { updatePassStatus: 'updated' } });
        }
        this.btnLoader = false;
        this.cdRef.markForCheck();
        this.clickstreamService.logForgotPasswordEvents(clickStreamConsts.eventType.updatePasswordClicked, this.updatePasswordCTA);
      })
      .catch((err) => {
        switch (err.code) {
          case "CodeMismatchException":
            this.errors.invalid = true;
            break;
          case "ExpiredCodeException":
            this.errors.codeExpired = true;
            break;
        }
        this.btnLoader = false;
        this.cdRef.markForCheck();
      });
    this.cdRef.markForCheck();
  }

  setSentEmailStatus(status: sentEmailStatus) {
    this.emailSentType = status;
    setTimeout(() => {
      this.emailSentType = null;
      this.cdRef.markForCheck();
    }, 15000);
    this.cdRef.markForCheck();
  }

  resendEmail() {
    this.errors.limitExceeded = this.forgotPasswordService.isOtpLimitExceeds();
    this.cdRef.markForCheck();
    if (this.errors.limitExceeded)
      return
    this.startResendCodeTimer(1);
    Auth.forgotPassword(this.email)
      .then(() => {
        this.forgotPasswordService.setOtpTriggerStatus();
        this.setSentEmailStatus('resent');
        this.cdRef.markForCheck();
        this.clickstreamService.logForgotPasswordEvents(clickStreamConsts.eventType.resendVerificationEmailClicked, this.resendCodeCTA);
      })
      .catch((err) => {
        this.cdRef.markForCheck();
        if (err.code === "UserNotFoundException")
          this.setSentEmailStatus('resent');
        if (err.code === "LimitExceededException")
          this.errors.limitExceeded = true;
        if (this.resendTimer)
          clearInterval(this.resendTimer);
      });
  }

  startResendCodeTimer(minute) {
    if (this.resendTimer)
      clearInterval(this.resendTimer);
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    this.resendTimer = setInterval(() => {
      seconds--;
      if (statSec !== 0) statSec--;
      else statSec = 59;
      if (statSec < 10)
        textSec = "0" + statSec;
      else textSec = statSec;
      this.otpResendTimer = textSec;
      if (seconds === 0) {
        this.otpResendTimer = "";
        clearInterval(this.resendTimer);
      }
      this.cdRef.detectChanges();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.resendTimer)
      clearInterval(this.resendTimer);
  }
}
