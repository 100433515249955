export class ClickStream {
  public siteName: string;
  public siteLanguage: string;
  public idpInstitutionId: string;
  public idpcInstitutionId: string;
  public idpcInstitutionName: string;
  public conversionPlusFlag: string;
  public institutionCountryName: string;
  public idpCourseId: string;
  public idpCourseName: string;
  public idpcCourseId: string;
  public idpcCourseName: string;
  public eventTime: string;
  public eventType: string;
  public pageName: string;
  public sectionName: string;
  public CTATitle: string;
  public videoName: string;
  public videoViewedPercentage: string;
  public functionalityName: string;
  public videoCategory: string;
  public actionType: string;
  public ldcs1Code: string;
  public ldcs1CodeDesc: string;
  public ldcs2Code: string;
  public ldcs2CodeDesc: string;
  public ldcs3Code: string;
  public ldcs3CodeDesc: string;
  public browseNodeSubjects: string;
  public studyLevelId: string;
  public studyLevel: string;
  public childStudyLevelId: string;
  public childStudyLevel: string;
  public studyModeId: string;
  public studyMode: string;
  public manualBoostingFlag: string;
  public orderItemId: string;
  public subOrderItemId: string;
  public sourceOrderItemId: string;
  public userId: string;
  public idpcUserId: string;
  public sessionTrackId: string;
  public userIPAddress: string;
  public userAgent: string;
  public campaignId: string;
  public requestURL: string;
  public refererURL: string;
  public screenResolution: string;
  public isMobileUser: string;
  public mobileDeviceModel: string;
  public mobileDeviceOS: string;
  public C4CStudentId: string;
  public signedupMethod: string;
  public signupFailureReason: string;
  public signedInMethod: string;
  public signinFailureReason: string;
  public userloggedIn: string;
  public isAdvertiser: string;
  public courseAvailabilityId: string;
  public courseAvailabilityIsOipable: string;
  public courseFee: string;
  public courseFeeCurrency: string;
  public searchKeyword: string;
  public oipMatchingTypePerformed: string;
  public oipSummaryMatched: string;
  public oipDetailedMatched: string;
  public oipInitiationFailureReason: string;
  public interestedSubjectArea: string;
  public interestedIntakeYear: string;
  public interestedStudyLevel: string;
  public interestedCountry: string;
  public englishLanguageTestScore: string;
  public englishLanguageTestType: string;
  public articleId: string;
  public articleTitle: string;
  public articleTopic: string;
  public nearestIDPOfficeID: string;
  public viewedPercentage: string;
  public preferredCounsellingMode: string;
  public referralSource: string;
  public paginationNumber: string;
  public sortOption: string;
  public appUserId: string;
  public CREQualification: string;
  public CREDestinations: string;
  public CRERegionCity: string;
  public locationType: string;
  public UCASCode: string;
  public UCASPoints: string;
  public SRInstitutionIDOrder: string;
  public userSource: string;
  public sourcePageName: string;
  public interestedIntakeMonth: string;
  public funding: string;
  public pushNotificationConsent: string;
  public newsletterConsent: string;
  public solusConsent: string;
  public surveyConsent: string;
  public latitude: string;
  public longitude: string;
  public hecos1Code: string;
  public hecos2Code: string;
  public appFlag: string;
  public networkId: string;
  public countryOfResidence: string;
  public countryOfCitizenship: string;
  public eventMode: string;
  public month: string;
  public year: string;
  public careerParentCategory: string;
  public careerChildCategory: string;
  public carrerParentCategoryMatchPercent: string;
  public careerChildCategoryMatchPercent: string;
  public jobProfileMatchPercent: string;
  public jobProfileName: string;
  public eventId: string;
}

export interface ClickStreamNamedParams {
  eventType: string,
  CTATitle?: string,
  sectionName?: string,
  uniCourseInfo?: any,
  moreInfo?: any
}
