import { Injectable } from '@angular/core';
import { Adjust, AdjustConfig, AdjustEnvironment, AdjustEvent } from '@awesome-cordova-plugins/adjust/ngx';
import { Platform } from '@ionic/angular';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdjustService {
  constructor (private adjust: Adjust,
    private platform: Platform) { }

  integrateAdjust () {
    if (!this.isBrowser()) {
      const modeOfTracking = environment.production ? AdjustEnvironment.Production : AdjustEnvironment.Sandbox;
      const config = new AdjustConfig(environment.adjustAppToken, modeOfTracking);
      this.adjust.create(config);
    }
  }

  adjustEventTracking (event) {
    const adjustEvent = new AdjustEvent(event);
    this.adjust.trackEvent(adjustEvent);
  }

  isBrowser (): boolean {
    return (this.platform.is('desktop') || this.platform.is('mobileweb'));
  }
}
