import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';

declare let UXCam: any;
@Injectable({
    providedIn: 'root'
})

export class AnalyticsService {
    constructor (private firebaseAnalytics: FirebaseAnalytics, private platform: Platform) { }

    logEvent (eventname, param) {
        console.log(eventname, param)
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb'))
                this.firebaseAnalytics.logEvent(eventname, param)
                    .then((res: any) => console.log('resp', res))
                    .catch((error: any) => console.error(error));
    }

    integrateUxcam () {
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
            UXCam.optIntoSchematicRecordings() /* To enable session video recording on iOS */
            UXCam.startWithKey(environment.uxcamKey);
            UXCam.setAutomaticScreenNameTagging(false);
        }
    }

    pauseEvent () {
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb'))
            UXCam.pauseScreenRecording();
    }

    resumeEvent () {
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
            console.log('not des');
            UXCam.resumeScreenRecording();
        }
    }

    setUserId (propertyName, value) {
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb'))
            UXCam.setUserProperty(propertyName, value);
    }

    uxCamScreenView (name: string) {
        if (!this.platform.is('desktop') && !this.platform.is('mobileweb'))
            UXCam.tagScreenName(name);
    }
}
