export class SingleOption {
  public optionId: string;
  public optionDesc: string;
  public selectedFlag: boolean = false;
}

export class Location {
  public isFilterSelected: boolean = false;
  public commaSeperatedValue: string;
  public commaSeperatedId: string;
  public options: SingleOption[] = [];
}

export class MultipleFilter {
  public isFilterSelected: boolean = false;
  public commaSeperatedValue: string;
  public commaSeperatedId: string;
  public options: SingleOption[] = [];
}

export class SingleFilter {
  public isFilterSelected: boolean = false;
  public selectedOption = new SingleOption();
}

export class YourGrade {
  public isFilterSelected: boolean = false;
  public score: number;
}

export class Filter {
  public sortBy = new SingleFilter();
  public score = new YourGrade();
  public region = new MultipleFilter();
  public qualification = new SingleFilter();
  public studyMode = new MultipleFilter();
  public locationType = new Location();
  public appliedFiltersCount: number;
  public selectedTab: string;
  type: any;

  public constructor () {
    this.appliedFiltersCount = 1;
    this.sortBy.selectedOption.optionId = 'TOP_PICKS';
    this.sortBy.isFilterSelected = true;
    this.score.score = 0;
  }
}

export class EventFilter {
  public region = new MultipleFilter();
  public locationType = new MultipleFilter();
  public eventType = new MultipleFilter();
  public appliedFiltersCount: number;
}
