const env = {

  contentfulApiURI: "/v1/users/sign-in",

  submitUserDataApiURI: "/v1/users/submit",

  getQualApiURI: "/v1/users/qualification",

  calculateUcasApiURI: "/v1/ucas/calculator",

  getUserDetailsApiURI: "/v1/users/details",

  getOnBoardingApiURI: "/v1/users/onboarding",

  getSubjectApiURI: "/v1/subject/getSubjectList",

  submitContactUsApiURI: "/v1/users/contact-us",

  submitDeleteURI: "/v1/users/delete",

  getCourseDetailsURL: "/v1/courses/details",

  // updateBasketURI: "/v1/favourites/save",

  getSearchResult: "/v1/recent-searches",

  getSubjectSearchResultURL: "/v1/subject/searches",

  getInstitutionResultURL: "/v1/institution/searches",

  getInstitutionCourseResultsURL: "/v1/institutions/course/searches",

  getClickStreamURI: "/v1/logs/clickstream",

  getFiltersURI: "/v1/filters/searches",

  applyFilterURI: "/v1/filters/apply/searches",

  homeURI: '/v1/home',

  viewFavourites: "/v1/favourites/viewFavourites",

  viewCompareFavourites: '/v1/favourites/viewCompareFavorites',

  favouriteInstitutionResultURI: "/v1/favourites/institutions",

  recentSearchURI: "/v1/clear-recent-searches",

  getInstitutionProfileURL: "/v1/institutions/profile",

  getPopularSubjects: "/v1/institutions/popular-subjects",

  getSuggestedUnis: "/v1/institutions/competitors",

  getGlobalErrorURL: "/v1/errors/client-error",

  interactionSubmitURI: "/v1/interactions/submit",

  homeSnippetTwoURI: "/v1/home/snippet-two",

  homeCareerMatcherURI: "/v1/home/career-matcher",

  homePopularSubjectsURI: "/v1/home/popular-subjects",

  homeCourseLikeURI: "/v1/home/course-like",

  homeSnippetOneURI: "/v1/home/snippet-one",

  coldStartSearchResultURI: "/v1/cold-start/searches",

  homeUniLikeURI: "/v1/home/uni-you-might-like",

  footerURI: "/v1/get-contentful",

  homeRecentSearch: "/v1/home/recent-searches",

  getUserProfile: "/v1/users/profile",

  getEnquiryDetailsURL: "/v1/interactions/enquiry-details",

  getVersionURL: "/v1/app/update",

  getInstitutionReview: "/v1/institutions/institution_id/review",

  getCourseReview: "/v1/courses/institution_id/course_id/review",

  getInstitutionReviewSearches: "/v1/institutions/institution_id/review/searches",

  getCourseReviewSearches: "/v1/courses/institution_id/course_id/review/searches",

  getReviewHelpful: "/v1/review/helpful/review_id",

  getEventsDetailUrl: "/v1/events/institutions/institution_id",

  getOpendaySection: "/v1/events/section",

  getEventSearchUrl: "/v1/events/searches",

  getOpenDayFeatureEventUrl: "/v1/events/featured",

  getEventsAjaxUrl: "/v1/events/ajax",

  getEventsFilterUrl: "/v1/events/filter",

  getEventFilterCountUrl: "/v1/events/applyFilter",

  getFavURL: "/v1/users/favouritesCount",

  saveToFavourites: "/v1/favourites/saveToFavourites",

  userAddress: "/v1/users/address",

  mobileOTPverify: "/v1/mobile/verify",

  getQuizIntro: "/v1/careers/landing-page",

  getQuiz: "/v1/guest/careers/quiz",

  submitQuiz: "/v1/careers/update-traits",

  getQuitResults: "/v1/careers/getResultPage",

  careersCat: "/v1/careers/category",

  careerSubCat: "/v1/guest/careers/category",

  careersSearch: "/v1/careers/searches",

  careerArea: "/v1/careers",

  jobRole: "/v1/careers",

  otherJobs: "/v1/careers/otherJobs",

  similarCourses: "/v1/careers/similarCourses",

  recommendedEvent: "/v1/events/recommendedEvents",

  viewFinalShortListURL: "/v1/finalShortListing/viewFinalShortListing",

  addToFinalShortListingURL: "/v1/finalShortListing/saveToFinalShortListing",

  updateFinalShortListingURL: "/v1/finalShortListing/updateFinalShortlistedOrder",

  viewCompareSelected: "/v1/favourites/viewCompareSelected"
};

const guestEnv = {

  homeURI: "/v1/guest/home",

  getSearchResult: "/v1/guest/recent-searches",

  getSubjectSearchResultURL: "/v1/guest/subject/searches",

  getInstitutionResultURL: "/v1/guest/institution/searches",

  getCourseDetailsURL: "/v1/guest/course/details",

  getUserDetailsApiURI: "/v1/guest/users/details",

  getInstitutionProfileURL: "/v1/guest/institutions/profile",

  getInstitutionCourseResultsURL: "/v1/guest/institutions/course/searches",

  recentSearchURI: "/v1/guest/clear-recent-searches",

  homeRecentSearch: "/v1/guest/home/recent-searches",

  getClickStreamURI: "/v1/guest/logs/clickstream",

  clickstreamSocialLogingURI: "",

  homeSnippetTwoURI: "/v1/guest/home/snippet-two",

  homeCareerMatcherURI: "/v1/guest/home/career-matcher",

  homeSnippetOneURI: "/v1/guest/home/snippet-one",

  homePopularSubjectsURI: "/v1/guest/home/popular-subjects",

  homeCourseLikeURI: "/v1/guest/home/course-like",

  homeUniLikeURI: "/v1/guest/home/uni-you-might-like",

  getUserProfile: "/v1/guest/users/profile",

  getEnquiryDetailsURL: "/v1/guest/interactions/enquiry-details",

  getQualApiURI: "/v1/guest/users/qualification",

  getSuggestedUnis: "/v1/guest/institutions/competitors",

  getInstitutionReviewSearches: "/v1/guest/institutions/institution_id/review/searches",

  getCourseReviewSearches: "/v1/guest/courses/institution_id/course_id/review/searches",

  getCourseReview: "/v1/guest/courses/institution_id/course_id/review",

  getInstitutionReview: "/v1/guest/institutions/institution_id/review",

  getReviewHelpful: "/v1/guest/review/helpful/review_id",

  getEventsDetailUrl: "/v1/guest/events/institutions/institution_id",

  getOpendaySection: "/v1/guest/events/section",

  getEventSearchUrl: "/v1/guest/events/searches",

  getOpenDayFeatureEventUrl: "/v1/guest/events/featured",

  getEventsAjaxUrl: "/v1/guest/events/ajax",

  getEventsFilterUrl: "/v1/guest/events/filter",

  getEventFilterCountUrl: "/v1/guest/events/applyFilter",

  careersSearch: "/v1/guest/careers/searches",

  careersCat: "/v1/guest/careers/category",

  careerArea: "/v1/guest/careers",

  jobRole: "/v1/guest/careers",

  otherJobs: "/v1/guest/careers/otherJobs",

  similarCourses: "/v1/guest/careers/similarCourses"
}

export const apiEndpointURI = env;

export const guestApiEndPointURL = guestEnv;
