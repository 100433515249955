import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SocialLoginState } from '../models/social-login-state';

@Injectable({
    providedIn: 'root'
})

export class RedirectGuard implements CanActivate {
    constructor (private router: Router) { }

    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!SocialLoginState?.getSocialLoginTriggeredPoint?.pageUrl) {
            this.router.navigateByUrl('/tab/home');
            return false;
        }
        return true;
    }
}
