import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AnalyticsService } from '../../services/analytics.service';
import { constants } from '../../utilities/constants/constants';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ClickStream } from '../../models/click-stream';
import { ClickstreamService } from '../../services/clickstream.service';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { NotificationService } from '../../services/notification.service';
import { MODALS } from '../../enums/modals.enum';

@Component({
  selector: 'app-enquiry-success',
  templateUrl: './enquiry-success.component.html',
  styleUrls: ['./enquiry-success.component.scss']
})
export class EnquirySuccessComponent implements OnInit {
  apiUrl: string = '/interactions/success-page';
  isLoaderEnabled: boolean = false;
  enquiryFlag: boolean = false;
  @Input() enquiryType: string;
  @Input() enquiryDetails: any;
  @Input() clickStreamInfo: any;
  pageData: any = {
};
  preferenceDetails: any;
  isSubmitted: boolean = false;
  institutionName: any;
  disableSubmit = true;
  clickStream$: Subscription;
  public sub1$: Subscription = new Subscription();

  constructor (private apiService: ApiService,
    private modalCtrl: ModalController,
    private analytics: AnalyticsService,
    private notification: NotificationService,
    private cd: ChangeDetectorRef,
    private platform: Platform,
    private clickstreamService: ClickstreamService) {
    this.sub1$ = platform.resume.subscribe((e) => {
      const index = this.preferenceDetails?.fieldComponents?.findIndex((item) => item?.sectionName === 'pushNotification');
      this.notification.notificationPermission().then(() => {
        this.preferenceDetails.fieldComponents[index].selectValue = true;
        this.cd.detectChanges();
      }).catch(() => {
        this.preferenceDetails.fieldComponents[index].selectValue = false;
        this.cd.detectChanges();
      });
    });
  }

  ngOnInit () {
    this.enquiryFlag = !!this.enquiryType;
    this.getPageData();
    this.submitEnquiry();
  }

  ionViewDidEnter () {
    this.analytics.logEvent(constants.firebaseEvents.screenview, {
 page_name: this.apiUrl
});
  }

  getPageData () {
    if (this.enquiryFlag) {
      if (this.enquiryType?.includes('favourite') || this.enquiryType === "GUEST-LOGIN")
        this.pageData.heading = 'Sign up';
       else if (this.enquiryType?.includes('request_info'))
        this.pageData.heading = 'Request info';
       else
        this.pageData.heading = 'Subscribe';

      this.institutionName = this.enquiryDetails?.institutionDetails?.institutionName;
    }
  }

  submitEnquiry (): void {
    this.isLoaderEnabled = true;
    this.apiService.submitInteraction(this.apiUrl, this.enquiryDetails).subscribe((data: any) => {
      if (data?.pageName) {
        this.isLoaderEnabled = false;
        if (!data?.alreadyEnquiredFlag)
          this.clickStream$ = this.clickstreamService.enquiryFormSubmittedClickstreamLogging(clickStreamConsts.ctaTitleRequestInfo, this.clickStreamInfo);
        this.pageData = Object.assign(this.pageData, data);
        this.preferenceDetails = this.pageData.moduleComponents[0];
        data?.moduleComponents[0]?.fieldComponents?.forEach((ele: any) => {
          ele.selectValue = !!((ele?.fieldValue === 'Y' || ele?.selectValue));
        })
        const notificationPrefIndex = this.preferenceDetails?.fieldComponents?.findIndex((item) => item?.sectionName === 'pushNotification')
        this.getCheckedList('init', this.preferenceDetails?.fieldComponents[notificationPrefIndex], notificationPrefIndex);
        this.isSubmitted = true;
      }
    }, () => {
      this.isLoaderEnabled = false;
    })
  }

  submitPreference (): void {
    const data = {
 "userData": []
}
    this.preferenceDetails.fieldComponents.forEach(ele => {
      data.userData.push({
 'fieldName': ele.sectionName, 'fieldValue': ele.selectValue ? 'Y' : 'N'
});
    });
    this.apiService.submitPersonal(data).subscribe((res: any) => {
      const clickstream = new ClickStream();
      const mapData = {
};
      data?.userData?.forEach(d => {
        mapData[d.fieldName] = d.fieldValue;
      });

      clickstream.pushNotificationConsent = mapData["pushNotification"] || undefined;
      clickstream.newsletterConsent = mapData["marketingSolus"] || undefined;
      clickstream.solusConsent = mapData["marketingSolus"] || undefined;
      clickstream.surveyConsent = mapData["surveyFlag"] || undefined;
      clickstream.idpcInstitutionId = this.enquiryDetails?.institutionDetails?.institutionId;
      clickstream.idpcCourseId = this.enquiryDetails?.courseId;
      clickstream.idpcCourseName = this.enquiryDetails?.courseName;
      this.clickstreamService.onBoardingClickstreamLogging(null, clickStreamConsts.functionalityEnquiry, clickStreamConsts.saveYourSettings, clickstream, clickStreamConsts.enquiryFormButtonClicked);
      if (res?.message?.toLowerCase()?.includes('updated'))
        this.modaldismiss();
    })
  }

  getCheckedList = (action, item, i) => {
    console.log(item.selectValue, 'itempreference');
    if (item?.sectionName === 'pushNotification')
      if (action === 'init')
        this.notification.notificationPermission().then((res) => {
          this.preferenceDetails.fieldComponents[i].selectValue = true;
          this.cd.detectChanges();
        }).catch(() => {
          if (item.selectValue) {
            this.preferenceDetails.fieldComponents[i].selectValue = false;
            this.cd.detectChanges();
          }
        });
       else if (action === 'select')
        this.notification.notificationPermission().then((res) => {
          if (item.selectValue) {
            this.preferenceDetails.fieldComponents[i].selectValue = true;
            this.cd.detectChanges();
          } else if (!item.selectValue) {
            this.notification.openNotificationSettings();
          }
        }).catch(() => {
          if (item.selectValue)
            this.notification.openNotificationSettings();
        });

    this.disableSubmit = !this.preferenceDetails.fieldComponents.some(ele => (item.selectValue === ele.sectionName) ? !item.selectValue : ele.selectValue);
  }

  modaldismiss () {
    this.modalCtrl.dismiss({
 dismissed: true, isSubmitted: this.isSubmitted || false
}, null, MODALS.ENQUIRY_SUCCESS);
  }

  ionViewDidLeave () {
    this.sub1$.unsubscribe();
  }

  ngOnDestroy (): void {
    this.clickStream$?.unsubscribe();
  }
}
