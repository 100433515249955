import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MODALS } from '../../enums/modals.enum';
import { ClickStream } from '../../models/click-stream';
import { EnquiryData, EnquiryType, EnquiryTypes } from '../../models/enquiry.model';
import { AnalyticsService } from '../../services/analytics.service';
import { ApiService } from '../../services/api.service';
import { ClickstreamService } from '../../services/clickstream.service';
import { NotificationService } from '../../services/notification.service';
import { Inappbrowser } from '../../utilities/common/inappbrowser';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { constants } from '../../utilities/constants/constants';
import { WuniModalControllerService } from '@services/wuni-modal-controller.service';
import { BookedEvent } from '../../models/open-day';
import { EventApiService } from '@services/eventApi.service';

@Component({
  selector: 'app-enquiry-success-modal',
  templateUrl: './enquiry-success-modal.component.html',
  styleUrls: ['./enquiry-success-modal.component.scss']
})

export class EnquirySuccessModalComponent implements OnInit, OnDestroy {
  @Input() enquiryType: EnquiryType;
  @Input() enquiryDetails: any;
  @Input() enquiryData: EnquiryData;
  @Input() clickStreamInfo: any;
  @Input() addressData: any;
  @Input() hasNoRecommendedEvents: boolean = false;

  apiUrl: string = '/interactions/prospectus/success-page';
  isLoaderEnabled: boolean = false;
  pageData: any = {};
  enquiryTypes = EnquiryTypes;
  preferenceDetails: any;
  isSubmitted: boolean = false;
  disableSubmit = true;
  clickStream$: Subscription;
  websiteUrl: string;
  alreadyProsEnquiredFlag: boolean = false;
  successMsg: string = "Successfully ordered prospectus from";
  exceedSuccessMsg = "You've exceeded the number of times you can order a prospectus from this provider, through Whatuni, Soz."
  public sub1$: Subscription = new Subscription();
  submit$: Subscription;
  preferenceHeapLabel = '';
  bookedEvents: BookedEvent[];

  constructor(private apiService: ApiService,
    private modalCtrl: ModalController,
    private analytics: AnalyticsService,
    private notification: NotificationService,
    private cd: ChangeDetectorRef,
    private platform: Platform,
    private iab: Inappbrowser,
    private wuniModalCtrl: WuniModalControllerService,
    private eventApiService: EventApiService,
    private clickstreamService: ClickstreamService) {
    this.sub1$ = this.platform.resume.subscribe((e) => {
      const index = this.preferenceDetails?.fieldComponents?.findIndex((item) => item?.sectionName === 'pushNotification');
      this.notification.notificationPermission().then(() => {
        this.preferenceDetails.fieldComponents[index].selectValue = true;
        this.cd.detectChanges();
      }).catch(() => {
        this.preferenceDetails.fieldComponents[index].selectValue = false;
        this.cd.detectChanges();
      });
    });
  }

  ngOnInit() {
    this.getPageData();
    this.submitEnquiry();
    this.successMsg = this.successMsg + ' ' + (this.enquiryData?.institutionName || '');
  }

  ngOnChanges() {
    this.successMsg = this.successMsg + ' ' + (this.enquiryData?.institutionName || '');
  }

  ionViewDidEnter() {
    this.analytics.logEvent(constants.firebaseEvents.screenview, {
      page_name: this.apiUrl
    });
  }

  getPageData() {
    switch (this.enquiryType) {
      case EnquiryTypes.request_info:
        this.pageData.heading = 'Request info';
        break;
      case EnquiryTypes.get_prospectus:
        this.pageData.heading = 'Get prospectus';
        break;
      case EnquiryTypes.book_event:
        this.pageData.heading = 'Book open day';
        this.apiUrl = '/interactions/events/success-page';
        break;
      default:
        this.pageData.heading = 'Subscribe';
        break;
    }
  }

  visitWebsite(url?: string) {
    this.iab.openBrowser(url);
    this.analytics.logEvent('ecommerce_purchase', {
      coupon: 'interaction_webclick', location: this.enquiryData.institutionName, page_name: this.enquiryData.pageName, item_name: this.enquiryData.subject
    });
    this.clickstreamService.visitWebsiteClickStreamLogging(clickStreamConsts.ctaTitleVisitWebsite, this.enquiryDetails);
  }

  submitEnquiry(): void {
    this.isLoaderEnabled = true;
    let data: any = {};
    let enqDetails: any;
    if (this.enquiryType === EnquiryTypes.book_event) {
      enqDetails = this.enquiryDetails?.map((ele: any) => this.eventApiService.getActualEventSubmitData(ele));
      data = { "recommendationPageFlag": "Y" }
    }
    else {
      enqDetails = this.enquiryDetails;
      data = { "enquiryAttributes": this.addressData }
    }
    this.submit$ = this.apiService.submitInteraction(this.apiUrl, enqDetails, data).subscribe((data: any) => {
      if (data?.pageName) {
        this.alreadyProsEnquiredFlag = data?.alreadyProsEnquiredFlag;
        this.isLoaderEnabled = false;

        if (this.enquiryType === EnquiryTypes.book_event) {
          const initiallySubmittedEvent: any = {
            institutionName: this.enquiryData?.institutionName,
            eventDate: this.enquiryData?.eventDate,
            eventLocation: this.enquiryData?.location || this.enquiryData.eventLocation,
            institutionLogoUrl: this.enquiryData?.institutionLogoUrl,
            eventCalendarItemId: this.enquiryData?.eventCalendarItemId,
            studyLevelId: this.enquiryData?.studyLevelId?.toString(),
            studyLevel: this.enquiryData?.studyLevel?.toString()
          };
          this.bookedEvents = [initiallySubmittedEvent];
          if (!this.hasNoRecommendedEvents)
            this.bookedEvents = [...this.bookedEvents, ...this.enquiryDetails];

          if (this.enquiryDetails?.length) {
            this.showTimedSuccessMsg();
            this.enquiryDetails?.forEach((bookedEvent: any) => {
              const uniCourseInfo = {
                eventId: bookedEvent?.eventCalendarItemId || this.enquiryData?.eventCalendarItemId,
                idpcInstitutionId: bookedEvent?.institutionId || this.enquiryData?.institutionId,
                orderItemId: bookedEvent?.orderItemId || this.enquiryData?.orderItemId,
                subOrderItemId: bookedEvent?.subOrderItemId || this.enquiryData?.subOrderItemId,
                idpcCourseId: bookedEvent?.courseId || this.enquiryData?.courseId,
                idpcCourseName: bookedEvent?.courseName || this.enquiryData?.courseName,
                studyLevelId: bookedEvent?.studyLevelId?.toString(),
                studyLevel: bookedEvent?.studyLevel?.toString()
              }
              this.eventApiService.logInteractionClickstream(clickStreamConsts.eventType.bookEventSubmitted, "Continue", 'Open day success', uniCourseInfo, { interestedIntakeYear: this.enquiryData?.interestedIntakeYear });
            });
            this.wuniModalCtrl.emittedData.next({ modalId: MODALS.OPEN_DAY_BOOKING_SUCCESS, data: { isSubmitted: true, eventCalendarItemId: this.bookedEvents?.map((ele: any) => ele.eventCalendarItemId) } });
          }
        } else {
          this.isSubmitted = true;
        }

        if (this.enquiryType === EnquiryTypes.request_info && !data?.alreadyEnquiredFlag)
          this.clickStream$ = this.clickstreamService.enquiryFormSubmittedClickstreamLogging(clickStreamConsts.ctaTitleRequestInfo, this.clickStreamInfo);

        else if (this.enquiryType === EnquiryTypes.get_prospectus && !this.alreadyProsEnquiredFlag)
          this.clickstreamService.enquiryFormClickedClickstreamLogging(clickStreamConsts.eventType.prospectusRequested, "Confirm address", this.enquiryData);

        this.pageData = Object.assign(this.pageData, data);
        this.preferenceDetails = this.pageData.moduleComponents[0];
        data?.moduleComponents[0]?.fieldComponents?.forEach((ele: any) => {
          ele.selectValue = !!(ele?.fieldValue === 'Y' || ele?.selectValue);
        });
        const notificationPrefIndex = this.preferenceDetails?.fieldComponents?.findIndex((item) => item?.sectionName === 'pushNotification')
        this.getCheckedList('init', this.preferenceDetails?.fieldComponents[notificationPrefIndex], notificationPrefIndex);
        this.websiteUrl = data?.website;
      }
    }, () => {
      this.isLoaderEnabled = false;
    })
  }

  getNumberToText(number: number) {
    const firstTwenty = [
      "", "One", "Two", "Three",
      "Four", "Five", "Six", "Seven",
      "Eight", "Nine", "Ten", "Eleven",
      "Twelve", "Thirteen", "Fourteen", "Fifteen",
      "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    return firstTwenty[number]?.toLocaleLowerCase() || '';
  }

  submit(): void {
    const data = {
      "userData": []
    }
    this.preferenceDetails.fieldComponents.forEach(ele => {
      data.userData.push({
        'fieldName': ele.sectionName, 'fieldValue': ele.selectValue ? 'Y' : 'N'
      });
    });
    this.apiService.submitPersonal(data).subscribe((res: any) => {
      const clickstream = new ClickStream();
      const mapData = {
      };
      data?.userData?.forEach(d => {
        mapData[d.fieldName] = d.fieldValue;
      });
      clickstream.pushNotificationConsent = mapData["pushNotification"] || undefined;
      clickstream.newsletterConsent = mapData["marketingSolus"] || undefined;
      clickstream.solusConsent = mapData["marketingSolus"] || undefined;
      clickstream.surveyConsent = mapData["surveyFlag"] || undefined;
      clickstream.idpcInstitutionId = this.enquiryDetails?.institutionDetails?.institutionId || this.enquiryData?.institutionId;
      clickstream.idpcCourseId = this.enquiryDetails?.courseId || this.enquiryData?.courseId;
      clickstream.idpcCourseName = this.enquiryDetails?.courseName || this.enquiryData?.courseName;
      if (this.enquiryType === EnquiryTypes.book_event) {
        clickstream.interestedIntakeYear = this.enquiryData?.interestedIntakeYear?.toString();
        clickstream.orderItemId = this.enquiryData?.orderItemId?.toString();
        clickstream.subOrderItemId = this.enquiryData?.subOrderItemId?.toString();
        clickstream.studyLevelId = this.enquiryData?.studyLevelId?.toString();
        clickstream.studyLevel = this.enquiryData?.studyLevel?.toString();
        clickstream.isAdvertiser = "Y";
        clickstream.eventId = this.enquiryDetails?.length ? this.bookedEvents?.map((ele) => String(ele?.eventCalendarItemId))?.join(",") : String(this.enquiryData?.eventCalendarItemId);
        this.clickstreamService.onBoardingClickstreamLogging("Open Day", clickStreamConsts.functionalityName.openDay, clickStreamConsts.saveYourSettings, clickstream, clickStreamConsts.buttonClicked);
      } else
        {this.clickstreamService.onBoardingClickstreamLogging(null, clickStreamConsts.functionalityEnquiry, clickStreamConsts.saveYourSettings, clickstream, clickStreamConsts.enquiryFormButtonClicked);}
      if (res?.message?.toLowerCase()?.includes('updated'))
        if (this.enquiryType === EnquiryTypes.book_event) {
          this.wuniModalCtrl.emittedData.next({ modalId: MODALS.OPEN_DAY_BOOKING_SUCCESS, data: { isUserPreferenceSubmitted: true } })
          this.wuniModalCtrl.closeAllModals();
        }
        else
          {this.modalCtrl.dismiss({
            dismissed: true, isSubmitted: true, dismissiedFrom: 'prospectusSuccess', dismissedBy: 'Save your settings', preferenceDetails: data?.userData, ...this.enquiryData
          }, null, MODALS.ENQUIRY_SUCCESS);}
    })
  }

  modaldismiss(isSubmitted: boolean = false) {
    if (this.enquiryType === EnquiryTypes.book_event) {
      this.eventApiService.logInteractionClickstream(clickStreamConsts.eventType.closeClicked, "close", "Open Day");
      this.wuniModalCtrl.closeAllModals();
      return
    }
    this.modalCtrl.dismiss({
      dismissed: true, isSubmitted, dismissiedFrom: 'prospectusSuccess', dismissedBy: 'close', ...this.enquiryData
    }, null, MODALS.ENQUIRY_SUCCESS);
  }

  showTimedSuccessMsg() {
    this.isSubmitted = true;
    setTimeout(() => {
      this.isSubmitted = false;
    }, 15000);
  }

  getCheckedList = (action, item, i) => {
    if (item?.sectionName === 'pushNotification')
      if (action === 'init') {
        this.notification.notificationPermission().then((res) => {
          this.preferenceDetails.fieldComponents[i].selectValue = true;
          this.cd.detectChanges();
        }).catch(() => {
          if (item.selectValue) {
            this.preferenceDetails.fieldComponents[i].selectValue = false;
            this.cd.detectChanges();
          }
        });
        this.updatePreferenceHeapLabel();
      }
      else if (action === 'select')
        {this.notification.notificationPermission().then((res) => {
          if (item.selectValue) {
            this.preferenceDetails.fieldComponents[i].selectValue = true;
            this.cd.detectChanges();
          } else if (!item.selectValue)
            {this.notification.openNotificationSettings();}
        }).catch(() => {
          if (item.selectValue)
            this.notification.openNotificationSettings();
        })}
    this.disableSubmit = !this.preferenceDetails.fieldComponents.some(ele => (item.selectValue === ele.sectionName) ? !item.selectValue : ele.selectValue);
  }

  updatePreferenceHeapLabel() {
    this.preferenceHeapLabel = (this.preferenceDetails.fieldComponents[0].selectValue ? "All_push_notification" : "") +
      " | " + (this.preferenceDetails.fieldComponents[1].selectValue ? "newsletter_uniupdates" : "") +
      " | " + (this.preferenceDetails.fieldComponents[2].selectValue ? "surveys" : "");
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.sub1$.unsubscribe();
    this.submit$?.unsubscribe();
    this.clickStream$?.unsubscribe();
  }
}
