import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { environment } from '../../../environments/environment'
import { AnalyticsService } from '../../services/analytics.service';
import { ModalController } from '@ionic/angular';
import { EnquiryOnboardingComponent } from '../enquiry-onboarding/enquiry-onboarding.component';
import { Subscription } from 'rxjs';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { ClickstreamService } from '../../services/clickstream.service';
import { constants } from '../../utilities/constants/constants';
import { EnquiryData, EnquiryTypes } from '../../models/enquiry.model';
import { EnquiryModalComponent } from '../../modals/enquiry-modal/enquiry-modal.component';
import { MODALS } from '../../enums/modals.enum';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';

@Component({
  selector: 'app-sign-up-screen2',
  templateUrl: './sign-up-screen2.page.html',
  styleUrls: ['./sign-up-screen2.page.scss']
})
export class SignUpScreen2Page implements OnInit {
  @Input() enquiryType: EnquiryTypes;
  @Input() enquiryData: EnquiryData;

  content_type = 'appModuleComponent';
  apiurl = '/sign-up/password';
  pageDetails: any;
  signUpForm: UntypedFormGroup;
  isLoaderEnabled: boolean = false;
  isCurrentPwdShow: boolean = false;
  enquiryFlag: boolean = false;
  pageData: any = {
  };

  clickStreamPV$: Subscription;
  clickStreamSignUp$: Subscription;
  signUp$: Subscription;

  get formControl() { return this.signUpForm.controls; }

  constructor(private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private router: Router,
    private modalCtrl: ModalController,
    private apiService: ApiService,
    private analytics: AnalyticsService,
    private wuniModalCtrl: WuniModalControllerService,
    private clickstreamService: ClickstreamService) {
    if (!this.commonService.userDetails.userName)
      this.router.navigate(['/']);
  }

  ngOnInit() {
    this.enquiryFlag = !!this.enquiryType;
    this.pageData.heading = this.commonService.getSignInSignUpModalsHeading('signUp', this.enquiryType);
    this.getContentData();
  }

  ionViewDidEnter() {
    this.commonService.setCurrentPage(clickStreamConsts.signUpPageTwo);
    this.clickStreamPV$ = this.clickstreamService.pageViewedClickstreamLogging();
    this.analytics.uxCamScreenView(clickStreamConsts.signUpPageTwo);
  }

  getContentData() {
    const formGroup: any = {
    };
    this.signUp$ = this.apiService.getContentfulDetails(this.content_type, 2, this.apiurl, this.enquiryData).subscribe(res => {
      this.pageDetails = res;
      res.fieldComponents.forEach(ele => {
        const val = ele.fieldName === 'email' ? this.commonService.userDetails.userName : '';
        const isDisable = ele.fieldName === 'email';
        formGroup[ele.fieldName] = new UntypedFormControl({
          value: val, disabled: isDisable
        }, this.getValidators(ele.fieldName));
      });
      this.signUpForm = this.formBuilder.group(formGroup);
    })
  }

  getValidators(fieldType): Validators {
    const fieldValidators: any = []
    fieldValidators.push(Validators.required);
    if (fieldType === 'password') {
      fieldValidators.push(Validators.minLength(8));
      fieldValidators.push(this.commonService.hasLowerCase);
      fieldValidators.push(this.commonService.hasNumbers);
      fieldValidators.push(this.commonService.hasUpperCase);
      fieldValidators.push(this.commonService.hasSpecialChar);
    }
    return fieldValidators;
  }

  submit() {
    this.commonService.userOnboardingDetails.removeStoredOnboardingDetails();
    this.isLoaderEnabled = true;
    Auth.signUp({
      username: this.commonService.userDetails.userName.toLowerCase(),
      password: this.formControl.password?.value,
      //optional parameters
      attributes: {
        "custom:app_flag": "Y",
        "custom:user_source": this.getSignUpSource(this.enquiryType),
        "given_name": this.commonService.userDetails.firstName,
        "family_name": this.commonService.userDetails.lastName,
        "email": this.commonService.userDetails.userName.toLowerCase(),
        "website": environment.affiliateId.toString()
      }
    }).then(() => {
      localStorage.removeItem('onBoardingVisited');
      this.signIn(this.commonService.userDetails.userName, this.formControl.password?.value);
      this.setEmailInHeap(this.commonService.userDetails.userName);
      // Firebase log for quiz sucessful signup
      const source = this.enquiryData?.pageName.includes('quiz') ? 'career-quiz' : this.enquiryType === EnquiryTypes.book_event ? 'open_days' : localStorage.getItem('userSource') ? localStorage.getItem('userSource') : 'not-set';
      const param = { page_name: this.enquiryData?.pageName, source, method: 'sign-up' }
      this.analytics.logEvent(constants.firebaseEvents.signup_submit, param);
    }).catch(err => {
      console.log("Auth signUp error msg:", err);
      this.isLoaderEnabled = false;
    })
  }

  setEmailInHeap(emailAddress: any) {
    (window as any).heap.identify(emailAddress);
    (window as any).heap.addUserProperties({ "email": emailAddress });
  }

  getSignUpSource(eqType: EnquiryTypes): string {
    let userSource = "registration";
    switch (eqType) {
      case EnquiryTypes.career_matches:
        userSource = "career_matcher";
        break;
      case EnquiryTypes.request_info:
        userSource = "request-info";
        break;
      case EnquiryTypes.get_prospectus:
        userSource = "request_prospectus";
        break;
      case EnquiryTypes.book_event:
        userSource = "book_event";
        break;
      case EnquiryTypes.final_5:
        userSource = "final_choice";
        break;
      default:
        userSource = "registration"
        break;
    }
    return userSource;
  }

  signIn(username: any, password: any) {
    Auth.signIn(username.toLowerCase(), password).then(res => {
      if (res.username)
        this.getUserInfo(res.username)
    }).catch(error => {
      this.isLoaderEnabled = false;
      console.log(error, 'error');
      throw error;
    });
  }

  getUserInfo(userSubId: any) {
    const username = this.commonService.userDetails.userName;
    localStorage.setItem('userid', 'true');
    this.apiService.getUserid(username.toLowerCase(), userSubId).subscribe(res => {
      this.isLoaderEnabled = false;
      if (this.enquiryType === EnquiryTypes.favourite_uni || this.enquiryType === EnquiryTypes.favourite_course || this.enquiryType === EnquiryTypes.guest_login || this.enquiryType === EnquiryTypes.career_matches || this.enquiryType === EnquiryTypes.final_5)
        this.modaldismiss(true);
      else if (this.enquiryType === EnquiryTypes.book_event)
        this.modalCtrl.dismiss({
          dismissed: true
        }, null, MODALS.SIGN_UP_2);

      else if (this.enquiryFlag)
        this.enquiryFormModal();
      else if (this.commonService.isOnBoardingVisited())
        this.router.navigateByUrl('/tab/home');
      else
        this.router.navigate(['/on-boarding']);
    })
    this.clickStreamSignUp$ = this.clickstreamService.signUpClickstreamLogging();
  }

  async enquiryFormModal() {
    const param = {
      coupon: 'interaction_request_email', location: this.enquiryData.institutionName, page_name: this.enquiryData.pageName, item_name: this.enquiryData.subject
    }
    this.analytics.logEvent(constants.firebaseEvents.ecommerce_purchase, param);
    if (this.enquiryType === EnquiryTypes.book_event) {
      this.wuniModalCtrl.openOpenDayBookingForm(this.enquiryData);
      return;
    }
    const modal = await this.modalCtrl.create({
      component: this.enquiryType === EnquiryTypes.get_prospectus ? EnquiryModalComponent : EnquiryOnboardingComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ENQUIRY_YOE,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData: this.enquiryData,
        previousModalId: MODALS.SIGN_UP_2
      }
    })
    modal.onWillDismiss().then(res => {
      this.isLoaderEnabled = false;
      if (res?.data?.dismissed)
        this.modaldismiss(res?.data?.dismissed, res?.data?.isSubmitted);
    })
    await modal.present();
  }

  modaldismiss(dismissed?: any, isSubmitted?: any) {
    this.modalCtrl.dismiss({
      dismissed, isSubmitted
    }, null, MODALS.SIGN_UP_2);
  }

  ionViewWillLeave() {
    this.clickStreamPV$?.unsubscribe();
    this.clickStreamSignUp$?.unsubscribe();
    this.signUp$?.unsubscribe();
  }
}
