import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class WelcomeGuard implements CanLoad {
  constructor(private router: Router) { }

  canLoad(route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userId: boolean = !!localStorage.getItem('userid');
    if (userId && route.path === 'welcome')
      return this.router.parseUrl('/tab/home');
    return true;
  }
}
