import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { Filter } from '../models/filter.model';
import { ModalController } from '@ionic/angular';
import { AddedYourFavouritesComponent } from '../common/added-your-favourites/added-your-favourites.component';
import { Router, NavigationEnd } from '@angular/router';
import { UserOnboardingDetails } from '../models/user-onboarding-details'
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { EnquiryData, EnquiryTypes, ModalContents } from '../models/enquiry.model';
import { MODALS } from '../enums/modals.enum';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { clickStreamConsts } from '../utilities/constants/click-stream-const';
import { AddressComponent } from 'user-profile';
import { EnquirySuccessModalComponent } from '../modals/enquiry-success-modal/enquiry-success-modal.component';
import { FavouritesLimitExceededComponent } from '../components/favourites-limit-exceeded/favourites-limit-exceeded.component';
import { FavouriteUniCourses } from '../pages/favourites/models/favourites.model';
@Injectable({
  providedIn: 'root'
})

export class CommonService {
  userDetails = {
    'userName': '',
    'Password': '',
    'firstName': '',
    'lastName': ''
  }

  yourGrade: any;

  public filtersValues = new Filter();
  public pRFilterValues = new Filter();
  public filterSubject = new Subject<any>();
  public favFlagSubject = new Subject<any>();
  public favGetProspectusListner = new Subject<any>();
  public universityInfo: any;
  currentPageName: string = "";
  previousPageName: string = "";
  disableRequestInfoLister = new Subject<any>();

  isFilterButtonVisible: boolean = false;
  userOnboardingDetails = new UserOnboardingDetails();
  private previousUrl: string;
  private currentUrl: string;

  constructor(private modalCtrl: ModalController,
    private router: Router,
    private launchReview: LaunchReview,
    public nativeStorage: NativeStorage) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  setCurrentPage(pageName: string) {
    if (localStorage.getItem('currentPageName') && localStorage.getItem('currentPageName') === pageName && pageName !== clickStreamConsts.jobRolePage)
      return;
    localStorage.setItem('previousPageName', localStorage.getItem('currentPageName') || '');
    localStorage.setItem('currentPageName', pageName);
    this.previousPageName = localStorage.getItem('previousPageName');
    this.currentPageName = localStorage.getItem('currentPageName');
  }
  public getPreviousUrl() {
    return this.previousUrl;
  }

  getModalsContent(enquiryType): ModalContents {
    let modalContent: ModalContents;
    switch (enquiryType) {
      case EnquiryTypes.guest_login:
        modalContent.title = 'Welcome to Whatuni';
        break;
      case EnquiryTypes.request_info:
        modalContent.heading = 'Request info';
        break;
      case EnquiryTypes.get_prospectus:
        modalContent.heading = 'Get prospectus';
        break;
      default:
        modalContent.heading = 'Subscribe';
        break;
    }
    return modalContent;
  }

  getSignInSignUpModalsHeading(flow: 'signIn' | 'signUp', enquiryType: EnquiryTypes): string {
    let heading: string;
    switch (enquiryType) {
      case EnquiryTypes.guest_login:
        heading = (flow === 'signIn') ? 'Sign in' : 'Sign up';
        break;
      case EnquiryTypes.favourite:
        heading = (flow === 'signIn') ? 'Sign in' : 'Sign up';
        break;
      case EnquiryTypes.favourite_course:
        heading = (flow === 'signIn') ? 'Sign in' : 'Sign up';
        break;
      case EnquiryTypes.favourite_uni:
        heading = (flow === 'signIn') ? 'Sign in' : 'Sign up';
        break;
      case EnquiryTypes.career_matches:
        heading = (flow === 'signIn') ? 'Sign in' : 'Sign up';
        break;
      case EnquiryTypes.request_info:
        heading = 'Request info';
        break;
      case EnquiryTypes.get_prospectus:
        heading = 'Get prospectus';
        break;
      case EnquiryTypes.book_event:
        heading = "Book open day";
        break;
      case EnquiryTypes.final_5:
        heading = (flow === 'signIn') ? 'Sign in' : 'Sign up';
        break;
      default:
        heading = 'Subscribe';
        break;
    }
    return heading;
  }

  emailValidation(object: AbstractControl): { [key: string]: boolean } {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex = new RegExp(pattern);
    if (regex.test(object.value))
      return {
      };

    return {
      emailerror: true
    };
  }

  nameValidation(object: AbstractControl): { [key: string]: boolean } {
    const pattern = /^[ a-zA-Z\u00C0-\u024F-']*$/;
    const regex = new RegExp(pattern)
    if (regex.test(object.value))
      return {
      };

    return {
      nameerror: true
    };
  }

  hasLetter(object: AbstractControl): { [key: string]: boolean } {
    const pattern = /[A-Za-z]/g;
    const regex = new RegExp(pattern);
    if (regex.test(object.value))
      return {
      };

    return {
      LetterError: true
    };
  }

  hasNumbers(object: AbstractControl): { [key: string]: boolean } {
    const pattern = /\d/g;
    const regex = new RegExp(pattern);
    if (regex.test(object.value))
      return {
      };

    return {
      numberError: true
    };
  }

  hasUpperCase(object: AbstractControl): { [key: string]: boolean } {
    const pattern = /[A-Z]/g;
    const regex = new RegExp(pattern);
    if (regex.test(object.value))
      return {
      };

    return {
      upperCaseError: true
    };
  }

  hasLowerCase(object: AbstractControl): { [key: string]: boolean } {
    const pattern = /[a-z]/g;
    const regex = new RegExp(pattern);
    if (regex.test(object.value))
      return {
      };

    return {
      lowerCaseError: true
    };
  }

  hasSpecialChar(object: AbstractControl): { [key: string]: boolean } {
    const pattern = /[-!@#$%^&*()_+|~=`{}\[\]:";\\'<>?,.\/]/g;
    const emailRegexp = new RegExp(pattern);
    if ((emailRegexp.test(object.value)))
      return {
      };

    return {
      specialCharError: true
    };
  }

  isOnBoardingVisited() {
    return !!localStorage.getItem('onBoardingVisited');
  }

  ordinal_suffix_of(i: any) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11)
      return `${String(i)}st`;
    if (j === 2 && k !== 12)
      return `${String(i)}nd`;
    if (j === 3 && k !== 13)
      return `${String(i)}rd`;
    return `${String(i)}th`;
  }

  ucasPoint(min: number, max: number) {
    if (min === max)
      return max;
    else if (!min || !max)
      return min || max
    else if (min && max)
      return min.toString() + ' - ' + max.toString();
    else
      return '';
  }

  async openAddedFavModal(type: any, modalType: string, enquiryData?: EnquiryData, fromModalId?: string) {
    const modal = await this.modalCtrl.create({
      component: AddedYourFavouritesComponent,
      cssClass: 'modal-sm ' + (modalType === 'favourite_success' ? 'sr_noreqinfo_modal' : 'fav_noreqinfo_modal'),
      mode: 'ios',
      id: MODALS.FAVOURITE,
      initialBreakpoint: 0.6,
      breakpoints: [0, 0.6],
      componentProps: {
        type,
        modalType,
        enquiryData
      }
    })
    modal.onWillDismiss().then(res => {
      if (res?.data?.isProspectusSubmitted)
        this.favGetProspectusListner.next(res?.data);

      if (fromModalId === MODALS.COURSE_SEARCH && res?.data?.dismissed) {
        console.log('close')
        this.modalCtrl.dismiss({
          'dismissed': res?.data?.dismissed
        }, null, MODALS.COURSE_SEARCH);
      } else {
        this.modalCtrl.dismiss();
      }
      if (res?.data?.dismissed && res.data?.dismissiedFrom !== 'prospectusSuccess')
        this.router.navigate(['/tab/shortlist'], {
          queryParams: {
            type
          }
        });
    })
    return await modal.present();
  }

  async openFavPopupModal(favourite: FavouriteUniCourses) {
    const modal = await this.modalCtrl.create({
      component: FavouritesLimitExceededComponent,
      cssClass: 'my-sheet-modal_h404',
      mode: 'ios',
      id: MODALS.FAVOURITES_LIMIT_EECEEDS,
      initialBreakpoint: 0.7,
      breakpoints: [0, 0.7],
      componentProps: {
        thirtyFirstFavouriteObj: favourite
      }
    })
    return await modal.present();
  }

  appRate() {
    /*************** Hidden for this sprint *****************/
    const reviewcount = localStorage.getItem('reivewCount') ? JSON.parse(localStorage.getItem('reivewCount')) : '';
    const loggedin = localStorage.getItem('userid') ? localStorage.getItem('userid') : ''
    if (reviewcount.count > 3 && loggedin)
      if (this.launchReview.isRatingSupported()) {
        console.log('open ratign popyp')
        this.launchReview.rating()
          .subscribe((status) => {
            console.log('statusdialog', status);
            if (status === 'dismissed')
              console.log('dismissed');
          }, err => {
            console.log(err);
            throwError(err);
          })
      }
  }

  getParamsFromString(url: string): any {
    if (url?.split('?')[1]) {
      const obj = {
      };
      const queryParamsStr = url?.split('?')[1];
      for (const param of queryParamsStr.split('&'))
        obj[param.split('=')[0]] = param.split('=')[1];

      return obj;
    }
  }

  delayedFocusOf(id: string, delayTime = 300) {
    setTimeout(() => {
      const element = document.querySelector('[id=' + id + ']');
      console.log(element, 'element');
      element?.scrollIntoView({
        block: "center"
      });
    }, delayTime);
  }

  emitRequestInfoDisableLister(data: any) {
    this.disableRequestInfoLister.next(data);
  }

  async openAddressModal() {
    const addressModal = await this.modalCtrl.create({
      component: AddressComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ADDRESS,
      mode: 'ios',
      animated: true
    })
    await addressModal.present();
    await addressModal.onWillDismiss().then(res => {
      if (res?.data?.dismissed)
        this.modalCtrl.dismiss({
          dismissed: res?.data?.dismissed, isSubmitted: res?.data?.isSubmitted
        });
      else if (res?.data)
        this.openEnquirySuccessModal(res?.data);
    })
  }

  async openEnquirySuccessModal(addressData?: any) {
    const modal = await this.modalCtrl.create({
      component: EnquirySuccessModalComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ENQUIRY_SUCCESS

    })
    await modal.present();
    await modal.onWillDismiss().then(res => {
      if (res?.data?.dismissed)
        this.modalCtrl.dismiss(res?.data, null, MODALS.ENQUIRY_YOE);
    })
  }
}
