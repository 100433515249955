import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonContent, ModalController, IonInfiniteScroll } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Filter } from '../../models/filter.model';
import { ApiService } from '../../services/api.service';
import { ClickstreamService } from '../../services/clickstream.service';
import { CommonService } from '../../services/common.service';
import { WelcomePage } from '../../pages/welcome/welcome.page';
import { SearhTab } from '../../models/search-tab.enum';
import { InfiniteScrollCustomEvent } from '../../models/infinte-scroll';
import { FilterComponent } from '../filter/filter.component';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, map, tap, switchMap } from 'rxjs/operators';
import { AnalyticsService } from '../../services/analytics.service';
import { AcousticService } from '../../utilities/common/acoustic.service';
import { EnquiryData, EnquiryTypes } from '../../models/enquiry.model';
import { MODALS } from '../../enums/modals.enum';
import { SocialLoginState } from '../../models/social-login-state';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';
import { FavouriteUniCourses } from 'src/app/pages/favourites/models/favourites.model';
import { AddFavoriteRequestList } from 'src/app/models/favourite-page-data';

@Component({
  selector: 'app-course-search',
  templateUrl: './course-search.component.html',
  styleUrls: ['./course-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CourseSearchComponent implements OnInit, OnDestroy {
  @Input() institutionId: any;
  @Input() totalCourseCount: number = 0;
  @Input() categoryData: any;
  @Input() subject: any;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) content: IonContent;
  @Input() isFavouritesPage: boolean;
  @Input() favCourseList: any[] = [];
  @Input() institutionName: string;
  limitExceedFavPopUp$: Subscription;

  appliedFilters = new Filter();
  currentPageNo$ = new BehaviorSubject<any>({
    page: 1, searchapplied: false, filterapplied: false
  });
  clickstreamSL$: Subscription;
  totalPages: number = 0;
  courseLists: any[] = [];
  isOverlayEnabled: boolean = false;
  currentInfiniteEvent: InfiniteScrollCustomEvent;
  searchStart: boolean = false;
  isNoResult: boolean;
  topSearchResult: any[] = [];
  initialFocus: boolean = false;
  searchCourses: any = [];
  inputPayload: any;
  searchText = new UntypedFormControl('');
  catCode: any;
  catid: any;
  appliedFiltercount: any;
  currentPageData$: Observable<any>;
  courseListData: any;
  totalcourses: number = 0;
  showFilter: boolean = false;
  isSkeleton: boolean = false;
  isInitialPage = true;
  filterCount: number = 0;
  searchCount: number = 0;
  pageNoArr = [];
  wuniModalCtrl$: Subscription;

  constructor(private modalCtrl: ModalController,
    private router: Router,
    private apiService: ApiService,
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private clickstreamService: ClickstreamService,
    private analytics: AnalyticsService,
    private acservice: AcousticService,
    private wuniModalCtrl: WuniModalControllerService) {
  }

  ngOnInit() {
    this.totalcourses = this.totalCourseCount;
    this.totalPages = this.totalCourseCount < 10 ? this.totalCourseCount : Math.ceil(this.totalCourseCount / 10);
    this.keyboardFilterResult();
    this.searchText.valueChanges.subscribe(val => {
      this.searchStart = val.length >= 1;
    })
    this.getData();
    this.inputPayload = {
      page: 1,
      institutionId: this.institutionId,
      ...this.categoryData
    }
    if (SocialLoginState.getSocialLoginTriggeredPoint?.triggeredBy === EnquiryTypes.favourite) {
      if (SocialLoginState.getSocialLoginTriggeredPoint?.data?.filteredData)
        this.commonService.pRFilterValues = SocialLoginState.getSocialLoginTriggeredPoint?.data?.filteredData;
      if (SocialLoginState.getSocialLoginTriggeredPoint?.data?.pageNo)
        for (let i = 1; i <= Number(SocialLoginState.getSocialLoginTriggeredPoint?.data?.pageNo); i++)
          this.pageNoArr.push(i);
    }
  }

  modalDismiss() {
    this.modalCtrl.dismiss();
  }

  getData() {
    this.currentPageData$ = this.currentPageNo$.pipe(
      switchMap((pageNo) => {
        if (this.pageNoArr?.length) {
          this.inputPayload.page = this.pageNoArr;
          this.currentPageNo$.value.page = this.pageNoArr[this.pageNoArr?.length - 1]
        } else {
          this.inputPayload.page = pageNo.page;
        }
        return this.apiService.getInstitutionAllCourses(this.inputPayload).pipe(map((ele) => {
          if (this.pageNoArr?.length)
            this.pageNoArr = [];
          this.appliedFiltercount = Object.keys(ele.filterBy).filter(x => ele.filterBy[x] && ele.filterBy[x] !== '0' && x !== 'sortBy').length;
          this.appliedFiltercount = this.appliedFiltercount > 0 ? this.appliedFiltercount : '';
          this.showFilter = true;
          ele?.providerResults?.forEach((childEle: any) => {
            childEle.ucasPoint = this.commonService.ucasPoint(childEle.minPoint, childEle.maxPoint)
          });

          if (this.currentPageNo$.value.page === 1)
            this.courseLists = [];

          this.totalCourseCount = this.searchCount || this.filterCount || this.totalcourses;
          this.totalPages = this.totalCourseCount < 10 ? this.totalCourseCount : Math.ceil(this.totalCourseCount / 10);
          this.courseLists = [...this.courseLists, ...ele.providerResults];
          ele.providerResults = this.courseLists;

          if (this.currentPageNo$.value.page === this.totalPages && this.currentInfiniteEvent)
            this.currentInfiniteEvent.target.disabled = true;

          this.courseListData = ele?.providerResults;
          return ele;
        }))
      }), tap(() => {
        this.resumeSocialLoginState();
        if (this.currentInfiniteEvent) {
          this.currentInfiniteEvent.target.complete();
          this.currentInfiniteEvent = null;
        }
      })
    )
  }

  resumeSocialLoginState() {
    if (SocialLoginState.getSocialLoginTriggeredPoint?.triggeredBy === EnquiryTypes.favourite) {
      const course = this.courseListData.filter((course: any) => course?.courseId === SocialLoginState.getSocialLoginTriggeredPoint.courseId)[0];
      if (course) {
        this.commonService.delayedFocusOf('cors-' + course?.courseId);
        this.shortList(course);
      }
    }
  }

  loadData(event: any) {
    this.currentInfiniteEvent = event;
    if (this.currentPageNo$.value.page === this.totalPages && this.currentInfiniteEvent) {
      this.currentInfiniteEvent.target.disabled = true;
      return
    }

    if (this.currentPageNo$.value.filterapplied)
      this.currentPageNo$.next({
        page: this.currentPageNo$.value.page + 1, searchapplied: false, filterapplied: true
      });
    else if (this.currentPageNo$.value.searchapplied)
      this.currentPageNo$.next({
        page: this.currentPageNo$.value.page + 1, searchapplied: true, filterapplied: false
      });
    else
      this.currentPageNo$.next({
        page: this.currentPageNo$.value.page + 1, searchapplied: false, filterapplied: false
      });
  }

  navigateToCDPage(course: any) {
    if (this.isFavouritesPage) {
      this.modalCtrl.dismiss(course);
    }
    else {
      this.modalDismiss();
      this.router.navigate(['/course-details', course.institutionId, course.courseId]);
    }
  }

  trackByFn(index: any, element: any) {
    return element?.courseId;
  }

  trackByFnTopResult(index, item) {
    return item.optionId
  }

  shortList(item: any) {
    if (localStorage.getItem('userid'))
      this.updateShortList(item);
    else
      this.enquiryFormModal(item);
  }

  updateShortList(item: any) {
    item.favoriteFlag = !item.favoriteFlag;
    this.isOverlayEnabled = true;
    const addFavoriteRequestList: AddFavoriteRequestList[] = [];
    addFavoriteRequestList.push({
      contentType: "COURSE",
      contentId: item.courseId,
      contentName: item.courseTitle,
      inputFlag: item.favoriteFlag
    });
    const action = !item.favoriteFlag ? 'unfavourite' : undefined;
    this.apiService.saveToFavourites(addFavoriteRequestList, action).subscribe(res => {
      if (res?.message === 'Limit exceeded') {
        item.favoriteFlag = false;
        const favouriteUniCourses: FavouriteUniCourses = {
          institutionId: Number(item.institutionId),
          institutionName: item?.institutionName,
          courseId: item.courseId,
          courseName: item.courseTitle
        }
        this.commonService.openFavPopupModal(favouriteUniCourses);
        this.limitExceedFavPopUp$?.unsubscribe();
        this.limitExceedFavPopUp$ = this.wuniModalCtrl.emittedData.subscribe((res) => {
          if (res?.modalId === MODALS.FAVOURITES_LIMIT_EECEEDS)
            this.modalCtrl.dismiss(null, null, MODALS.COURSE_SEARCH);
        });
      } else if ((res?.message?.toLowerCase()?.includes('added') || res?.message === 'Already exists') && item.favoriteFlag) {
        const enquiryData: EnquiryData = {
          "institutionId": item?.institutionId, "courseId": item?.courseId, "institutionName": item.institutionName, "pageName": 'institution-profile', "subject": 'not-set'
        }
        this.commonService.openAddedFavModal(SearhTab.Course, 'favourite_success', enquiryData, MODALS.COURSE_SEARCH);
        this.clickstreamSL$ = this.clickstreamService.shortListClickstreamLogging(null, null, item);
        this.apiService.getFavCount().subscribe(cnt => {
          if (cnt.count >= 2)
            this.commonService.appRate();
        })
        this.acservice.addEvent('courseAddedFavourite', item.courseTitle, item.institutionName, item.institutionName, res.count?.toString());
      } else if (res?.finalChoices) {
        this.wuniModalCtrl$ = this.wuniModalCtrl.emittedData.subscribe((res) => {
          if (res?.modalId === MODALS.FINAL5_REMOVE && !res?.data) {
            item.favoriteFlag = true;
            this.wuniModalCtrl$?.unsubscribe();
            this.cd.detectChanges();
          }
        });
        this.openRemoveFinal5Model(res?.finalChoices, addFavoriteRequestList);
      } else {
        item.favoriteFlag = false;
      }
      this.isOverlayEnabled = false;
      this.cd.markForCheck();
      this.analytics.logEvent('add_to_wishlist', {
        item_name: this.subject, item_category: item.institutionName
      })
    }, err => {
      console.log("Error while add into shortlist", err);
      item.favoriteFlag = false;
      this.isOverlayEnabled = false;
      this.cd.markForCheck();
    })
  }

  openRemoveFinal5Model(data: any, courseItems: any) {
    const items = [{ ...data[0], ...courseItems[0] }];
    this.wuniModalCtrl.openModal(MODALS.FINAL5_REMOVE, null, items);
  }

  async enquiryFormModal(item: any) {
    const modal = await this.modalCtrl.create({
      component: WelcomePage,
      cssClass: 'my-modal-styles',
      id: MODALS.WELCOME,
      componentProps: {
        enquiryType: 'favourite_course',
        enquiryData: {
          'institutionId': item?.institutionId,
          'courseId': item?.courseId,
          "pageName": this.categoryData ? 'jobe-role' : 'university-profile',
          "pageUrl": this.categoryData ? ('careers' + '/' + this.categoryData?.sectionInfo?.jobRole?.subCategorySlugUrl + '/' + this.categoryData?.sectionInfo?.jobRole?.jobSlugUrl) : ('/university-profile/' + item?.institutionId)
        },
        otherData: {
          filteredData: this.commonService?.pRFilterValues,
          pageNo: this.currentPageNo$.value.page,
          categoryData: this.categoryData,
          ...this.categoryData?.sectionInfo
        }
      }
    })
    modal.onWillDismiss().then(res => {
      this.modalCtrl.dismiss();
      if (res?.data?.dismissed)
        this.updateShortList(item);
    })
    await modal.present();
  }

  async openFilterModal() {
    const modal = await this.modalCtrl.create({
      component: FilterComponent,
      cssClass: 'my-modal-styles',
      componentProps: {
        searchValues: {
          "catCode": this.catCode,
          "course": this.catid,
          "courseId": '',
          "searchType": 'COURSE',
          "instid": this.institutionId
        },
        isPRModal: true
      }
    });
    modal.onWillDismiss().then(res => {
      if (res?.data) {
        this.appliedFilters = res?.data || this.appliedFilters;
        this.inputPayload = {
          page: 1,
          institutionId: this.institutionId
        }
        this.content?.scrollToPoint(0, 0, 600);
        this.totalCourseCount = res?.data.course;
        this.filterCount = res?.data.course;
        this.cd.markForCheck();
        this.inputPayload = {
          institutionId: this.institutionId,
          catcode: this.catCode,
          catid: this.catid
        }
        this.isInitialPage = true;
        this.currentPageNo$.next({
          page: 1, searchapplied: false, filterapplied: true
        });
      }
    })
    await modal.present();
  }

  courseEvent(value) {
    const instdata = {
      instid: this.institutionId
    }
    const key = value?.trim();
    if (key && key?.trim().length > 2)
      this.apiService.getSearchResult(value, instdata).subscribe(res => {
        this.topSearchResult = res?.subjects;
      })
  }

  keyboardFilterResult() {
    const instdata = {
      instid: this.institutionId
    }
    this.searchText.valueChanges
      .pipe(
        map((value: string) => { value?.length <= 2 ? this.clearResult() : this.isSkeleton = true; this.isNoResult = false; return value?.trim() }),
        filter((value: string) => value?.length > 2),
        debounceTime(400),
        switchMap((value: string) =>
          this.apiService.getSearchResult(value, instdata)
        )
      ).subscribe(res => {
        if (res?.subjects && this.searchText.value?.length > 1) {
          this.topSearchResult = res?.subjects;
          this.isSkeleton = false;
          this.isNoResult = false;
        } else {
          if (res.subjects === null) {
            this.isNoResult = true;
            this.topSearchResult = [];
          }
        }
        this.cd.markForCheck();
      })
  }

  gotoSearchResults(option) {
    this.searchText?.setValue(option.description);
    this.inputPayload = {
      institutionId: this.institutionId,
      catcode: option.catCode,
      catid: option.optionId,
      qualcode: option.qualification,
      sortby: 'AZ'
    }
    this.searchCourses = [];
    this.isInitialPage = true;
    this.searchCount = option.courseCount;
    this.searchStart = false;
    this.catCode = option.catCode;
    this.catid = option.catid;
    this.currentPageNo$.next({
      page: 1, searchapplied: true, filterapplied: false
    });
  }

  clearResult() {
    this.topSearchResult = [];
    this.searchCourses = [];
    this.searchStart = false;
    this.isNoResult = true;
    this.inputPayload = {
      page: 1,
      institutionId: this.institutionId
    }

    this.searchCount = 0;
    if (this.infiniteScroll)
      this.infiniteScroll.disabled = false;

    if (this.catCode) {
      this.isInitialPage = true;
      this.currentPageNo$.next({
        page: 1, searchapplied: true, filterapplied: true
      });
    }
    this.catCode = '';
  }

  cancelSearch() {
    this.initialFocus = false;
    this.searchText.setValue("");
  }

  ngOnDestroy(): void {
    if (this.infiniteScroll)
      this.infiniteScroll.disabled = true;
    this.limitExceedFavPopUp$?.unsubscribe();
  }

  ionViewDidLeave() {
    this.commonService.pRFilterValues = new Filter();
  }
}
