import { Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Router } from '@angular/router';
import { User } from '../models/user';
import { Auth, Hub } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})

export class SocialLoginService {
  userObj: User = new User();

  constructor(private router: Router) { }

  socialLogin(provider: CognitoHostedUIIdentityProvider, redirecturl: string) {
    Auth.federatedSignIn({
      provider, customState: redirecturl
    }).then(async res => {
      console.log(res, 'Auth.federatedSignIn');
    }).catch((err) => {
      console.log(err, 'error')
    })
  }

  setTokenForSocialLogin() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // console.log("Hub.listen event", event, data, JSON.stringify(data));
      this.PageRedirection(data);
      switch (event) {
        case "signIn":
          this.signIn();
          break;
        case "signOut":
          break;
        case "Welcome":
          this.router.navigateByUrl('/tab/search');
          break;
        case "cognitoHostedUI":
          break;
        case "customOAuthState":
          break;
      }
    });
  }

  signIn() {
    localStorage.setItem('userid', 'true');
  }

  PageRedirection(data) {
    switch (data) {
      case '"Welcome"':
        setTimeout(() => {
          // this.router.navigate(['/tab/search']);
          if (localStorage.getItem('redirection'))
            document.location.href = localStorage.getItem('redirection');

          //window.location.href='/tab/search/search-results/8219?type=SUBJECT&courseId=8219&course=Business&catCode=AA.3&qualification=M';
        }, 2000);
        // this.router.parseUrl('/tab/home');
        //this.router.navigate(['/tab/search']);
        // window.HTMLIonRefresherContentElement()
        break;
    }
  }

  isBlankOrNull(value) {
    if (value === null || value === 'null' || value === '' || value === 'undefined' ||
      value === undefined || (typeof (value) === 'object' && (Object.keys(value).length === 0 || value.length === 0)))
      return true;
    return false;
  }
}
