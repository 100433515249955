import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription, forkJoin, of } from 'rxjs';
import { MODALS } from '../../enums/modals.enum';
import { EnquiryData, EnquiryType, EnquiryTypes } from '../../models/enquiry.model';
import { SocialLoginState } from '../../models/social-login-state';
import { AnalyticsService } from '../../services/analytics.service';
import { ApiService } from '../../services/api.service';
import { ClickstreamService } from '../../services/clickstream.service';
import { Inappbrowser } from '../../utilities/common/inappbrowser';
import { constants } from '../../utilities/constants/constants';
import { AddressComponent } from 'user-profile';
import { EnquirySuccessModalComponent } from '../enquiry-success-modal/enquiry-success-modal.component';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';
import { EventApiService } from '../../services/eventApi.service';
import { fadeInAnimation } from '../../animations/success-msg.animation';
import { clickStreamConsts } from 'src/app/utilities/constants/click-stream-const';

@Component({
  selector: 'app-enquiry-modal',
  templateUrl: './enquiry-modal.component.html',
  styleUrls: ['./enquiry-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation]
})

export class EnquiryModalComponent implements OnInit, OnDestroy {
  @Input() enquiryData: EnquiryData;
  @Input() enquiryType: EnquiryType;
  @Input() previousModalId: MODALS;

  modalContents = {
    heading: "Get prospectus",
    sectionTitle: "Get a prospectus in the post",
    description: "Receive tailored newsletters from"
  }
  enquiryTypes = EnquiryTypes;
  selectedYear: string;
  consentFlag: boolean = false;
  isLoaderEnabled: boolean = false;
  textArea: any;
  isTextAreaValueadded: boolean = false;
  submitFlag: boolean = false;
  cntFormGrp: UntypedFormGroup;
  question: any;
  enquiryDetails: any;
  contentType = 'appModuleComponent';
  apiUrl: string = '/yoe';
  institutionInfo: any;
  institutionName: string;
  bookEventDetails: any = {};
  privacyNoticeUrl = "https://www.whatuni.com/wu-cont/privacy.htm?skipcookieconsent=true";

  onboardingYOE$: Subscription;
  enquiry$: Subscription;

  constructor(private apiService: ApiService,
    private cdref: ChangeDetectorRef,
    private iab: Inappbrowser,
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    private analytics: AnalyticsService,
    private clickStreamService: ClickstreamService,
    private eventApiService: EventApiService,
    private wuniModalCtrl: WuniModalControllerService) {
    this.cntFormGrp = this.formBuilder.group({
      userContent: ['', [Validators.required, Validators.minLength(50)]]
    });
  }

  ngOnInit() {
    this.getOnBoardingYOE();
    this.getAdditionalEnquiryDetails();
    if (this.previousModalId === MODALS.SIGN_IN || this.previousModalId === MODALS.SIGN_UP_2) {
      this.modalContents.sectionTitle = null;
      this.modalContents.description = null;
    } else if (this.enquiryType === EnquiryTypes.book_event) {
      this.modalContents.heading = 'Book open day'
    } else {
      this.modalContents.description = this.modalContents.description + " " + this.enquiryData?.institutionName;
    }
    SocialLoginState.clearIfTriggeredByis(this.enquiryType);
  }

  getOnBoardingYOE(): void {
    this.apiUrl = (this.enquiryType?.includes('request_info') || this.enquiryType === EnquiryTypes.get_prospectus || this.enquiryType === EnquiryTypes.book_event) ? '/interactions/yoe' : '/yoe';
    this.onboardingYOE$ = this.apiService.getOnBoardingContentful(this.contentType, this.apiUrl, this.enquiryData).subscribe(data => {
      this.question = data;
      this.institutionInfo = data?.enquiryInfo;
      this.bookEventDetails = data?.eventdetails;
      if(!this.enquiryData.eventDate || !this.enquiryData.eventLocation) {
        this.enquiryData.eventDate = data?.eventdetails?.eventDate;
        this.enquiryData.eventLocation = data?.eventdetails?.eventLocation;
      }
      this.cdref.detectChanges();
    });
  }

  getAdditionalEnquiryDetails = () => {
    this.enquiry$ = this.apiService.getEnquiryDetails(this.enquiryData).subscribe(res => {
      this.enquiryDetails = res?.enquiryDetails;
      this.institutionName = this.enquiryDetails?.institutionDetails?.institutionName;
      if (!this.enquiryData.keyProfileId || !this.enquiryData.studyLevelId || this.enquiryData.orderItemId) {
        this.enquiryData.keyProfileId = this.enquiryDetails?.institutionDetails?.keyProfileId;
        this.enquiryData.hcCollegeId = this.enquiryDetails?.institutionDetails?.collegeId;
        this.enquiryData.orderItemId = this.enquiryDetails?.institutionDetails?.orderItemId;
        this.enquiryData.subOrderItemId = this.enquiryDetails?.institutionDetails?.subOrderItemId;
        this.enquiryData.providerEmail = this.enquiryDetails?.providerEmail;
        this.enquiryData.studyLevel = this.enquiryDetails?.clickStreamData?.studyLevel;
        this.enquiryData.studyLevelId = this.enquiryDetails?.clickStreamData?.studyLevelId;
        this.enquiryData.courseName = this.enquiryDetails?.courseName;
        this.enquiryData.institutionName = this.enquiryDetails?.institutionDetails?.institutionName;
      }
      this.cdref.detectChanges();
    });
  }

  onYearSelect(year: any): void {
    this.selectedYear = year;
  }

  continueClicks() {
    this.isLoaderEnabled = true;
    this.submitEnquiry();
    this.cdref.detectChanges();
  }

  openInappBrowser(url: string) {
    this.iab.openBrowser(url);
  }

  onBlur() {
    if (!this.isTextAreaValueadded) {
      this.isTextAreaValueadded = true;
      if (!this.cntFormGrp.get('userContent')?.value?.trim()?.length)
        this.cntFormGrp.setValue({
          userContent: this.textArea
        })
    }
    this.submitFlag = this.cntFormGrp.invalid;
  }

  getCachedRecommendedEvents() {
    if (this.enquiryType === EnquiryTypes.book_event)
      return this.eventApiService.getCachedRecommendedEvents(this.enquiryData?.eventCalendarItemId)
    else
      return of(null);
  }

  submitEnquiry() {
    if (this.enquiryType !== EnquiryTypes.get_prospectus) {
      const param = {
        coupon: 'interaction_submit_email', location: this.enquiryData.institutionName, page_name: this.enquiryData.pageName, item_name: this.enquiryData.subject
      }
      this.analytics.logEvent(constants.firebaseEvents.ecommerce_purchase, param);
    }
    if (!this.selectedYear || !this.consentFlag)
      return
    this.isLoaderEnabled = true;
    const submitData: any = {
      fieldName: this.question.componentTitle,
      fieldValue: this.selectedYear
    };
    forkJoin({ submitRes: this.apiService.submitOnboardQuestion(submitData, this.enquiryData, this.enquiryType === EnquiryTypes.get_prospectus), recommendedEvents: this.getCachedRecommendedEvents() }).subscribe((res: any) => {
      this.clickStreamService.logButtonClickedEvent('Continue', null, null, null, null, null, this.enquiryType === EnquiryTypes.book_event ? clickStreamConsts.functionalityName.openDay : null);
      if (this.enquiryType === EnquiryTypes.book_event) {
        const data: any = {
          enquiryType: this.enquiryType,
          enquiryData: { interestedIntakeYear: this.selectedYear, ...this.enquiryData, institutionLogoUrl: this.institutionInfo?.logoPath }
        }
        if (res?.recommendedEvents?.recommendedEvents?.length) {
          data.enquiryDetails = this.enquiryDetails;
          this.wuniModalCtrl.openModal(MODALS.OPEN_DAY_BOOKING_SUGGESTION, null, data);
        }
        else {
          data.enquiryDetails = [this.enquiryData];
          data.hasNoRecommendedEvents = true;
          this.wuniModalCtrl.openModal(MODALS.OPEN_DAY_BOOKING_SUCCESS, null, data);
        }
      }
      else {
        this.openAddressModal();
      }
      this.isLoaderEnabled = false;
      this.cdref.detectChanges();
    }, () => {
      this.isLoaderEnabled = false;
      this.cdref.detectChanges();
    })
  }

  async openAddressModal() {
    const addressModal = await this.modalCtrl.create({
      component: AddressComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ADDRESS,
      mode: 'ios',
      animated: true,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData: this.enquiryData
      }
    })
    await addressModal.present();
    await addressModal.onWillDismiss().then(res => {
      if (res?.data?.dismissed)
        this.modalCtrl.dismiss({
          dismissed: res?.data?.dismissed, isSubmitted: res?.data?.isSubmitted
        });
      else if (res?.data)
        this.openEnquirySuccessModal(res?.data);
    })
  }

  async openEnquirySuccessModal(addressData?: any) {
    const modal = await this.modalCtrl.create({
      component: EnquirySuccessModalComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ENQUIRY_SUCCESS,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData: this.enquiryData,
        enquiryDetails: this.enquiryDetails,
        addressData
      }
    })
    await modal.present();
    await modal.onWillDismiss().then(res => {
      if (res?.data?.dismissed)
        this.modalCtrl.dismiss(res?.data, null, MODALS.ENQUIRY_YOE);
    })
  }

  modaldismiss(dismissed?: boolean, icon?: string, isOpenDayFlow?: boolean) {
    if (isOpenDayFlow)
      this.eventApiService.logInteractionClickstream(clickStreamConsts.eventType.closeClicked, icon, "Open Day");
    else
      this.clickStreamService.logButtonClickedEvent(icon);
    this.modalCtrl.dismiss({
      dismissed
    });
  }

  ngOnDestroy() {
    this.onboardingYOE$?.unsubscribe();
    this.enquiry$?.unsubscribe();
  }
}
