export const GetProspectusFavContent = {
    title: "Favourited, now what?",
    description: "Now’s the perfect time to learn more. Request a prospectus or more info from the uni"
}

export const RequestInfoFavContent = {
    title: 'Added to your favourites',
    description: "Now's a good time to contact the uni for more details or ask them any questions you have."
}

export const AlreadyRequestedInfoFavContent = {
    title: 'Info already requested',
    description: "Looks like you've already contacted the uni."
}

export const SuccessfullyAddedToFavContent = {
    title: 'Added to your favourites',
    viewLinkText: 'See favourites',
    description: "Need more info on the university or course? Click the button below to contact the uni directly."
}
