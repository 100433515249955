import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonContent, ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FAVOURITES_EDIT, MODALS } from '../../enums/modals.enum';
import { FavouriteTabs } from '../../models/search-tab.enum';
import { FavouriteUniCourses } from '../../pages/favourites/models/favourites.model';
import { ApiService } from '../../services/api.service';
import { AddFavoriteRequestList } from '../../models/favourite-page-data';
import { heightAnimateInOut } from '../../animations/success-msg.animation';
import { FavouriteService } from '../../pages/favourites/service/favourite.service';
import { clickStreamConsts } from 'src/app/utilities/constants/click-stream-const';
import { WuniModalControllerService } from '@services/wuni-modal-controller.service';
import { ComparisonService } from 'src/app/pages/comparison/service/comparison.service';

interface ViewCompareResponse {
  viewCompareResponse: any[];
}

@Component({
  selector: 'app-favourites-edit',
  templateUrl: './favourites-edit.component.html',
  styleUrls: ['./favourites-edit.component.scss'],
  animations: [heightAnimateInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavouritesEditComponent implements OnInit {
  @Input() mode = FAVOURITES_EDIT.FAVOURITE;
  @Input() thirtyFirstFavouriteObj: FavouriteUniCourses;
  @Input() final5Detail: any;
  FAVOURITES_EDIT = FAVOURITES_EDIT;
  favouriteUniCourseData$: Observable<ViewCompareResponse>;
  favourites: ViewCompareResponse;
  ctaLoading = false;
  FAVORITES_MAX_LIMIT = 30;
  maxSevenWarning = false;
  selectedUniNames: string;
  modalContents = {
    heading: 'Choose favourites',
    subHeading: 'Deselect the items to remove from favourites',
    ctaTitle: 'Save favourites',
    heapDataId: null
  }
  selectedFavourites: any[] = [];
  @ViewChild(IonContent) content: IonContent;
  wuniModalCtrl$: Subscription;

  constructor(private modalCtrl: ModalController,
    private router: Router,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef,
    private favouriteService: FavouriteService,
    private wuniModalCtrl: WuniModalControllerService,
    private comparisonService: ComparisonService) { }

  ngOnInit() {
    switch (this.mode) {
      case FAVOURITES_EDIT.FINAL5:
      case FAVOURITES_EDIT.FINAL5_ADD:
        this.modalContents.heading = 'Final 5 options';
        this.modalContents.subHeading = 'Choose up to 7 options';
        this.modalContents.ctaTitle = 'View Final 5';
        this.modalContents.heapDataId = 'viewFinal5';
        this.mode === FAVOURITES_EDIT.FINAL5 ? this.getFinal5List() : this.getFavouiteList();
        break;
      case FAVOURITES_EDIT.COMPARISON:
        this.modalContents.heading = 'Compare favourites';
        this.modalContents.subHeading = 'Choose up to 7 options';
        this.modalContents.ctaTitle = 'Compare selected';
        this.getFavouiteList();
        break;
      default:
        this.getViewCompareFavorites();
        break;
    }
    // if (this.mode === FAVOURITES_EDIT.COMPARISON) {
    //   this.modalContents.heading = 'Compare favourites';
    //   this.modalContents.subHeading = 'Choose up to 7 options';
    //   this.modalContents.ctaTitle = 'Compare selected';
    // } else if (this.mode === FAVOURITES_EDIT.FINAL5 || this.mode === FAVOURITES_EDIT.FINAL5_ADD) {
    //   this.modalContents.heading = 'Final 5 options';
    //   this.modalContents.subHeading = 'Choose up to 7 options';
    //   this.modalContents.ctaTitle = 'View Final 5';
    //   this.modalContents.heapDataId = 'viewFinal5';
    //   this.mode === FAVOURITES_EDIT.FINAL5 ? this.getFinal5List() : this.getFavouiteList();
    // } else {
    //   this.getViewCompareFavorites();
    // }
  }

  getViewCompareFavorites() {
    this.favouriteUniCourseData$ = this.apiService.getViewCompareFavorites().pipe(map((res: ViewCompareResponse) => {
      res?.viewCompareResponse?.forEach((val: FavouriteUniCourses) => {
        val.isSelected = this.mode === FAVOURITES_EDIT.FAVOURITE;
      });
      this.favourites = res;
      if (this.thirtyFirstFavouriteObj)
        this.favourites.viewCompareResponse = [{ ...this.thirtyFirstFavouriteObj, isSelected: true }, ...this.favourites.viewCompareResponse]
      this.getSelectedFavourites();
      return this.favourites;
    }))
  }

  getFavouiteList() {
    this.favouriteUniCourseData$ = this.apiService.getFavourites().pipe(map((res: any) => {
      this.favourites = { 'viewCompareResponse': [] };
      res?.favourites?.forEach((val: any) => {
        val.isDisabled = (!(val?.courseId && val?.institutionId) && !val.finalChoiceId);
        val.isSelected = (this.mode !== FAVOURITES_EDIT.COMPARISON && !!val.finalChoiceId);
        this.favourites.viewCompareResponse.push(val);
        //val.final5ShortlistingPosition = val?.final5ShortlistingPosition || 0;
      });
      // this.favourites.viewCompareResponse = this.favourites.viewCompareResponse.filter(f => f.courseId || !(f.courseId && f.institutionId));
      this.getSelectedFavourites();
      return this.favourites;
    }))
  }

  getFinal5List() {
    this.favouriteUniCourseData$ = this.favouriteService.getFinalFive().pipe(map((res: any) => {
      const isSelected = true;
      const isDisabled = true;
      this.favourites = { 'viewCompareResponse': [{ ...this.final5Detail, isSelected, isDisabled }] };
      res?.viewFinalShortlistingResponse?.forEach((val: any) => {
        val.isSelected = true;
        this.favourites.viewCompareResponse.push(val);
      });
      //this.favourites = res;
      //  if (this.thirtyFirstFavouriteObj)
      // this.favourites.viewCompareResponse.push({ ...this.thirtyFirstFavouriteObj, isSelected: true });
      //this.favourites.viewCompareResponse.push(res?.viewFinalShortlistingResponse);
      this.getSelectedFavourites();
      return this.favourites;
    }));
  }

  sorting(items: any[]) {
    items.sort((a, b) => {
      // Sort by id in ascending order
      if (a.finalChoicePosition && a.finalChoicePosition < b.finalChoicePosition)
        return -1;
      else if (a.finalChoicePosition && a.finalChoicePosition > b.finalChoicePosition)
        return 1;
      else
        return 0;
      //  else {
      //   // If id is the same, sort by active in descending order
      //   if (!a.isSelected && b.isSelected) {
      //     return 1;
      //   } else if (a.isSelected && !b.isSelected) {
      //     return -1;
      //   } else {
      //     return 0;
      //   }
      // }
    });
  }

  getSelectedFavourites(item?: any) {
    console.log(item, 'item');
    this.selectedUniNames = this.favourites?.viewCompareResponse?.filter(f => f.isSelected)?.map(ele => ele?.institutionName)?.join(' | ');
    this.selectedFavourites = this.favourites?.viewCompareResponse?.filter(res => res?.isSelected);
    if (this.mode === FAVOURITES_EDIT.COMPARISON || this.mode === FAVOURITES_EDIT.FINAL5 || this.mode === FAVOURITES_EDIT.FINAL5_ADD)
      if (this.selectedFavourites.length > 7 && item?.isSelected) {
        setTimeout(() => {
          this.content.scrollToPoint(0, 0, 1000);
        }, 400);
        this.maxSevenWarning = true;
        setTimeout(() => {
          this.content.scrollToPoint(0, 0, 1000);
        }, 200);
      }
      else {
        this.maxSevenWarning = false;
      }
    if (item && !item?.isSelected)
      item.isDisabled = (!(item?.courseId && item?.institutionId));
  }

  getCtaDisableStatus(): boolean {
    if (this.mode === FAVOURITES_EDIT.COMPARISON)
      return this.selectedFavourites?.length >= 2;
    else if (this.mode === FAVOURITES_EDIT.FINAL5)
      return (this.selectedFavourites?.length <= 7);
    return this.selectedFavourites?.length <= this.FAVORITES_MAX_LIMIT;
  }

  trackByFn(index: any, favUniCourse: any) {
    return favUniCourse?.courseId || favUniCourse?.institutionId;
  }

  ctaCLick() {
    this.ctaLoading = true;
    switch (this.mode) {
      case FAVOURITES_EDIT.FINAL5:
        this.updateFinal5List();
        break;
      case FAVOURITES_EDIT.COMPARISON:
      case FAVOURITES_EDIT.FINAL5_ADD: {
        const selectedFavourites = this.favourites?.viewCompareResponse?.filter(res => res?.isSelected).length;
        if (selectedFavourites > 7) {
          this.maxSevenWarning = true;
          this.content.scrollToPoint(0, 0, 1000);
          this.ctaLoading = false;
        } else {
          this.mode === FAVOURITES_EDIT.FINAL5_ADD ? this.addFinal5FromFav() : this.moveSelectedCourseForCompare();
        }
        break;
      }
      default: // FAVOURITES_EDIT.FAVOURITES
        this.checkFinal5ForUpdate();
        break;
    }
  }

  checkFinal5ForUpdate() {
    const favList = this.getModifiedfavourites();
    const isFinalLists = this.getModifiedfavourites()?.some(s => s.finalChoiceId);
    if (isFinalLists) {
      this.ctaLoading = false;
      this.wuniModalCtrl$ = this.wuniModalCtrl.emittedData.subscribe((res) => {
        if (res?.modalId === MODALS.FINAL5_REMOVE && res?.data) {
          this.wuniModalCtrl$?.unsubscribe();
          this.closeModalAndNavigateTofavourites();
        }
      });
      this.openRemoveFinal5Model(favList);
    } else {
      this.updateFavourites();
    }
  }

  openRemoveFinal5Model(data: any) {
    this.wuniModalCtrl.openModal(MODALS.FINAL5_REMOVE, 'removeMultiInfo', data);
  }

  addFinal5FromFav() {
    const removeList = this.favourites?.viewCompareResponse.filter(f => !f.isSelected && f.finalChoiceId).map(m => {
      return {
        'finalChoiceId': m.finalChoiceId,
        'oldPosition': m.finalChoicePosition,
        'newPosition': null
      }
    });
    const addItem = this.favourites?.viewCompareResponse.filter(f => f.isSelected && !f.finalChoiceId).map(m => {
      return {
        "courseId": m.courseId,
        "institutionId": m.institutionId
      }
    });
    const updateList = [...removeList, ...addItem];
    const SRInstitutionIDOrder = this.favourites?.viewCompareResponse?.filter(f => f.isSelected)?.map(ele => ele?.institutionId)?.join(', ');
    this.favouriteService.logFinal5InteractionClickstream({ eventType: clickStreamConsts.buttonClicked, CTATitle: this.modalContents.ctaTitle, sectionName: "Final 5", moreInfo: { SRInstitutionIDOrder } });
    if (updateList.length)
      this.favouriteService.updateFinalShortListing('update', updateList).subscribe(res => {
        this.ctaLoading = false;
        this.modalCtrl.dismiss('true');
      }, () => {
        this.modalCtrl.dismiss();
      });
    else
      this.modalCtrl.dismiss();
  }

  updateFinal5List() {
    const removeItems = this.favourites?.viewCompareResponse.filter(f => !f.isSelected).map(m => {
      return {
        'finalChoiceId': m.finalChoiceId,
        'oldPosition': m.finalChoicePosition,
        'newPosition': null
      }
    });
    const addItems = [{
      "courseId": this.final5Detail.courseId,
      "institutionId": this.final5Detail.institutionId
    }];
    const SRInstitutionIDOrder: string = this.favourites?.viewCompareResponse.filter(f => f.isSelected).map(m => m.institutionId)?.join(', ');
    this.favouriteService.logFinal5InteractionClickstream({ eventType: clickStreamConsts.buttonClicked, CTATitle: this.modalContents.ctaTitle, sectionName: "Final 5", moreInfo: { SRInstitutionIDOrder } });
    const updateList = [...removeItems, ...addItems];
    this.favouriteService.updateFinalShortListing('update', updateList).subscribe(res => {
      this.ctaLoading = false;
      this.modalCtrl.dismiss({ removeItems, addItems });
    }, () => {
      this.modalCtrl.dismiss({ error: true });
    });
  }

  moveSelectedCourseForCompare() {
    this.comparisonService.compareRequestBody.compareRequestBody = this.favourites?.viewCompareResponse.filter(f => f.isSelected).map(m => {
      return {
        "courseId": m.courseId,
        "institutionId": m.institutionId
      }
    });
    console.log("selectedFavourites", this.comparisonService.compareRequestBody)
    this.close();
    this.router.navigate(['/comparison']);
  }

  closeModalAndNavigateTofavourites() {
    this.close();
    localStorage.setItem('favouriteEdit', 'true');
    this.router.navigate(['tab', 'shortlist'], { queryParams: { type: FavouriteTabs.Favourites } });
  }

  updateFavourites() {
    const modifiedFavouritesList = this.getModifiedfavourites();
    if (!modifiedFavouritesList?.length) {
      this.closeModalAndNavigateTofavourites();
      return;
    }
    this.apiService.saveToFavourites(modifiedFavouritesList).subscribe(() => {
      this.closeModalAndNavigateTofavourites();
      this.ctaLoading = false;
    }, () => {
      this.ctaLoading = false;
      this.cdRef.markForCheck();
    });
  }

  getModifiedfavourites(): AddFavoriteRequestList[] {
    const removedfavourites = this.favourites?.viewCompareResponse?.slice(1).filter(res => !res?.isSelected) || [];
    const addedFavourites = this.favourites?.viewCompareResponse?.slice(0, 1).filter(res => res?.isSelected) || [];
    const updatedFavourites: FavouriteUniCourses[] = [...removedfavourites, ...addedFavourites];
    const submitFormate = <AddFavoriteRequestList[]>updatedFavourites.map((res: FavouriteUniCourses) => {
      return {
        inputFlag: res.isSelected,
        contentId: res?.courseId ? res.courseId : res.institutionId,
        contentName: res.courseName ? res.courseName : res.institutionName,
        contentType: res?.courseId ? "COURSE" : "INSTITUTION",
        finalChoiceId: res?.finalChoiceId || undefined,
        finalChoicePosition: res?.finalChoicePosition || undefined
      }
    });
    return submitFormate;
  }

  close() {
    this.wuniModalCtrl.dismiss(MODALS.FAVOURITES_EDIT);
  }
}
