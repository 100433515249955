export enum MODALS {
    ENQUIRY_YOE = 'enquiry_yoe',
    ENQUIRY_SUCCESS = "enquiry_success",
    WELCOME = "welcome",
    SIGN_IN = "sign_in",
    SIGN_UP_1 = "sign_up_1",
    SIGN_UP_2 = "sign_up_2",
    ADDRESS = "address",
    FAVOURITE = "added_your_fav",
    COURSE_SEARCH = "course_search",
    COURSE_SEARCH_SELECT = "course_search_select",
    CAREER_AREA = "career_area",
    CAREER_SORT_OPTIONS = "career_sort_options",
    QUIZ = "quiz",
    JOB_ROLE = "job_role",
    FORGOT_PASSWORD = "forgot_password",
    UPDATE_PASSWORD = "update_password",
    FAVOURITES_EDIT = "favourites_edit",
    FAVOURITES_LIMIT_EECEEDS = "favourites_limit_exceeds",
    OPEN_DAY_BOOKING_YOE = "open_day_booking_yoe",
    OPEN_DAY_BOOKING_SUGGESTION = "open_day_booking_suggestion",
    OPEN_DAY_BOOKING_SUCCESS = "open_day_booking_success",
    FINAL5_REMOVE = "final5_remove",
    COMPARISON_SUGGESTION = "comparison_suggestion"
}

export enum FAVOURITES_EDIT {
    FAVOURITE = "favourites_select",
    COMPARISON = "comparison_select",
    FINAL5 = "final5_select",
    FINAL5_ADD = "final5_add"
}