import { NativeStorage } from '@ionic-native/native-storage/ngx';

const MYSTORAGE_KEY_PREFIX = '@MyStorage:';

export class MyStorage implements Storage {
  [name: string]: any;
  length: number;

  static nativeStorage: NativeStorage;
  static dataMemory: Object = {
  };

  constructor() {
    MyStorage.nativeStorage = new NativeStorage();
  }

  key(index: number): string {
    return Object.keys(MyStorage.dataMemory)[index];
  }

  /**
   * This is used to set a specific item in storage
   */
  setItem(key: string, value) {
    MyStorage.nativeStorage.setItem(MYSTORAGE_KEY_PREFIX + key, value).then(() => { }).catch(() => { });
    MyStorage.dataMemory[key] = value;
    return MyStorage.dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  getItem(key: string): string {
    if (MyStorage.dataMemory[key])
      return MyStorage.dataMemory[key]
    else
      return undefined;
  }

  /**
   * This is used to remove an item from storage
   */
  removeItem(key: string) {
    MyStorage.nativeStorage.remove(MYSTORAGE_KEY_PREFIX + key).then(res => {
      console.log(key, res, "native.removeItem");
    }).catch(() => { });
    return delete MyStorage.dataMemory[key];
  }

  /**
   * This is used to clear the storage
   */
  clear() {
    MyStorage.dataMemory = {};
    MyStorage.nativeStorage.clear().then(res => { })
    return MyStorage.dataMemory;
  }

  /**
   * Will sync the MyStorage data from AsyncStorage to storageWindow MyStorage
   */
  static async sync(): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      await MyStorage.nativeStorage.keys().then(keys => {
        if (!keys.length)
          return resolve();
        keys.forEach(async (key: string, index) => {
          await MyStorage.nativeStorage.getItem(key).then((data: any) => {
            const value = data;
            const memoryKey = key.replace(MYSTORAGE_KEY_PREFIX, '');
            MyStorage.dataMemory[memoryKey] = value;
            if (keys.length - 1 === index)
              resolve();
          }).catch(() => resolve());
        });
      }).catch(() => resolve());
    });
  }
}
