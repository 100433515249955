import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ApiService } from '../../services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Inappbrowser } from '../../utilities/common/inappbrowser';
import { SignInPage } from '../sign-in/sign-in.page';
import { SignUpPage } from '../sign-up-screen1/sign-up.page';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { Subscription } from 'rxjs';
import { ClickstreamService } from '../../services/clickstream.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { EnquiryData, EnquiryType, EnquiryTypes } from '../../models/enquiry.model';
import { MODALS } from '../../enums/modals.enum';
import { SocialLoginService } from '../../services/social-login.service';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { SignedInMethod, SocialLoginState } from '../../models/social-login-state';
import { AnalyticsService } from '../../services/analytics.service';
import { constants } from '../../utilities/constants/constants';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss']
})

export class WelcomePage implements OnInit, OnDestroy {
  @Input() enquiryType: EnquiryType;
  @Input() enquiryData: EnquiryData;
  @Input() otherData: any;
  enquiryTypes = EnquiryTypes;
  contentType = 'appModuleComponent';
  apiurl = '/welcome';
  welcomeDetails: any;
  imgBannerDel: any;
  imgLogoDel: any;
  emaildel: any;
  sectionDel: any = {
  };
  bannerImgUrl: any;
  isLoaderEnabled: boolean = false;
  enquiryFlag: boolean = false;
  pageData: any = {};
  institutionInfo: any;
  institutionName: any;
  isPlatformAndroid: boolean;
  isSocialLoginFailed: boolean = false;

  clickStreamPV$: Subscription;
  welcome$: Subscription;
  dbCheck$: Subscription;
  errorMsg$: Subscription;
  userSource: any;

  welform = this.formBuilder.group({
    userName: [localStorage.getItem('email') || '', [Validators.required, this.commonService.emailValidation]]
  });

  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private modalCtrl: ModalController,
    private iab: Inappbrowser,
    private statusBar: StatusBar,
    public platform: Platform,
    private clickstreamService: ClickstreamService,
    private analytics: AnalyticsService,
    private alertController: AlertController,
    private socialLoginService: SocialLoginService, private zone: NgZone) {
    this.userSource = localStorage.getItem('userSource') ? localStorage.getItem('userSource') : 'not-set';
    this.errorMsg$ = SocialLoginState.socialLoginState$.subscribe((status) => {
      this.zone.run(() => {
        const signedInMethod = SocialLoginState.getSocialLoginTriggeredPoint.signedInMethod;
        this.clickstreamService.logSocialLogin(signedInMethod, SocialLoginState.loginMethodToCtaMapper.get(signedInMethod), status?.reason);
        this.isSocialLoginFailed = status?.error;
        SocialLoginState.clearSocialLoginTriggeredPoint();
      })
    })
  }

  ngOnInit() {
    this.isPlatformAndroid = this.platform.is('android') || this.platform.is('desktop')
    this.enquiryFlag = !!this.enquiryType;
    this.getModalHeading();
    if (!this.enquiryType)
      this.platform.ready().then(() => {
        if (this.platform.is('android') || this.platform.is('ios'))
          this.statusBar.backgroundColorByHexString("#00BBFD");
      }).catch(() => { })
  }

  ionViewDidEnter() {
    this.commonService.setCurrentPage(clickStreamConsts.welcomePage);
    this.clickStreamPV$ = this.clickstreamService.pageViewedClickstreamLogging();
  }

  getModalHeading() {
    switch (this.enquiryType) {
      case EnquiryTypes.guest_login:
        this.pageData.subHeading = 'Welcome to Whatuni'
        break;
      case EnquiryTypes.request_info:
        this.apiurl = '/interaction/welcome';
        this.pageData.title = 'Request info';
        break;
      case EnquiryTypes.get_prospectus:
        this.apiurl = '/interaction/prospectus/welcome';
        this.pageData.title = 'Get prospectus';
        break;
      case EnquiryTypes.career_matches:
        this.pageData.title = 'Careers';
        this.pageData.subHeading = 'Sign in or create an account to see your career matches';
        break;
      case EnquiryTypes.favourite_course:
        this.pageData.subHeading = 'Create an account to favourite this course';
        break;
      case EnquiryTypes.favourite_uni:
        this.pageData.subHeading = 'Create an account to favourite this university';
        break;
      case EnquiryTypes.book_event:
        this.pageData.title = "Book open day"
        this.apiurl = "/interaction/events/welcome";
        break;
      case EnquiryTypes.final_5:
        this.pageData.subHeading = 'Create an account or sign in'
        break;
    }
    this.getContentData();
  }

  /**
  * @see: This function used to get contentfull datas from backend
  */
  getContentData(): void {
    this.welcome$ = this.apiService.getContentfulDetails(this.contentType, 2, this.apiurl, this.enquiryData).subscribe(res => {
      this.welcomeDetails = res;
      this.institutionInfo = res?.enquiryInfo;
      this.institutionName = res?.enquiryInfo?.institutionName || this.enquiryData?.institutionName;
      if ((!this.enquiryFlag) && res?.trackSessionId)
        localStorage.setItem('trackSessionId', res?.trackSessionId);
      this.getDetails();
    })
  }

  /**
   * @see: This function used to get submit input field values
   * @param: fieldKey, sectionValue as string
   * @returns: fieldValues
   */
  getDetails(): void {
    this.welcomeDetails?.fieldComponents.forEach(m => {
      if (m?.imageTitle === 'welcomeImg') {
        this.imgBannerDel = m;
        this.bannerImgUrl = this.sanitizer.bypassSecurityTrustStyle(`url(https:${String(this.imgBannerDel?.url)}) no-repeat center top`);
      } else if (m?.imageTitle === 'logoImg') {
        this.imgLogoDel = `https:${String(m?.url)}`;
      } else if (m?.sectionTitle === 'welcomeScreen') {
        this.sectionDel = m;
        m.tileTexts = m?.sectionDescription.split(",");
      } else if (m?.fieldName === 'email') {
        this.emaildel = m
      } else if (m?.sectionTitle === 'requestInfoWelcomeScreen') {
        this.pageData.subHeading = m?.sectionName;
        this.pageData.decription = m?.sectionDescription;
      } else if (this.enquiryType === EnquiryTypes.get_prospectus && m?.sectionTitle === 'getProspectusWelcomeScreen') {
        this.pageData.subHeading = m?.sectionName;
        this.pageData.decription = m?.sectionDescription;
      } else if (m?.sectionTitle === "eventsWelcomeScreen") {
        this.pageData.subHeading = m?.sectionName;
        this.pageData.decription = m?.sectionDescription;
      }
    })
  }

  /**
   * @see: This function used to get email from html and set emailId into the local storage after trim.
   */
  gotoSignUpScreen1(): void {
    this.isLoaderEnabled = true;
    const email = this.welform.controls['userName'].value.trim();
    this.commonService.userDetails.userName = email;
    localStorage.setItem('email', email);
    this.checkUserExistInDB(email);
    const source = localStorage.getItem('userSource') ? localStorage.getItem('userSource') : 'not-set'
    const param = { "page_name": this.enquiryData?.pageName, source, "method": 'not-set' }
    if (this.enquiryType === EnquiryTypes.book_event) {
      this.clickstreamService.logButtonClickedEvent('Continue', clickStreamConsts.eventType.buttonClicked, null, null, null, null, 'Open days')
      param.source = 'open-days';
    } else if (this.enquiryType === EnquiryTypes.final_5) {
      this.clickstreamService.logButtonClickedEvent('Continue', clickStreamConsts.eventType.buttonClicked, null, null, null, null, 'Final 5')
    }
    this.analytics.logEvent(constants.firebaseEvents.signin_click, param);
  }

  /**
   * @see: This function used to check user existence in DB
   * @param: email as string
   */
  checkUserExistInDB(emailId: string): void {
    this.dbCheck$ = this.apiService.checkExistUserName(emailId.toLowerCase(), 'Y').subscribe(res => {
      if (res?.emailCheckResponse)
        if (this.enquiryFlag)
          this.enquiryFormModal('SIGN_IN');
        else
          this.router.navigate(['sign-in']);
      else
        if (this.enquiryFlag)
          this.enquiryFormModal('SIGN_UP');
        else
          this.router.navigate(['sign-up-screen1']);
    }, () => {
      this.isLoaderEnabled = false;
    })
    localStorage.removeItem('guestLogin');
  }

  openInappBrowser(eve) {
    this.iab.openBrowser(eve);
  }

  loginAsGuest(): boolean {
    this.clickstreamService.logButtonClickedEvent('Skip');
    const param = {
      "page_name": 'welcome', 'source': localStorage.getItem('userSource') ? localStorage.getItem('userSource') : 'not-set', "method": 'not-set'
    }
    this.analytics.logEvent('skiplink_clicked', param);
    this.commonService.userOnboardingDetails.removeStoredOnboardingDetails();
    localStorage.setItem('guestLogin', 'true');
    localStorage.setItem('userSource', '');
    this.router.navigateByUrl('/tab/home');
    return false;
  }

  async enquiryFormModal(toScreen: string) {
    const modal = await this.modalCtrl.create({
      component: toScreen === 'SIGN_IN' ? SignInPage : SignUpPage,
      cssClass: 'my-modal-styles',
      id: toScreen === 'SIGN_IN' ? MODALS.SIGN_IN : MODALS.SIGN_UP_1,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData: this.enquiryData
      }
    })
    modal.onWillDismiss().then((res: any) => {
      this.isLoaderEnabled = false;
      if (res?.data?.dismissed) {
        if (this.enquiryType === EnquiryTypes.book_event)
          this.modalCtrl.dismiss({ enquiryType: this.enquiryType, enquiryData: this.enquiryData }, null, MODALS.WELCOME);
        this.modaldismiss(res?.data?.dismissed, res?.data?.isSubmitted);
      }
    })
    await modal.present();
  }

  googleLogin() {
    this.setSocialLoginTriggerPoints('Google');
    this.socialLoginService.socialLogin(CognitoHostedUIIdentityProvider.Google, 'Welcome');
    this.logFirebase('Google');
  }

  faceBookLogin() {
    this.setSocialLoginTriggerPoints('Facebook');
    this.socialLoginService.socialLogin(CognitoHostedUIIdentityProvider.Facebook, 'Welcome');
    this.logFirebase('Facebook');
  }

  logFirebase(signedInMethod) {
    const param = { "page_name": SocialLoginState.getSocialLoginTriggeredPoint?.pageName || 'welcome', 'source': localStorage.getItem('userSource') ? localStorage.getItem('userSource') : 'not-set' }
    if (SocialLoginState.getSocialLoginTriggeredPoint?.pageName.includes('quiz'))
      param.source = 'career_quiz'
    else if (this.enquiryType === EnquiryTypes.book_event)
      param.source = 'open_days'
    else if (SocialLoginState.getSocialLoginTriggeredPoint?.pageName.includes('final-5'))
      param.source = 'final_5'
    this.analytics.logEvent(signedInMethod === 'Facebook' ? constants.firebaseEvents.fbsigninsignup_clicked : constants.firebaseEvents.googlesigninsignup_clicked, param);
  }

  setSocialLoginTriggerPoints(signedInMethod: SignedInMethod) {
    if (this.enquiryType) {
      const enquiryType: EnquiryType = (this.enquiryType === EnquiryTypes.favourite_uni || this.enquiryType === EnquiryTypes.favourite_course) ? EnquiryTypes.favourite : this.enquiryType;
      SocialLoginState.setSocialLoginTriggeredPoint = {
        signedInMethod,
        pageName: this.enquiryData?.pageName,
        pageUrl: this.enquiryData?.pageUrl,
        uniId: this.enquiryData?.institutionId,
        courseId: this.enquiryData?.courseId,
        eventCalendarItemId: this.enquiryData?.eventCalendarItemId,
        triggeredBy: enquiryType,
        data: this.otherData
      }
    } else {
      SocialLoginState.setSocialLoginTriggeredPoint = {
        signedInMethod,
        pageName: "welcome",
        pageUrl: "/welcome",
        triggeredBy: EnquiryTypes.guest_login,
        data: this.otherData
      }
    }
  }

  async terminateQuiz() {
    const alert = await this.alertController.create({
      header: 'Are you sure?',
      message: "If you stop the test, you’ll lose your progress so far.",
      cssClass: 'stop_career_matcher',
      mode: "ios",
      buttons: [
        {
          text: 'No, I’ll continue',
          role: 'continue'
        },
        {
          text: 'Yes, stop the test',
          role: 'stop',
          handler: () => {
            this.modalCtrl.dismiss(null, null, MODALS.WELCOME);
            this.modalCtrl.dismiss(null, null, MODALS.QUIZ);
          }
        }
      ]
    });
    await alert.present();
  }

  ionViewWillLeave() {
    this.statusBar.backgroundColorByHexString('#ffffff');
    this.statusBar.styleDefault();
    this.welcome$?.unsubscribe();
    this.dbCheck$?.unsubscribe();
    this.clickStreamPV$?.unsubscribe();
    this.errorMsg$?.unsubscribe();
  }

  ionViewDidLeave() {
    this.isLoaderEnabled = false;
  }

  modaldismiss(dismissed?: any, isSubmitted?: any) {
    if (!dismissed && !isSubmitted) {
      if (this.enquiryType === EnquiryTypes.final_5)
        this.clickstreamService.logButtonClickedEvent("close", clickStreamConsts.eventType.closeClicked, null, null, null, null, "Final 5");
      else
        this.clickstreamService.logButtonClickedEvent("close", clickStreamConsts.eventType.closeClicked);
      if (this.enquiryType === EnquiryTypes.career_matches) {
        this.terminateQuiz();
        return;
      }
    }
    this.modalCtrl.dismiss({ dismissed, isSubmitted }, null, MODALS.WELCOME);
  }

  ngOnDestroy(): void {
    SocialLoginState.clearSocialLoginTriggeredPoint();
    this.errorMsg$?.unsubscribe();
  }
}
