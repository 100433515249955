export class User {
    email: string | null;
    password: string | null;
    firstName: string | null;
    lastName: string | null;
    termsFlag: boolean;
    marketingFlag: boolean;
    picsFlag: boolean;
    siteId: string | null;
    profileImgUrl: string | null;
    mobileID: string | null;
}