export type EnquiryType = 'request_info' | 'get_prospectus' | 'favourite' | 'favourite_course' | 'favourite_uni' | 'GUEST-LOGIN' | "career-matches" | "book_event" | "final_5"; // Literal type

export enum EnquiryTypes {
    request_info = "request_info",
    get_prospectus = "get_prospectus",
    favourite_course = "favourite_course",
    favourite_uni = "favourite_uni",
    guest_login = "GUEST-LOGIN",
    favourite = "favourite",
    career_matches = "career-matches",
    book_event = "book_event",
    final_5 = "final_5"
}

export interface GetProspectusEnquiryData {
    'requestProspectusWebform'?: string,
}

export interface BookEventEnquiryData {
    "studyLevel"?: number,
    "eventCalendarItemId"?: number,
    "keyProfileId"?: number,
    "orderItemId"?: number,
    "providerEmail"?: string,
    "hcCollegeId"?: number,
    "institutionLogoUrl"?: string,
    "interestedIntakeYear"?: string | number,
    "eventDate"?: string,
    "eventLocation"?: string,
    "eventTime"?: string,
    "studyLevelId"?: string,
    "location"?: string
}

export interface EnquiryData extends BookEventEnquiryData {
    'institutionId'?: string | number,
    'institutionName'?: string,
    'subOrderItemId'?: string | number,
    'courseId'?: string | number,
    'courseName'?: string,
    'pageName'?: 'course-details' | 'institution-profile' | string,
    'subject'?: string,
    'requestProspectusWebform'?: string,
    'pageUrl'?: string,
}

export interface ModalContents {
    title: string,
    heading?: string,
    description?: string,
}