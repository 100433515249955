import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { environment } from '../environments/environment';
import { apiEndpointURI, guestApiEndPointURL } from '../app/utilities/constants/api-endpoints-uri';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonService } from './services/common.service';
import 'crypto-js/lib-typedarrays';
import awsconfig from "./configs/aws.config";
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AuthGuard } from './services/auth.guard';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { GlobalErrorHandler } from './services/global-error-handler';
import { ServerErrorInterceptor } from './services/http-error-interceptor';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AdjustService } from './services/adjust.service';
import { Adjust } from '@awesome-cordova-plugins/adjust/ngx';
import { AmplifyService } from 'aws-amplify-angular';

import { MyStorage } from './configs/aws-amplify';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { clickStreamConsts } from './utilities/constants/click-stream-const';
import { ClickstreamService } from './services/clickstream.service';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { WuniModalControllerService } from './services/wuni-modal-controller.service';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      scrollPadding: false,
      scrollAssist: true
    }),
    AppRoutingModule,
    HttpClientModule,
    OverlayModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule],
  providers: [
    NativeStorage,
    { provide: APP_INITIALIZER, useFactory: initializeAppCustomLogic, deps: [Platform, NativeStorage], multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: 'environment', useValue: environment },
    { provide: 'apiEndpointURI', useValue: apiEndpointURI },
    { provide: 'guestApiEndPointURL', useValue: guestApiEndPointURL },
    { provide: 'CLICKSTREAM', useValue: clickStreamConsts },
    { provide: 'commonService', useValue: CommonService },
    { provide: 'adjustService', useValue: AdjustService },
    { provide: 'WuniModalControllerService', useClass: WuniModalControllerService },
    { provide: 'ClickstreamService', useClass: ClickstreamService },
    SplashScreen,
    AuthGuard,
    InAppBrowser,
    OpenNativeSettings,
    Diagnostic,
    CommonService,
    Adjust,
    Keyboard,
    StatusBar,
    EmailComposer,
    FirebaseAnalytics,
    Network,
    AppVersion,
    LaunchReview,
    Deeplinks,
    Device,
    ScreenOrientation,
    SocialSharing,
    AmplifyService,
    SmsRetriever,
    StreamingMedia
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function initializeAppCustomLogic(platform: Platform, nativeStorage: NativeStorage): () => Promise<void> {
  return () =>
    new Promise(async (resolve) => {
      platform.ready().then(async () => {
        if (platform.is('android') || platform.is('ios') || platform.is('iphone') || platform.is('ipad')) {
          console.log("Storage: Native storage");
          awsconfig.Auth.oauth.redirectSignIn = environment.redirectSignIn;
          awsconfig.Auth.oauth.redirectSignOut = environment.redirectSignOut;
          awsconfig.Auth.storage = new MyStorage();
        } else {
          console.log("Storage: Local storage");
          return resolve();
        }
        await nativeStorage.keys().then(async (keys) => {
          const tokenKeys = keys?.filter((value: string) => value?.includes('@MyStorage:'));
          if (!tokenKeys?.length)
            return resolve();

          await tokenKeys?.forEach(async (key: string, index: number) => {
            await nativeStorage.getItem(key).then((data: any) => {
              const value = data;
              const memoryKey = key.replace('@MyStorage:', '');
              MyStorage.dataMemory[memoryKey] = value;
              if (tokenKeys.length - 1 === index)
                return resolve();
            }).catch(() => resolve());
          });
        }).catch(() => resolve());
      });
    });
}
