import { Component, OnDestroy, OnInit, ViewChildren, QueryList } from '@angular/core';
import { Platform, IonRouterOutlet, NavController } from '@ionic/angular';
import { Location } from '@angular/common';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AnalyticsService } from './services/analytics.service';
import { clickStreamConsts } from './utilities/constants/click-stream-const';
import { constants } from './utilities/constants/constants';
import { ClickStream } from './models/click-stream';
import WebStorageCookieStore from 'tough-cookie-web-storage-store';
import { ClickstreamService } from './services/clickstream.service';
import { CommonService } from './services/common.service';
import { Router } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ApiService } from './services/api.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { AdjustService } from './services/adjust.service';
import { NotificationService } from './services/notification.service';
import { SocialLoginService } from './services/social-login.service';
import { AmplifyService } from 'aws-amplify-angular';
import { SocialLoginState } from './models/social-login-state';
import { Subscription } from 'rxjs';
import { EnquiryData, EnquiryTypes } from './models/enquiry.model';
import { Auth } from 'aws-amplify';
import awsconfig from './configs/aws.config';
import { Cookie, CookieJar } from 'tough-cookie';
import { DeeplinksService } from './utilities/common/deeplinks.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy, OnInit {
  appOpenInfo = new ClickStream();
  checkNetworkFlag = false;
  deeplink$: Subscription;
  public enquiryType: EnquiryTypes;
  public enquiryData: EnquiryData;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  constructor(public platform: Platform,
    public location: Location,
    public splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private clickstreamService: ClickstreamService,
    private analytics: AnalyticsService,
    private commonService: CommonService,
    private network: Network,
    private router: Router,
    private apiService: ApiService,
    private screenOrientation: ScreenOrientation,
    private deeplinks: Deeplinks,
    private navCtrl: NavController,
    private adjustService: AdjustService,
    private socialLoginService: SocialLoginService,
    private amplifyService: AmplifyService,
    private notification: NotificationService,
    private deeplinksService: DeeplinksService
  ) {
    Auth.configure(awsconfig);
    this.appOpenInfo.pageName = localStorage.getItem('userid') ? clickStreamConsts.home : clickStreamConsts.welcomePage;
    this.appOpenInfo.eventType = clickStreamConsts.appOpenEvent;
    this.appOpenInfo.actionType = clickStreamConsts.interaction;
    // Handle back button Android
    if (this.platform.is('android'))
      this.deviceBackButtonEvent();

    this.checkNetworkConn();
    this.initializeApp();
  }

  ngOnInit(): void {
    this.socialLoginService.setTokenForSocialLogin();
    this.amplifyService.authStateChange$.subscribe(async authState => {
      switch (authState.state) {
        case 'signedOut':
          // this.navCtrl.navigateRoot('welcome');
          break;
        case 'signedIn':
          if (SocialLoginState?.getSocialLoginTriggeredPoint?.signedInMethod)
            if (SocialLoginState?.getSocialLoginTriggeredPoint?.pageUrl !== "/welcome") {
              this.router.navigateByUrl(SocialLoginState.getSocialLoginTriggeredPoint.pageUrl);
            } else if (SocialLoginState?.getSocialLoginTriggeredPoint?.triggeredBy === EnquiryTypes.guest_login &&
              SocialLoginState?.getSocialLoginTriggeredPoint?.pageName === "welcome") {
              const idToken = (await Auth.currentSession().then()).getIdToken();
              if (idToken.payload['new_user_flag'])
                this.router.navigate(['/on-boarding']);
              else if (!localStorage.getItem('onBoardingVisited'))
                this.router.navigate(['/on-boarding']);
              else
                this.navCtrl.navigateRoot('/tab/home');
            } else {
              this.navCtrl.navigateRoot('/tab/home');
            }
          break;
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(async (e) => {
      // For ionic flow plugin issue
      this.setupDeeplinks();
      this.splashScreen.hide();

      // Show Status bar
      if (this.platform.is('android')) {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.statusBar.styleDefault();
      } else {
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.statusBar.styleDefault();
      }
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

      this.analytics.integrateUxcam();
      this.adjustService.integrateAdjust();
      const uid = localStorage.getItem('email') ? localStorage.getItem('email') : ''
      this.analytics.setUserId('userid', uid);
      this.apiService.getVersion().subscribe(res => {
        if (constants.appVersion < res.latestVersion)
          this.router.navigate(['no-connection'], {
            queryParams: {
              errorCode: 'update', updatelabel: res.mandatoryUpdate
            }
          });
      }, err => {
        console.log(err);
      })
      this.clickstreamService.clickStreamLogging(this.appOpenInfo).subscribe(); // Click Stream Logging for appOpen
      // UXcam Setup

      //heap cookie
      const cookiePropertyDescriptor = Object.getOwnPropertyDescriptor(
        document,
        'cookie'
      );
      const isCookieConfigurable =
        !cookiePropertyDescriptor || cookiePropertyDescriptor.configurable;

      if (!isCookieConfigurable) {
        console.error(
          'Failed to initialise cookie storage on iOS. This might cause Heap tracking to fail is likely due to a regression causig cookie storage to be initialized more than once'
        ); //only init cookie storage once during app init
        return;
      }

      // const { Cookie } = tough;
      const FAKE_APP_URI = 'https://' + window.location.host;
      const store = new WebStorageCookieStore(localStorage);
      const cookiejar = new CookieJar(store, { rejectPublicSuffixes: false }); // false here sets the rejectPublicSuff...

      Object.defineProperty(document, 'cookie', {
        get() {
          return cookiejar.getCookieStringSync(FAKE_APP_URI);
        },
        set(cookie) {
          // loose; true lets us accept cookies with key and no value, which the original tests for this library included
          cookiejar.setCookieSync(
            Cookie.parse(cookie, {
              loose: true
            }),
            FAKE_APP_URI
          );
        }
      });

      //Open rate popup

      // this.platform.resume.subscribe(() => {
      // this.notification.notificationPermission().then(() => {
      //   this.notification.onResumeNotificationStatusCheck$.next(true)
      // }).catch(() => {
      //   this.notification.onResumeNotificationStatusCheck$.next(false)
      // })
      // });

      this.platform.pause.subscribe(() => {
        // this.deeplink$?.unsubscribe();
        this.appOpenInfo.eventType = clickStreamConsts.appCloseEvent;
        this.appOpenInfo.pageName = this.commonService?.currentPageName;
        this.appOpenInfo.actionType = clickStreamConsts.interaction;
        this.appOpenInfo.sourcePageName = this.commonService.previousPageName;
        this.clickstreamService.clickStreamLogging(this.appOpenInfo).subscribe();
      })

      if (localStorage.getItem('reivewCount') === null) {
        localStorage.setItem('reivewCount', JSON.stringify({
          count: 1
        }));
      } else {
        let rcount = JSON.parse(localStorage.getItem('reivewCount'));
        rcount = rcount.count + 1;
        localStorage.setItem('reivewCount', JSON.stringify({
          count: rcount
        }));
        this.commonService.appRate();
      }
      setTimeout(() => {
        this.notification.mcpluginCallbacks();
      }, 1000);
    });
  }

  setupDeeplinks() {
    // this.trackByInHeap("whatuni://shortlist?type=2&campaignName=test&capaignSource=notification");
    // npm install @ionic-native/deeplinks
    // cordova plugin add ionic-plugin-deeplinks --variable URL_SCHEME=whatuni --variable DEEPLINK_SCHEME=https --variable DEEPLINK_HOST=whatuni.com
    /*************** Hidden for this sprint *****************/
    this.deeplink$ = this.deeplinks.routeWithNavController(this.navCtrl, {
      "/federated-redirection": "/federated-redirection",
      "/open-days": "/open-days"
    }).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      console.log('Successfully matched route', JSON.stringify(match));
      this.deeplinksService.navigateLinks(match);
    }, nomatch => {
      console.log('no matched route', JSON.stringify(nomatch));
      // nomatch.$link - the full link data
      this.deeplinksService.navigateLinks(nomatch);
    })
  }

  deviceBackButtonEvent() {
    const intval = setInterval(() => {
    }, 5000);
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (this.router.url.includes('/welcome') || this.router.url.includes('/tab/home') || this.router.url.includes('on-boarding')) {
          clearInterval(intval);
          navigator['app'].exitApp();
        } else if (outlet.canGoBack()) {
          if (this.router.url.includes('/tab/search') || this.router.url.includes('/tab/shortlist') || this.router.url.includes('/tab/user-profile')) {
            if (this.router.url.includes('/tab/search/search-results/'))
              this.router.navigate(['/tab/search']);
          } else {
            this.location.back();
          }
        } else {
          if (!this.router.url.includes('/tab/user-profile') || this.router.url.includes('personal-details') || this.router.url.includes('user-settings') || this.router.url.includes('notifications') || this.router.url.includes('location-services') || this.router.url.includes('preferences') || this.router.url.includes('study-details'))
            this.location.back();
        }
      });
    });
  }

  /* Check Network connection */
  checkNetworkConn() {
    this.network.onDisconnect().subscribe(() => {
      this.checkNetworkFlag = true;
      this.router.navigate(['/', 'no-connection'], {
        queryParams: {
          errorCode: 'offline'
        }
      });
    });
    this.network.onConnect().subscribe(async () => {
      if (this.checkNetworkFlag) {
        this.checkNetworkFlag = false;
        this.location.back();
      }
    });
  }

  ngOnDestroy(): void {
    this.appOpenInfo.eventType = clickStreamConsts.appCloseEvent;
    this.appOpenInfo.actionType = clickStreamConsts.interaction;
    this.appOpenInfo.pageName = this.commonService.currentPageName;
    this.appOpenInfo.sourcePageName = this.commonService.previousPageName;
    this.clickstreamService.clickStreamLogging(this.appOpenInfo).subscribe();
  }
}
