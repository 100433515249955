import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FavouriteService } from '../../service/favourite.service';
import { clickStreamConsts } from '../../../../utilities/constants/click-stream-const';

interface Content {
  heading: string,
  subHeading: string,
  ctaTitle: string,
  ctaClick?: Function,
  closeCta?: Function,
  heapDataId?: string
}

@Component({
  selector: 'app-anymore-final5-options',
  templateUrl: './anymore-final5-options.component.html',
  styleUrls: ['./anymore-final5-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AnymoreFinal5OptionsComponent implements OnInit {
  @Input() mode: 'noLimit' | 'removeInfo' | 'removeMultiInfo' = 'noLimit';
  @Input() favCount: number = 1;
  @Input() final5_list: any;
  noThanksCtaLabel = "No thanks";
  content: Content;
  constructor(public modalCtrl: ModalController,
    private favouriteService: FavouriteService) { }

  ngOnInit() {
    this.getContent();
  }

  getContent() {
    switch (true) {
      case this.mode === 'noLimit': {
        this.content = {
          heading: "Can’t add any more Final 5 options",
          subHeading: "You've already selected seven courses! Please remove a choice before you add anymore.",
          ctaTitle: "Change Final 5 options",
          heapDataId: "changeFinal5options",
          ctaClick: ({ ctaTitle }: { ctaTitle: string }) => {
            this.modalCtrl.dismiss('true');
            this.favouriteService.logFinal5InteractionClickstream({ eventType: clickStreamConsts.buttonClicked, CTATitle: ctaTitle, sectionName: "Favourites" });
          }
        }
        break;
      }
      case (this.mode === 'removeInfo' || this.mode === 'removeMultiInfo'): {
        const favCount = this.final5_list.filter(f => f.finalChoiceId).length;
        const headingPrefix = (this.mode === 'removeInfo') ? 'This item is' : favCount <= 1 ? 'A favourite is' : (favCount + ' favourites are')
        this.content = {
          heading: headingPrefix + " in your Final 5",
          subHeading: "Removing this item from favourites will also remove it from your Final 5",
          ctaTitle: "Remove from Final 5",
          ctaClick: ({ ctaTitle }: { ctaTitle: string }) => {
            this.removeFinal5Item();
            this.favouriteService.logFinal5InteractionClickstream({ eventType: clickStreamConsts.buttonClicked, CTATitle: ctaTitle, sectionName: "Favourites" });
          }
        }
        break;
      }
    }
  }

  closeCta() {
    this.modalCtrl.dismiss('');
    this.favouriteService.logFinal5InteractionClickstream({ eventType: clickStreamConsts.buttonClicked, CTATitle: this.noThanksCtaLabel });
  }

  removeFinal5Item() {
    this.favouriteService.updateFinalShortListing('favourites', this.final5_list).subscribe(() => {
      this.modalCtrl.dismiss(true);
    });
  }
}
