import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AnalyticsService } from '../../services/analytics.service';
import { constants } from '../../utilities/constants/constants';
import { ModalController, IonTextarea } from '@ionic/angular';
import { EnquirySuccessComponent } from '../enquiry-success/enquiry-success.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Inappbrowser } from '../../utilities/common/inappbrowser';
import { EnquiryData, EnquiryType } from '../../models/enquiry.model';
import { MODALS } from '../../enums/modals.enum';
import { SocialLoginState } from '../../models/social-login-state';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './enquiry-onboarding.component.html',
  styleUrls: ['./enquiry-onboarding.component.scss']
})

export class EnquiryOnboardingComponent implements OnInit {
  @Input() enquiryType: EnquiryType;
  @Input() enquiryData: EnquiryData;
  cntFormGrp: UntypedFormGroup;
  question: any;
  selectedYear: string;
  apiUrl: string = '/yoe';
  contentType = 'appModuleComponent';
  isLoaderEnabled: boolean = false;
  enquiryFlag: boolean = false;
  enquiryDetails: any;
  pageData: any = {
  };
  institutionInfo: any;
  institutionName: any;
  courseName: any;
  textArea: any;
  isTextAreaValueadded: boolean = false;
  showYoeSection: boolean = true;
  clickStreamInfo: any = {
  };
  submitFlag: boolean = false;
  consentFlag: boolean = false;
  @ViewChild('textarea') houseTextArea: IonTextarea;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    private iab: Inappbrowser,
    private analytics: AnalyticsService) {
  }

  ngOnInit() {
    this.enquiryFlag = !!this.enquiryType;
    this.getPageData();
    this.cntFormGrp = this.formBuilder.group({
      userContent: ['', [Validators.required, Validators.minLength(50)]]
    });
    this.getOnBoardingYOE();
    SocialLoginState.clearIfTriggeredByis(this.enquiryType);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.houseTextArea.setFocus();
    }, 1000);
  }

  openInappBrowser(eve) {
    this.iab.openBrowser(eve);
  }

  getPageData() {
    if (this.enquiryFlag) {
      this.getEnquiryDetails();
      this.apiUrl = this.enquiryType?.includes('request_info') ? '/interactions/yoe' : '/yoe';

      if (this.enquiryType?.includes('favourite') || this.enquiryType === "GUEST-LOGIN")
        this.pageData.heading = 'Sign up';
      else if (this.enquiryType?.includes('request_info'))
        this.pageData.heading = 'Request info';
      else
        this.pageData.heading = 'Subscribe';
    }
  }

  getEnquiryDetails = () => {
    this.apiService.getEnquiryDetails(this.enquiryData).subscribe(res => {
      this.enquiryDetails = res?.enquiryDetails;
      this.institutionName = this.enquiryDetails?.institutionDetails?.institutionName;
      this.courseName = this.enquiryDetails?.courseName;
      this.textArea = "I read about " + (this.courseName ? this.courseName + " at " : "") + this.institutionName + " on Whatuni and want to know more about the " + (this.courseName ? "course" : "institution");
    })
  }

  ionViewDidEnter() {
    this.analytics.logEvent(constants.firebaseEvents.screenview, {
      page_name: this.apiUrl
    });
  }

  getOnBoardingYOE(): void {
    this.apiService.getOnBoardingContentful(this.contentType, this.apiUrl, this.enquiryData).subscribe(data => {
      this.question = data;
      this.institutionInfo = data?.enquiryInfo;
      this.showYoeSection = !this.question?.options
        .map(({ optionValue }: { optionValue: string }) => Number(optionValue))
        .some((year: number) => year === Number(data?.fieldValue));
      if (!this.showYoeSection)
        this.selectedYear = data?.fieldValue || null;
    });
  }

  submitEnquiry() {
    const param = {
      coupon: 'interaction_submit_email', location: this.enquiryData.institutionName, page_name: this.enquiryData.pageName, item_name: this.enquiryData.subject
    }
    this.analytics.logEvent(constants.firebaseEvents.ecommerce_purchase, param);
    if (!this.selectedYear || this.cntFormGrp.invalid || !this.consentFlag)
      return
    this.isLoaderEnabled = true;
    const submitData = {
      fieldName: this.question.componentTitle,
      fieldValue: this.selectedYear
    }
    this.apiService.submitOnboardQuestion(submitData, this.enquiryData).subscribe(() => {
      this.isLoaderEnabled = false;
      if (this.enquiryFlag) {
        this.enquiryDetails.userContent = this.cntFormGrp.controls['userContent'].value?.trim();
        this.enquiryFormModal();
      } else { this.router.navigate(['/on-boarding-type']); }
    }, () => {
      this.isLoaderEnabled = false;
    });
  }

  onBlur() {
    if (!this.isTextAreaValueadded) {
      this.isTextAreaValueadded = true;
      if (!this.cntFormGrp.get('userContent')?.value?.trim()?.length)
        this.cntFormGrp.setValue({
          userContent: this.textArea
        });
    }
    this.submitFlag = this.cntFormGrp.invalid;
  }

  onYearSelect(year: any): void {
    this.selectedYear = year;
  }

  async enquiryFormModal() {
    this.clickStreamInfo = this.enquiryDetails?.clickStreamData || {
    };
    const clickStreamCourseInfo = {
      interestedIntakeYear: this.selectedYear,
      subOrderItemId: this.enquiryDetails?.institutionDetails?.subOrderItemId,
      courseId: this.enquiryDetails?.courseId,
      courseName: this.enquiryDetails?.courseName,
      institutionId: this.enquiryDetails?.institutionId
    }
    this.clickStreamInfo = Object.assign(this.clickStreamInfo, clickStreamCourseInfo);
    const modal = await this.modalCtrl.create({
      component: EnquirySuccessComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ENQUIRY_SUCCESS,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryDetails: this.enquiryDetails,
        clickStreamInfo: this.clickStreamInfo
      }
    })
    await modal.present();
    await modal.onWillDismiss().then(res => {
      this.modalCtrl.dismiss({
        dismissed: res?.data?.dismissed, isSubmitted: res?.data?.isSubmitted
      }, null, MODALS.ENQUIRY_YOE);
    })
  }

  modaldismiss(dismissed: boolean = true) {
    setTimeout(() => {
      this.modalCtrl.dismiss({
        dismissed
      });
    }, 400);
  }
}
