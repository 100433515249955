import { Subject } from "rxjs/internal/Subject";
import { clickStreamConsts } from "../utilities/constants/click-stream-const";
import { EnquiryType } from "./enquiry.model";

export type SignedInMethod = 'Facebook' | 'Google' | 'Apple'; // Literal type

interface SocialLoginTriggeredState {
    signedInMethod: SignedInMethod,
    pageName: string,
    pageUrl: string,
    triggeredBy: EnquiryType,
    uniId?: string | number,
    courseId?: string | number,
    eventCalendarItemId?: number,
    data?: any
}

export class SocialLoginState {
    private static socialLoginTriggeredPoint = "socialLoginTriggeredPoint";
    public static socialLoginState$ = new Subject<any>();

    public static loginMethodToCtaMapper = new Map(Object.entries({
        Facebook: clickStreamConsts.ctaTile.facebook,
        Google: clickStreamConsts.ctaTile.google,
        Apple: clickStreamConsts.ctaTile.apple
      }));

    public static set setSocialLoginTriggeredPoint (data: SocialLoginTriggeredState) {
        localStorage.setItem(SocialLoginState.socialLoginTriggeredPoint, JSON.stringify(data));
    }

    public static get getSocialLoginTriggeredPoint (): SocialLoginTriggeredState {
        const data = localStorage.getItem(SocialLoginState.socialLoginTriggeredPoint);
        return JSON.parse(data) || undefined;
    }

    public static clearSocialLoginTriggeredPoint (): void {
        localStorage.removeItem(SocialLoginState.socialLoginTriggeredPoint)
    }

    public static clearIfTriggeredByis (triggeredBy: EnquiryType) {
        if (SocialLoginState.getSocialLoginTriggeredPoint?.triggeredBy === triggeredBy)
            SocialLoginState.clearSocialLoginTriggeredPoint();
    }
}