import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { ApiService } from '../../services/api.service';
import { YourGradesComponent } from 'user-profile';
import { Filter, MultipleFilter } from '../../models/filter.model'
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() searchValues: any;
  @Input() isPRModal: boolean;

  filterData: any;
  courseCount: number = 0;
  collapseAccordion1: any[] = [];
  collapseAccordion: any[] = [];
  filter$: Subscription;
  filterCount$: Subscription;

  appliedFilters = new Filter();
  isChanged: boolean = false;
  prefilterValues = new Filter();

  constructor(private modalCntrl: ModalController,
    private apiService: ApiService,
    private commonService: CommonService) { }

  ngOnInit() {
    if (this.isPRModal) {
      this.prefilterValues = this.commonService.pRFilterValues;
    } else {
      this.prefilterValues = this.commonService.filtersValues;
    }
    this.getFilters(false);
  }

  getFilters(isReset?: boolean): void {
    this.filter$ = this.apiService.getFilterData(this.searchValues).subscribe(res => {
      this.filterData = res;

      this.getPrviouseValue(isReset);
    })//get Filter subscription end
  }

  getPrviouseValue(isReset?: boolean) {
    this.appliedFilters.sortBy.selectedOption = this.filterData?.sortBy?.options.filter(f => f?.optionId === this.prefilterValues?.sortBy?.selectedOption?.optionId && !isReset)[0] || this.filterData?.sortBy?.options?.filter(f => f?.optionId === 'TOP_PICKS' || f?.optionId === 'OUR_TOP_PICKS')[0];

    // Check if Filter opening from PR and Set the Qualcode as Not set
    if (this.searchValues.instid)
      this.appliedFilters.qualification.selectedOption = this.filterData?.qualification?.options.filter(f => f?.optionId === this.prefilterValues?.qualification?.selectedOption?.optionId && !isReset)[0] || '';
    else
      this.appliedFilters.qualification.selectedOption = this.filterData?.qualification?.options.filter(f => f?.optionId === this.prefilterValues?.qualification?.selectedOption?.optionId && !isReset)[0] ||
        this.filterData?.qualification?.options.filter(f => f?.optionId?.toLowerCase() === 'm')[0];

    if (!isReset) {
      if (this.filterData?.studyMode) {
        this.appliedFilters.studyMode.options = this.filterData?.studyMode?.options?.map((element) => {
          this.prefilterValues?.studyMode?.options.forEach((styEle) => {
            element.selectedFlag = this.selectValue(element, styEle);
          });
          return element;
        })//map method end
        this.appliedFilters.studyMode.commaSeperatedValue = this.arrayToString(this.appliedFilters.studyMode.options);
        this.appliedFilters.studyMode.commaSeperatedId = this.prefilterValues?.studyMode?.options.filter(f => f.selectedFlag)?.map(m => m.optionId)?.join(', ');
      }
      this.appliedFilters.score.score = this.filterData?.score?.options || '';
      if (this.filterData?.locationType) {
        this.appliedFilters.locationType.options = this.filterData?.locationType?.options?.map((element) => {
          this.prefilterValues?.locationType?.options.forEach((locEle) => {
            element.selectedFlag = this.selectValue(element, locEle);
          });
          return element;
        })//map method end
        this.appliedFilters.locationType.commaSeperatedValue = this.arrayToString(this.appliedFilters.locationType.options);

        this.appliedFilters.locationType.commaSeperatedId = this.prefilterValues?.locationType?.options.filter(f => f.selectedFlag)?.map(m => m.optionId)?.join(', ');
      }

      if (this.filterData?.region) {
        this.appliedFilters.region.options = this.filterData?.region?.options?.map((element) => {
          this.prefilterValues?.region?.options.forEach((regEle) => {
            element.selectedFlag = this.selectValue(element, regEle);
          });
          return element;
        })//map method end
        this.appliedFilters.region.commaSeperatedValue = this.arrayToString(this.appliedFilters.region.options);

        this.appliedFilters.region.commaSeperatedId = this.prefilterValues?.region?.options.filter(f => f.selectedFlag)?.map(m => m.optionId)?.join(', ');
      }
    }

    this.getCourseCount();
  }

  selectValue(option, filterOption) {
    if (option.optionId === filterOption.optionId)
      return filterOption.selectedFlag || false;

    return option?.selectedFlag;
  }

  /**********your grades modal*************/
  async openYourGradesModal() {
    const modal = await this.modalCntrl.create({
      component: YourGradesComponent,
      cssClass: 'my-modal-styles',
      componentProps: {
        yourGradesFromFilter: this.commonService.yourGrade || null,
        sourcePage: "filter",
        isNotSet: Number(this.filterData?.score?.options) === 0
      }
    });
    modal.onWillDismiss().then(res => {
      if (res?.data?.UCASPoints) this.isChanged = true
      this.appliedFilters.score.score = Number(res?.data?.UCASPoints) || this.appliedFilters.score.score;
      this.filterData.score.options = Number(res?.data?.UCASPoints) || this.appliedFilters.score.score;
      this.commonService.yourGrade = res?.data?.yourGrades || null;
      if (res?.data?.UCASPoints) this.getCourseCount()
    })
    await modal.present();
  }
  /**********your grades modal*************/

  applyFilters(): void {
    if (this.isPRModal)
      this.commonService.pRFilterValues = Object.assign({
      }, this.appliedFilters);
    else
      this.commonService.filtersValues = Object.assign({
      }, this.appliedFilters);

    this.modalCntrl.dismiss({
      filter: this.appliedFilters, course: this.courseCount
    });
    if (!this.isPRModal) {
      const body = {

      }
      this.commonService.filterSubject.next(body);
    }
  }

  onSelect(obj: any, isNotGetCourseCountRequre?: boolean): void {
    this.isChanged = true;
    obj.isFilterSelected = true;
    if (!isNotGetCourseCountRequre)
      this.getCourseCount()
  }

  onLocationCheckBoxSelect(event?: any, obj?: any) {
    this.isChanged = true;
    this.appliedFilters.locationType.commaSeperatedValue = this.arrayToString(obj);
    this.appliedFilters.locationType.commaSeperatedId = obj.filter((ele: any) => ele.selectedFlag && ele?.optionId).map((obj) => obj.optionId).join(', ');
    if (!this.appliedFilters.locationType.isFilterSelected) {
      this.appliedFilters.locationType.isFilterSelected = true;
      this.appliedFilters.appliedFiltersCount++;
    } else if (obj.filter(x => x.selectedFlag).length === 0) {
      this.appliedFilters.locationType.isFilterSelected = false;
      this.appliedFilters.appliedFiltersCount--;
    }
    this.getCourseCount();
  }

  applyFilterChange(filter?: MultipleFilter, obj?: any) {
    filter.commaSeperatedValue = this.arrayToString(obj);

    filter.commaSeperatedId = obj.filter(f => f.selectedFlag)?.map(ele => ele.optionId)?.join(', ');

    if (!filter.isFilterSelected) {
      filter.isFilterSelected = true;
      this.appliedFilters.appliedFiltersCount++;
    } else if (obj.filter(x => x.selectedFlag).length === 0) {
      filter.isFilterSelected = false;
      this.appliedFilters.appliedFiltersCount--;
    }
    this.getCourseCount();
  }

  onCheckBoxSelect(event?: any, obj?: any, type?) {
    this.isChanged = true;
    if (type === 'region')
      this.applyFilterChange(this.appliedFilters.region, obj);
    else if (type === 'studyMode')
      this.applyFilterChange(this.appliedFilters.studyMode, obj);
    else
      this.applyFilterChange(this.appliedFilters.locationType, obj);
  }

  arrayToString(arr: any[]): string {
    return arr.filter((ele) => ele.selectedFlag && ele?.optionDesc).map((obj) => obj.optionDesc).join(', ');
  }

  getCourseCount() {
    this.filterCount$ = this.apiService.getFilteredCourseCount(this.searchValues, this.appliedFilters).subscribe(res => {
      this.courseCount = res?.courseCount;
    })
  }

  trackByFn(index, obj) {
    return obj?.optionId;
  }

  reset(): void {
    this.isChanged = true;
    this.getFilters(true);
    this.appliedFilters = new Filter(); // For remove all preset values
    this.getCourseCount();
  }

  pannelChange1(e: any) {
    if (e?.detail?.value?.length >= 0)
      this.collapseAccordion1 = e?.detail?.value;
  }

  pannelChange(e: any) {
    if (e?.detail?.value?.length >= 0)
      this.collapseAccordion = e?.detail?.value;
  }

  modalDismiss() {
    this.modalCntrl.dismiss();
  }

  ionViewDidLeave() {
    this.filter$?.unsubscribe();
    this.filterCount$?.unsubscribe();
  }
}
