import { animate, query, style, transition, trigger, stagger } from "@angular/animations";

export const heightAnimateInOut = trigger("inOutPaneAnimation", [
    transition(":enter", [
        style({ opacity: 0, height: "0px", paddingTop: "0px", paddingBottom: "0px" }), //apply default styles before animation starts
        animate(
            "300ms ease-in-out",
            style({ opacity: 1, height: "*", paddingTop: "*", paddingBottom: "*" })
        )
    ]),
    transition(":leave", [
        style({ opacity: 1, height: "*", paddingTop: "*", paddingBottom: "*" }), //apply default styles before animation starts
        animate(
            "300ms ease-in-out",
            style({ opacity: 0, height: "0px", paddingTop: "0px", paddingBottom: "0px" })
        )
    ])
]);

export const heightSmoothAnimateInOut = trigger("inOutHeightAnimation", [
    transition(":enter", [
        style({ height: "0px", overflow: 'hidden' }), //apply default styles before animation starts
        animate(
            "300ms ease-in-out",
            style({ height: "*", overflow: 'visible' })
        )
    ]),
    transition(":leave", [
        style({ height: "*" }), //apply default styles before animation starts
        animate(
            "300ms ease-in-out",
            style({ height: "0px" })
        )
    ])
]);

export const fadeInAnimation = trigger('fadeIn', [
    transition('void => *', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
    ])
]);

export const staggerAnimation = trigger('stagger', [
    transition('* => *', [
        query('ion-item-sliding', [
            style({ opacity: 0, scale: 0.9 }),
            stagger(80, [
                animate('0.5s cubic-bezier(0.35, 0, 0.25, 1)',
                    style({ opacity: 1, scale: 1 }))
            ])
        ], { optional: true }
        )
    ])
]);

export const staggerAnimationForOpenDay = trigger('stagger', [
    transition('* => *', [
        query('app-open-day-results', [
            style({ opacity: 0, scale: 0.9 }),
            stagger(80, [
                animate('0.5s cubic-bezier(0.35, 0, 0.25, 1)',
                    style({ opacity: 1, scale: 1 }))
            ])
        ], { optional: true }
        )
    ])
]);

export const dynamicHeightAnimation = trigger("dynamicHeight", [
    transition("* <=> *", [
        animate("300ms ease-in-out")
    ])
    // transition(":leave", [
    //     style({ opacity: 1, height: "*", paddingTop: "*", paddingBottom: "*" }), //apply default styles before animation starts
    //     animate(
    //         "300ms ease-in-out",
    //         style({ opacity: 0, height: "0px", paddingTop: "0px", paddingBottom: "0px" })
    //     )
    // ])
]);