import { Injectable } from '@angular/core';
import { ClickStream, ClickStreamNamedParams } from '../../../models/click-stream';
import { ClickstreamService } from '../../../services/clickstream.service';
import { CommonService } from '../../../services/common.service';
import { clickStreamConsts } from '../../../utilities/constants/click-stream-const';
import { FavouriteUniCourses } from '../models/favourites.model';
import { MODALS } from '../../../enums/modals.enum';
import { WuniModalControllerService } from '../../../services/wuni-modal-controller.service';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { apiEndpointURI } from '../../../utilities/constants/api-endpoints-uri'
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FavouriteService {
  FavAndFinal5Count: any;
  getFinalCountLister = new Subject<any>();
  VIEW_FINALSHORTLIST_URL = environment.API_END_POINT + apiEndpointURI.viewFinalShortListURL;
  SAVE_FINALSHORTLIST_URL = environment.API_END_POINT + apiEndpointURI.addToFinalShortListingURL;
  UPDATE_FINALSHORTLIST_URL = environment.API_END_POINT + apiEndpointURI.updateFinalShortListingURL;

  constructor(private commonService: CommonService,
    private clickstreamService: ClickstreamService,
    private wuniModalCtrl: WuniModalControllerService,
    public http: HttpClient) { }

  openCourseSearchModal(favouriteUniCourses?: FavouriteUniCourses) {
    this.wuniModalCtrl.openModal(MODALS.COURSE_SEARCH_SELECT, null, favouriteUniCourses);
  }

  logFavouritesClickstream(eventType: string, CTATitle: string, sectionName?: string, favouriteUniCourses?: FavouriteUniCourses) {
    const clickStream = new ClickStream();
    clickStream.actionType = clickStreamConsts.actionType.interaction;
    clickStream.CTATitle = CTATitle;
    clickStream.eventType = eventType;
    clickStream.sectionName = sectionName || undefined;
    clickStream.functionalityName = clickStreamConsts.functionalityName.favourites;

    clickStream.idpcInstitutionId = favouriteUniCourses?.institutionId?.toString();
    clickStream.idpcCourseId = favouriteUniCourses?.courseId?.toString();
    clickStream.idpcCourseName = favouriteUniCourses?.courseName;
    clickStream.institutionCountryName = favouriteUniCourses?.region;

    clickStream.pageName = this.commonService.currentPageName || localStorage.getItem('currentPageName');
    clickStream.sourcePageName = this.commonService.previousPageName || localStorage.getItem('previousPageName');
    this.clickstreamService.log(clickStream).subscribe();
  }

  pageViewedClickstreamLogging(sectionName?: string,
    functionalityName?: string) {
    this.clickstreamService.pageViewedClickstreamLogging(sectionName, functionalityName);
  }

  logFinal5InteractionClickstream({ eventType, CTATitle, sectionName, uniCourseInfo, moreInfo }: ClickStreamNamedParams) {
    const clickstreamData: ClickStream = new ClickStream();

    clickstreamData.eventType = eventType || undefined;
    clickstreamData.sectionName = sectionName || undefined;
    clickstreamData.CTATitle = CTATitle || undefined;
    clickstreamData.functionalityName = clickStreamConsts.functionalityName.final5;
    clickstreamData.actionType = clickStreamConsts.actionType.interaction;

    clickstreamData.idpcInstitutionId = uniCourseInfo?.idpcInstitutionId || uniCourseInfo?.institutionId || undefined;
    // clickstreamData.orderItemId = uniCourseInfo?.orderItemId || undefined;
    // clickstreamData.subOrderItemId = uniCourseInfo?.subOrderItemId || undefined;
    clickstreamData.institutionCountryName = clickStreamConsts.institutionCountryName;

    clickstreamData.idpcCourseId = uniCourseInfo?.idpcCourseId || uniCourseInfo?.courseId || undefined;
    clickstreamData.idpcCourseName = uniCourseInfo?.idpcCourseName || uniCourseInfo?.courseName || undefined;
    clickstreamData.studyLevel = uniCourseInfo?.studyLevel || undefined;
    clickstreamData.studyLevelId = uniCourseInfo?.studyLevelId || undefined;
    clickstreamData.childStudyLevel = uniCourseInfo?.childStudyLevel || undefined;
    clickstreamData.childStudyLevelId = uniCourseInfo?.childStudyLevelId || undefined;

    clickstreamData.SRInstitutionIDOrder = moreInfo?.SRInstitutionIDOrder || undefined;

    return this.clickstreamService.log(clickstreamData).pipe(take(1)).subscribe();
  }

  getFinalFive(): Observable<any> {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)

    const options = {
      params
    };
    return this.http.get(this.VIEW_FINALSHORTLIST_URL, options);
  }

  saveToFinalListing(AddShortlistingRequest: any) {
    const payLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      AddShortlistingRequest
    }
    return this.http.post(this.SAVE_FINALSHORTLIST_URL, payLoad);
  }

  updateFinalShortListing(action: string, updateFinalShortlistingRequestDetails: any[]): Observable<any> {
    const payLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      action,
      updateFinalShortlistingRequestDetails
    }
    return this.http.put(this.UPDATE_FINALSHORTLIST_URL, payLoad);
  }

  emitforGetCount() {
    this.getFinalCountLister.next(true);
  }
}
