import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import { apiEndpointURI, guestApiEndPointURL } from '../utilities/constants/api-endpoints-uri';
import { CommonService } from './common.service';
import { Filter } from '../models/filter.model';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { EnquiryData } from '../models/enquiry.model';
import { SearchType } from '../models/search-tab.enum';
import { map , retry, catchError} from 'rxjs/operators';
import { SocialLoginState } from '../models/social-login-state';
import { QuizData } from '../models/quiz.models';
import { AddFavoriteRequestList } from '../models/favourite-page-data';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  CONTENTFUL_ENDPOINT_URL: string = environment.API_END_POINT + apiEndpointURI.contentfulApiURI
  CHECK_EXIST_USERNAME_URL = environment.API_END_POINT + apiEndpointURI.getUserDetailsApiURI
  GUEST_CHECK_EXIST_USERNAME_URL = environment.API_END_POINT + guestApiEndPointURL.getUserDetailsApiURI
  SUBJECT_LIST_URL: string = environment.API_END_POINT + apiEndpointURI.getSubjectApiURI;
  // Onboarding
  ONBOARDING_CF_URL: string = environment.API_END_POINT + apiEndpointURI.getOnBoardingApiURI;
  SUBMIT_ENDPOINT_URL: string = environment.API_END_POINT + apiEndpointURI.submitUserDataApiURI;
  COURSE_DETAIL_URL: string = environment.API_END_POINT + apiEndpointURI.getCourseDetailsURL;
  GUEST_COURSE_DETAIL_URL: string = environment.API_END_POINT + guestApiEndPointURL.getCourseDetailsURL;
  // UPDATE_BASKET_URL: string = environment.API_END_POINT + apiEndpointURI.updateBasketURI;
  SEARCH_RESULT_URL: string = environment.API_END_POINT + apiEndpointURI.coldStartSearchResultURI;
  SUBJECT_SEARCH_RESULT: string = environment.API_END_POINT + apiEndpointURI.getSubjectSearchResultURL;
  GUEST_SUBJECT_SEARCH_RESULT: string = environment.API_END_POINT + guestApiEndPointURL.getSubjectSearchResultURL;
  INSTITUTION_COURSE_RESULT: string = environment.API_END_POINT + apiEndpointURI.getInstitutionCourseResultsURL;
  GUEST_INSTITUTION_COURSE_RESULT: string = environment.API_END_POINT + guestApiEndPointURL.getInstitutionCourseResultsURL;
  INSTITUTION_SEARCH_RESULT: string = environment.API_END_POINT + apiEndpointURI.getInstitutionResultURL;
  GUEST_INSTITUTION_SEARCH_RESULT: string = environment.API_END_POINT + guestApiEndPointURL.getInstitutionResultURL;

  INSTITUTION_PROFILE_URL: string = environment.API_END_POINT + apiEndpointURI.getInstitutionProfileURL;
  GUEST_INSTITUTION_PROFILE_URL: string = environment.API_END_POINT + guestApiEndPointURL.getInstitutionProfileURL;
  POPULAR_SUBJECTS: string = environment.API_END_POINT + apiEndpointURI.getPopularSubjects;
  CLICK_STREAM_URL: string = environment.API_END_POINT + apiEndpointURI.getClickStreamURI;
  HOME_URL: string = environment.API_END_POINT + apiEndpointURI.homeURI;
  CLEAR_RECENT_SEARCH_URL: string = environment.API_END_POINT + apiEndpointURI.recentSearchURI;
  SUGGESTED_UNIS: string = environment.API_END_POINT + apiEndpointURI.getSuggestedUnis;
  GUEST_SUGGESTED_UNIS: string = environment.API_END_POINT + guestApiEndPointURL.getSuggestedUnis;

  GET_FILTERS_URL: string = environment.API_END_POINT + apiEndpointURI.getFiltersURI;
  APPLY_FILTER_URL: string = environment.API_END_POINT + apiEndpointURI.applyFilterURI;
  VIEW_FAVOURITES_URL: string = environment.API_END_POINT + apiEndpointURI.viewFavourites;
  VIEW_COMPARE_FAVOURITES: string = environment.API_END_POINT + apiEndpointURI.viewCompareFavourites;
  FAVOURITE_INSTITUTION_RESULT_URL: string = environment.API_END_POINT + apiEndpointURI.favouriteInstitutionResultURI;
  INTERACTION_SUBMIT_URL: string = environment.API_END_POINT + apiEndpointURI.interactionSubmitURI;
  FOOTER_CONTENTFUL_URL: string = environment.API_END_POINT + apiEndpointURI.footerURI;
  RECENT_SEARCH_RESULT_URL: string = environment.API_END_POINT + apiEndpointURI.getSearchResult;
  GUEST_RECENT_SEARCH_RESULT_URL: string = environment.API_END_POINT + guestApiEndPointURL.getSearchResult;
  GUEST_CLEAR_RECENT_SEARCH_URL: string = environment.API_END_POINT + guestApiEndPointURL.recentSearchURI;
  ENQUIRY_DETAILS_URL: string = environment.API_END_POINT + apiEndpointURI.getEnquiryDetailsURL;
  GUEST_ENQUIRY_DETAILS_URL: string = environment.API_END_POINT + guestApiEndPointURL.getEnquiryDetailsURL;
  GET_VERSION_URL: string = environment.API_END_POINT + apiEndpointURI.getVersionURL;
  GET_OPENDAY_SECTION_URL: string = environment.API_END_POINT + apiEndpointURI.getVersionURL;
  GET_FAVCOUNT_URL: string = environment.API_END_POINT + apiEndpointURI.getFavURL;
  SAVE_TO_FAVORITES: string = environment.API_END_POINT + apiEndpointURI.saveToFavourites;

  GET_QUIZ_INTRO = environment.API_END_POINT + apiEndpointURI.getQuizIntro;
  GET_QUIZ: string = environment.API_END_POINT + apiEndpointURI.getQuiz;
  SUBMIT_QUIZ = environment.API_END_POINT + apiEndpointURI.submitQuiz;
  GET_QUIZ_RESULTS = environment.API_END_POINT + apiEndpointURI.getQuitResults;

  CAREERS_SEARCH_URL = environment.API_END_POINT + apiEndpointURI.careersSearch;
  CAREERS_SEARCH_GUEST_URL = environment.API_END_POINT + guestApiEndPointURL.careersSearch;

  CAREERS_CAT_URL = environment.API_END_POINT + apiEndpointURI.careersCat;
  CAREERS_CAT_GUEST_URL = environment.API_END_POINT + guestApiEndPointURL.careersCat;

  CAREER_AREA_URL = environment.API_END_POINT + apiEndpointURI.careerArea;
  CAREER_AREA_GUEST_URL = environment.API_END_POINT + guestApiEndPointURL.careerArea;

  JOB_ROLE_URL = environment.API_END_POINT + apiEndpointURI.jobRole;
  JOB_ROLE_GUEST_URL = environment.API_END_POINT + guestApiEndPointURL.jobRole;

  OTHER_JOB_ROLES_URL = environment.API_END_POINT + apiEndpointURI.otherJobs;
  OTHER_JOB_ROLES_GUEST_URL = environment.API_END_POINT + guestApiEndPointURL.otherJobs;

  constructor(public http: HttpClient, private commonService: CommonService, private device: Device, private router: Router) { }

  getContentfulDetails(contentType: string, include?: number, apiurl?: string, enquiryData?: EnquiryData): Observable<any> {
    let params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set("contentType", contentType)
      .set('affiliateId', environment.affiliateId)
      .set("authRequired", false)
    if (include)
      params = params.set("include", include.toString());

    if (apiurl)
      params = params.set("fields.apiUrl", apiurl);

    if (enquiryData?.institutionId)
      params = params.set('institutionId', enquiryData?.institutionId)

    if (enquiryData?.courseId)
      params = params.set('courseId', enquiryData?.courseId)

    if (enquiryData?.eventCalendarItemId)
      params = params.set('eventCalendarItemId', enquiryData?.eventCalendarItemId)

    const options = {
      params
    };
    const url = (apiurl !== '/bottom-bar') ? this.CONTENTFUL_ENDPOINT_URL : this.FOOTER_CONTENTFUL_URL;
    return this.http.get(url, options);
  }

  getOnBoardingContentful(contentType?: string, apiurl?: string, enquiryData?: EnquiryData): Observable<any> {
    let params = new HttpParams()
      .set("affiliateId", environment.affiliateId)
      .set("appFlag", environment.appFlag)
      .set("contentType", contentType);
    if (apiurl)
      params = params.set('fields.apiUrl', apiurl);

    if (enquiryData?.institutionId)
      params = params.set('institutionId', enquiryData?.institutionId);

    if (enquiryData?.courseId)
      params = params.set('courseId', enquiryData?.courseId);

    if (enquiryData?.subOrderItemId)
      params = params.set('subOrderItemId', enquiryData?.subOrderItemId);

    if (enquiryData?.eventCalendarItemId)
      params = params.set('eventCalendarItemId', enquiryData?.eventCalendarItemId);

    const option = {
      params
    }
    return this.http.get(this.ONBOARDING_CF_URL, option);
  }

  getSubjectList(selectedQual?: string): Observable<any> {
    const params = new HttpParams()
      .set("affiliateId", environment.affiliateId)
      .set("appFlag", environment.appFlag)
      .set('studyLevel', selectedQual)
    const option = {
      params
    }
    return this.http.get(this.SUBJECT_LIST_URL, option)
  }

  submitOnboardQuestion(data: any, enquiryData?: EnquiryData, prospectusFlag?: boolean) {
    const dataValue = {
      "affiliateId": environment.affiliateId,
      "appFlag": "Y",
      "mobileUserId": localStorage.getItem('mobileUserId') || undefined,
      // ...submitData,
      "userData": [data],
      "prospectusFlag": prospectusFlag ? "Y" : "N",
      "institutionId": enquiryData?.institutionId,
      "courseId": enquiryData?.courseId,
      "eventCalendarItemId": enquiryData?.eventCalendarItemId
    }
    return this.http.post(this.SUBMIT_ENDPOINT_URL, dataValue);
  }

  submitInteraction(apiUrl: string, enquiryData?: any, data?: any): Observable<any> {
    const dataValue = {
      ...data,
      "affiliateId": environment.affiliateId,
      "appFlag": "Y",
      "institutionId": enquiryData?.institutionId,
      "courseId": enquiryData?.courseId,
      "fields.pageUrl": apiUrl,
      "contentType": "appPageComponent",
      "include": 3,
      "enquiryDetails": enquiryData
    }
    return this.http.post(this.INTERACTION_SUBMIT_URL, dataValue);
  }

  submitPersonal(bodyContet: any) {
    const inPayLoad = {
      ...bodyContet,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag
    }
    return this.http.post(this.SUBMIT_ENDPOINT_URL, inPayLoad);
  }

  clearRecentSearch(): Observable<any> {
    const payLoad = {
      "appFlag": "Y",
      "affiliateId": 220703
    }
    const url = localStorage.getItem("userid") ? this.CLEAR_RECENT_SEARCH_URL : this.GUEST_CLEAR_RECENT_SEARCH_URL;
    return this.http.post(url, payLoad);
  }

  checkExistUserName(userName: string, emailFlag: string, password?: string, pwdFlag?: string): Observable<any> {
    const bodyJson = {
      "userEmail": userName.toLowerCase(),
      "emailFlag": emailFlag || 'N',
      "passwordFlag": pwdFlag || 'N',
      "userPassword": password || '',
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag
    }
    return this.http.post(this.GUEST_CHECK_EXIST_USERNAME_URL, bodyJson);
  }

  getUserid(userEmail: string, cognitoId: string): Observable<any> {
    const httpHeaders = new HttpHeaders()
    const bodyJson = {
      "userEmail": userEmail.toLowerCase(),
      "cognitoFlag": 'Y',
      cognitoId,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "mobileUserId": localStorage.getItem('mobileUserId') || undefined
    }
    let url: string;
    if (localStorage.getItem('userid'))
      url = this.CHECK_EXIST_USERNAME_URL;
    else
      url = this.GUEST_CHECK_EXIST_USERNAME_URL;

    return this.http.post(url, bodyJson, {
      headers: httpHeaders
    });
  }

  getCourseDetails(InstitutionId: number, courseId: number, availabilityId?: number, pageUrl?: string): Observable<any> {
    const userId = localStorage.getItem('userid')
    let params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
      .set('institutionId', InstitutionId)
      .set('courseId', courseId)
      .set('contentType', "appPageComponent");
    if (pageUrl)
      params = params.set('fields.pageUrl', pageUrl)

    if (availabilityId)
      params = params.set('availabilityId', availabilityId)

    const endpointUrl = userId ? this.COURSE_DETAIL_URL : this.GUEST_COURSE_DETAIL_URL;
    const options = {
      params
    };
    return this.http.get(endpointUrl, options);
  }

  // updateShortList(contentType: any, contentId: any, contentName: any, addOrRemove?: boolean): Observable<any> {
  //   const bodyJson = {
  //     "inputFlag": addOrRemove,
  //     contentId,
  //     contentName,
  //     contentType,
  //     "affiliateId": environment.affiliateId,
  //     "appFlag": environment.appFlag
  //   }
  //   return this.http.post(this.SAVE_TO_FAVORITES, bodyJson);
  // }

  saveToFavourites(addFavoriteRequestList: AddFavoriteRequestList[],action?:string): Observable<any> {
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
       action,
      "AddFavoriteRequestList": addFavoriteRequestList
    }
    return this.http.post(this.SAVE_TO_FAVORITES, bodyJson).pipe(retry(2), catchError((error) => {
      // console.error('catchError', error);
      this.router.navigate(['/tab/error'], {
        queryParams: {
          errorCode: error.status
        }
      });
      return throwError(error);
    }));
  }

 
  getHomeData(): Observable<any> {
    const bodyJson = {
      "appFlag": environment.appFlag,
      "affiliateId": environment.affiliateId,
      "contentType": "appPageComponent",
      "fields.pageUrl": "/home-page",
      "include": 3
    }
    return this.http.post(this.HOME_URL, bodyJson);
  }

  //-------Filter Logic-------//
  getFilterData(searchValues?: any): Observable<any> {
    const params = new HttpParams().set('authRequired', false);
    const inPayLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "browseCatId": searchValues?.courseId,
      "browseCatcode": searchValues?.catCode,
      "keyword": searchValues?.course,
      "searchType": searchValues?.searchType || 'COURSE',
      "sortBy": this.commonService.filtersValues?.sortBy?.selectedOption?.optionId || 'TOP_PICKS',
      "score": this.commonService.filtersValues?.score?.score?.toString() || '',
      "regionId": this.commonService.filtersValues?.region?.commaSeperatedId || '',
      "qualCode": this.commonService.filtersValues?.qualification?.selectedOption?.optionId?.toUpperCase() || '',
      "studyModeId": this.commonService.filtersValues?.studyMode?.commaSeperatedId || '',
      "locationTypeId": this.commonService.filtersValues?.locationType?.commaSeperatedId || '',
      "institutionId": searchValues?.instid
    }
    const options = {
      params
    };
    return this.http.post(this.GET_FILTERS_URL, inPayLoad, options);
  }

  getSearchResult(keyword: string, instdata?: any): Observable<any> {
    const params = new HttpParams().set('authRequired', false);
    const bodyJson = {
      keyword,
      "qualification": (instdata?.instid) ? (this.commonService?.pRFilterValues?.qualification?.selectedOption?.optionId?.toUpperCase() || 'M') : this.commonService?.userOnboardingDetails?.getStoredOnboardingDetails()?.selectedQualification,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "institutionId": instdata?.instid
    }
    const options = {
      params
    };
    return this.http.post(this.SEARCH_RESULT_URL, bodyJson, options);
  }

  getRecentSearchResult(): Observable<any> {
    const bodyJson = {
      "qualification": this.commonService?.userOnboardingDetails?.getStoredOnboardingDetails()?.selectedQualification,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag
    }
    const apiUri = localStorage.getItem("userid") ? this.RECENT_SEARCH_RESULT_URL : this.GUEST_RECENT_SEARCH_RESULT_URL;
    return this.http.post(apiUri, bodyJson);
  }

  getSubjectSearchResult(searchObj: any): Observable<any> {
    const bodyJson = {
      "keyword": searchObj?.course,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,

      "browseCatId": searchObj?.courseId,
      "browseCatcode": searchObj?.catCode,
      "basketId": "134",

      "searchType": searchObj?.type,
      "sortBy": this.commonService?.filtersValues?.sortBy?.selectedOption?.optionId || 'TOP_PICKS',
      "score": this.commonService?.filtersValues?.score?.score?.toString(),
      "regionId": this.commonService?.filtersValues?.region?.commaSeperatedId,
      "qualCode": this.commonService?.filtersValues?.qualification?.selectedOption?.optionId?.toUpperCase() || 'M',
      "studyModeId": this.commonService?.filtersValues?.studyMode?.commaSeperatedId,
      "locationTypeId": this.commonService?.filtersValues?.locationType?.commaSeperatedId,
      "pageNo": String(searchObj?.page)
    }
    const url = localStorage.getItem('userid') ? this.SUBJECT_SEARCH_RESULT : this.GUEST_SUBJECT_SEARCH_RESULT;
    return this.http.post(url, bodyJson);
  }

  getInstitutionSearchResult(searchObj: any, pageNo): Observable<any> {
    const bodyJson = {
      "keyword": searchObj?.course,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,

      "browseCatId": searchObj?.courseId,
      "browseCatcode": searchObj?.catCode,
      "pageNo": pageNo?.toString(),
      "basketId": "134",

      "searchType": "UNIVERSITY",
      "sortBy": this.commonService?.filtersValues?.sortBy?.selectedOption?.optionId || 'TOP_PICKS',
      "score": this.commonService?.filtersValues?.score?.score?.toString(),
      "regionId": this.commonService?.filtersValues?.region?.commaSeperatedId,
      "qualCode": this.commonService?.filtersValues?.qualification?.selectedOption?.optionId?.toUpperCase() || "",
      "studyModeId": this.commonService?.filtersValues?.studyMode?.commaSeperatedId,
      "locationTypeId": this.commonService?.filtersValues?.locationType?.commaSeperatedId
    }
    const url = localStorage.getItem('userid') ? this.INSTITUTION_SEARCH_RESULT : this.GUEST_INSTITUTION_SEARCH_RESULT;
    return this.http.post(url, bodyJson);
  }

  getSearchResultPage(searchType: SearchType, searchObj: any, pageNo: number | number[]): Observable<any> {
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "basketId": "134",

      "browseCatId": searchObj?.courseId,
      "browseCatcode": searchObj?.catCode,
      "keyword": searchObj?.course,
      searchType,

      "sortBy": this.commonService?.filtersValues?.sortBy?.selectedOption?.optionId || 'TOP_PICKS',
      "score": this.commonService?.filtersValues?.score?.score?.toString(),
      "regionId": this.commonService?.filtersValues?.region?.commaSeperatedId,
      "qualCode": this.commonService?.filtersValues?.qualification?.selectedOption?.optionId?.toUpperCase() || "",
      "studyModeId": this.commonService?.filtersValues?.studyMode?.commaSeperatedId,
      "locationTypeId": this.commonService?.filtersValues?.locationType?.commaSeperatedId
    }

    let url: string;
    if (searchType === SearchType.SUBJECT)
      url = localStorage.getItem('userid') ? this.SUBJECT_SEARCH_RESULT : this.GUEST_SUBJECT_SEARCH_RESULT;
    else
      url = localStorage.getItem('userid') ? this.INSTITUTION_SEARCH_RESULT : this.GUEST_INSTITUTION_SEARCH_RESULT;

    if (typeof pageNo === 'number') {
      return this.http.post(url, {
        "pageNo": pageNo?.toString(), ...bodyJson
      });
    } else {
      let objArr: Array<Observable<any>> = [];
      pageNo.forEach((pNo: number) => {
        objArr = [...objArr, this.http.post(url, {
          "pageNo": pNo?.toString(), ...bodyJson
        })]
      })
      return forkJoin(objArr).pipe(map((res: any) => {
        const firstObj = res[0];
        res?.forEach((resultObj: any, idx: number) => {
          if (idx !== 0 && searchType === SearchType.SUBJECT)
            firstObj.searchResults = [...firstObj.searchResults, ...resultObj.searchResults];
          else if (idx !== 0)
            firstObj.institutionResults = [...firstObj.institutionResults, ...resultObj.institutionResults];
        });
        return firstObj;
      }));
    }
  }

  getInstitutionCourseResult(data: any): Observable<any> {
    const bodyJson = {
      "keyword": data?.course,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "searchType": "COURSE",
      "browseCatId": data?.courseId,
      "browseCatcode": data?.catCode,
      "institutionId": data?.data?.institutionId?.toString(),
      "pageNo": data?.page?.toString() || '1',
      "sortBy": this.commonService?.filtersValues?.sortBy?.selectedOption?.optionId || 'TOP_PICKS',
      "score": this.commonService?.filtersValues?.score?.score?.toString(),
      "regionId": this.commonService?.filtersValues?.region?.commaSeperatedId,
      "qualCode": this.commonService?.filtersValues?.qualification?.selectedOption?.optionId?.toUpperCase() || 'M',
      "studyModeId": this.commonService?.filtersValues?.studyMode?.commaSeperatedId,
      "locationTypeId": this.commonService?.filtersValues?.locationType?.commaSeperatedId,
      "couseId": data?.data.courseId.toString()
    }
    const url = localStorage.getItem('userid') ? this.INSTITUTION_COURSE_RESULT : this.GUEST_INSTITUTION_COURSE_RESULT;
    return this.http.post(url, bodyJson);
  }

  getInstitutionAllCourses(data: any): Observable<any> {
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "institutionId": data?.institutionId?.toString(),
      "pageNo": data?.page || 1,

      "browseCatId": data?.catid,
      "browseCatcode": data?.catcode,

      "qualCode": this.commonService?.pRFilterValues?.qualification?.selectedOption?.optionId?.toUpperCase() || '',
      "sortBy": this.commonService?.pRFilterValues?.sortBy?.selectedOption?.optionId || 'AZ',
      "locationTypeId": this.commonService?.pRFilterValues?.locationType?.commaSeperatedId || '',
      "studyModeId": this.commonService?.pRFilterValues?.studyMode?.commaSeperatedId
    }
    const url = localStorage.getItem('userid') ? this.INSTITUTION_COURSE_RESULT : this.GUEST_INSTITUTION_COURSE_RESULT;
    if (typeof data?.page === 'number' || typeof data?.page === 'string') {
      return this.http.post(url, bodyJson)
    } else {
      const objArr: Array<Observable<any>> = [];
      data?.page.forEach((pNo: number) => {
        bodyJson.pageNo = pNo?.toString()
        const tmp = {
        };
        Object.assign(tmp, bodyJson)
        objArr.push(this.http.post(url, tmp));
      })
      return forkJoin(objArr).pipe(map((res: any) => {
        const firstObj = res[0];
        res?.forEach((resultObj: any, idx: number) => {
          if (idx !== 0)
            firstObj.providerResults = [...firstObj.providerResults, ...resultObj.providerResults];
        });
        return firstObj;
      }));
    }
  }

  //-------------------API CALL SPLITE----------------------//
  splitedApi(endPoint: string, data?: any): Observable<any> {
    let params = new HttpParams();
    const splitSeg = endPoint.split('/');
    if ((splitSeg.includes('course-like') || splitSeg.includes('uni-you-might-like')) && SocialLoginState?.getSocialLoginTriggeredPoint?.pageUrl)
      params = params.set('authRequired', false)

    const bodyJson = {
      "appFlag": environment.appFlag,
      "affiliateId": environment.affiliateId,
      "contentType": "appPageComponent",
      "fields.pageUrl": "/home-page",
      // "studyLevel": "M",
      ...data
    }
    const options = {
      params
    };
    return this.http.post(environment.API_END_POINT + endPoint, bodyJson, options);
  }
  //-------------------API CALL SPLITE----------------------//

  getFilteredCourseCount(searchValues: any, appliedFiltersTemp?: Filter): Observable<any> {
    const params = new HttpParams().set('authRequired', false);
    const inPayLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "keyword": searchValues?.course,
      "browseCatId": searchValues?.courseId,
      "browseCatcode": searchValues?.catCode,
      "searchType": searchValues?.searchType,
      "sortBy": appliedFiltersTemp?.sortBy?.selectedOption?.optionId || 'TOP_PICKS',
      "score": appliedFiltersTemp?.score?.score.toString() || '0',
      "regionId": appliedFiltersTemp?.region?.commaSeperatedId,
      "qualCode": appliedFiltersTemp?.qualification?.selectedOption?.optionId?.toUpperCase() || "",
      "studyModeId": appliedFiltersTemp?.studyMode?.commaSeperatedId,
      "locationTypeId": appliedFiltersTemp?.locationType?.commaSeperatedId,
      "institutionId": searchValues?.instid
    }
    const options = {
      params
    };
    return this.http.post(this.APPLY_FILTER_URL, inPayLoad, options);
  }

  getFavourites(): Observable<any> {
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag
    }
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
    const options = {
      params
    };
    return this.http.get(this.VIEW_FAVOURITES_URL, options);
  }

  getViewCompareFavorites() {
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag
    }
    return this.http.post(this.VIEW_COMPARE_FAVOURITES, bodyJson);
  }

  // getFavouriteCourseResult(): Observable<any> {
  //   const bodyJson = {
  //     "affiliateId": environment.affiliateId,
  //     "appFlag": environment.appFlag,
  //     "favouriteType": "COURSES",
  //     "contentType": "appPageComponent",
  //     "fields.pageUrl": "/home-page",
  //     "include": 3
  //   }
  //   return this.http.post(this.FAVOURITE_COURSE_RESULT_URL, bodyJson);
  // }

  // getFavouriteInstitutionResult(): Observable<any> {
  //   const bodyJson = {
  //     "affiliateId": environment.affiliateId,
  //     "appFlag": environment.appFlag,
  //     "favouriteType": "INSTITUTIONS",
  //     "contentType": "appPageComponent",
  //     "fields.pageUrl": "/home-page",
  //     "include": 3
  //   }
  //   return this.http.post(this.FAVOURITE_INSTITUTION_RESULT_URL, bodyJson);
  // }

  getInstitutionProfile(institutionId?: any): Observable<any> {
    const bodyJson = {
      institutionId,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "contentType": "appPageComponent",
      "fields.pageUrl": "/institutions/profile",
      "include": 2
    }
    const url = localStorage.getItem('userid') ? this.INSTITUTION_PROFILE_URL : this.GUEST_INSTITUTION_PROFILE_URL;
    return this.http.post(url, bodyJson);
  }

  getPopularSubjects(institutionId?: any): Observable<any> {
    const bodyJson = {
      institutionId,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "contentType": "appPageComponent",
      "include": 3
    }
    return this.http.post(this.POPULAR_SUBJECTS, bodyJson);
  }

  getUniMightLike(data: any): Observable<any> {
    const bodyJson = {
      "institutionId": data?.institutionId,
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "contentType": "appPageComponent",
      "fields.pageUrl": "/institutions/profile",
      "include": 2
    }
    const apiUrl = localStorage.getItem('userid') ? this.SUGGESTED_UNIS : this.GUEST_SUGGESTED_UNIS;
    return this.http.post(apiUrl, bodyJson);
  }

  getData(method: 'GET' | 'POST', url: string, fieldUrl?: string, data?: any): Observable<any> {
    url = environment.API_END_POINT + url;
    const params = this.getHttpParams(fieldUrl);
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "contentType": "appPageComponent",
      "fields.pageUrl": fieldUrl || undefined,
      "include": 2
    }
    const options = {
      params
    };
    return method === 'GET' ? this.http.get(url, options) : this.http.post(url, bodyJson, options);
  }

  getHttpParams(fieldUrl?: string): HttpParams {
    let httpParams = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set("contentType", 'appModuleComponent')
      .set('affiliateId', environment.affiliateId)
    if (fieldUrl)
      httpParams = httpParams.set("fields.apiUrl", fieldUrl);

    return httpParams;
  }

  getEnquiryDetails(enquiryData: EnquiryData): Observable<any> {
    const bodyJson = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "contentType": enquiryData?.courseId ? 'COURSE' : 'INSTITUTION',
      "institutionId": enquiryData?.institutionId,
      "courseId": enquiryData?.courseId,
      "eventCalendarItemId": enquiryData?.eventCalendarItemId
    }
    const apiUrl = localStorage.getItem('userid') ? this.ENQUIRY_DETAILS_URL : this.GUEST_ENQUIRY_DETAILS_URL;
    return this.http.post(apiUrl, bodyJson);
  }

  getVersion(): Observable<any> {
    const params = new HttpParams().set('authRequired', false)
    const bodyJson = {
      "currentVersion": "3.9",
      "affiliateId": 220703,
      "appFlag": "Y",
      "platform": this.device.platform
    }
    const apiUrl = this.GET_VERSION_URL;
    return this.http.post(apiUrl, bodyJson, {
      params
    });
  }

  getFavCount(): Observable<any> {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
    const option = {
      params
    }
    const apiUrl = this.GET_FAVCOUNT_URL;
    return this.http.get(apiUrl, option);
  }

  // Quiz API's
  getQuizIntro(): Observable<any> {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
      .set('fields.name', "WU-APP: Career Guide Matcher landing page")
    const option = {
      params
    }
    const apiUrl = this.GET_QUIZ_INTRO;
    return this.http.get<any>(apiUrl, option);
  }

  getQuiz(): Observable<QuizData> {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
      .set('quizType', "detailed")
      .set('previewFlag', "previewFlag")
    const option = {
      params
    }
    const apiUrl = this.GET_QUIZ;
    return this.http.get<QuizData>(apiUrl, option);
  }

  submitQuiz(answers: string) {
    const payLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      answers
    }
    return this.http.post(this.SUBMIT_QUIZ, payLoad);
  }

  getQuizResults() {
    const payLoad = {
      "affiliateId": environment.affiliateId,
      "appFlag": environment.appFlag,
      "contentType": "appPageComponent",
      "include": 2,
      "fields.pageUrl": "/careers/result-page"
    }
    return this.http.post(this.GET_QUIZ_RESULTS, payLoad);
  }

  // Careers API's
  getCareersCategoryData(subCatId?: number): Observable<any> {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
    const option = {
      params
    }
    let apiUrl = localStorage.getItem('userid') ? this.CAREERS_CAT_URL : this.CAREERS_CAT_GUEST_URL;
    if (subCatId)
      apiUrl = apiUrl + '/' + subCatId;

    return this.http.get(apiUrl, option);
  }

  searchCareers(searchKeyword: string) {
    const dataValue = {
      "affiliateId": environment.affiliateId,
      "appFlag": "Y",
      "keyword": searchKeyword
    }
    const apiUrl = localStorage.getItem('userid') ? this.CAREERS_SEARCH_URL : this.CAREERS_SEARCH_GUEST_URL;
    return this.http.post(apiUrl, dataValue);
  }

  getCareerAreaData(subCategorySlugUrl: any, pageNoAndSortBy: { page: number, sortBy: string }): Observable<any> {
    let params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
      .set("pageNo", pageNoAndSortBy.page)
    if (pageNoAndSortBy.sortBy)
      params = params.set("sortOption", pageNoAndSortBy.sortBy)
    const option = { params };
    let apiUrl = localStorage.getItem('userid') ? this.CAREER_AREA_URL : this.CAREER_AREA_GUEST_URL;
    apiUrl = apiUrl + '/' + subCategorySlugUrl?.toLowerCase();
    return this.http.get(apiUrl, option);
  }

  getJobRoleData(subCategorySlugUrl: string, jobSlugUrl: string) {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
    const option = { params };
    let apiUrl = localStorage.getItem('userid') ? this.JOB_ROLE_URL : this.JOB_ROLE_GUEST_URL;
    apiUrl = apiUrl + '/' + subCategorySlugUrl + '/' + jobSlugUrl;
    return this.http.get(apiUrl, option);
  }

  getOtherJobs(jobRole: any) {
    const params = new HttpParams()
      .set("appFlag", environment.appFlag)
      .set('affiliateId', environment.affiliateId)
      .set('pathwayId', jobRole?.pathwayIdFromSubCat || jobRole?.pathwayId)
      .set('slugUrl', jobRole?.subCategorySlugUrl + jobRole?.jobSlugUrl)
      .set('jobProfileId', jobRole?.jobProfileUniqId)
    const option = {
      params
    }
    const apiUrl = localStorage.getItem('userid') ? this.OTHER_JOB_ROLES_URL : this.OTHER_JOB_ROLES_GUEST_URL;
    // apiUrl = apiUrl + jobRole?.jobSlugUrl;
    return this.http.get(apiUrl, option);
  }
}
