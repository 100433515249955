export interface OnboardingDetails {
    selectedQualification: string;
    selectedSubjects: string;
    selectedYOE: string;
}

export class UserOnboardingDetails implements OnboardingDetails {
    localStorageKey: string = 'userOnboardingDetails';

    selectedQualification: string;
    selectedSubjects: string;
    selectedYOE: string;

    constructor (selectedYOE?: string | null, selectedQualification?: string | null, selectedSubjects?: string | null) {
        this.setInitialOnboardingDetails(selectedYOE, selectedQualification, selectedSubjects);
    }

    public setInitialOnboardingDetails (selectedYOE?: string | null, selectedQualification?: string | null, selectedSubjects?: string | null): void {
        this.selectedYOE = selectedYOE || this.getStoredOnboardingDetails()?.selectedYOE;
        this.selectedQualification = selectedQualification || this.getStoredOnboardingDetails()?.selectedQualification;
        this.selectedSubjects = selectedSubjects || this.getStoredOnboardingDetails()?.selectedSubjects;
        this.storeOnboardingDetails();
    }

    public refreshOnboardinDetails (): void {
        this.selectedYOE = this.getStoredOnboardingDetails()?.selectedYOE;
        this.selectedQualification = this.getStoredOnboardingDetails()?.selectedQualification;
        this.selectedSubjects = this.getStoredOnboardingDetails()?.selectedSubjects;
    }

    public getStoredOnboardingDetails (): OnboardingDetails {
        const userOnboardingDetails: string = localStorage.getItem(this.localStorageKey);
        return userOnboardingDetails ? JSON.parse(userOnboardingDetails) : null;
    }

    public setOnboardingDetails (selectedYOE: string | null, selectedQualification: string | null, selectedSubjects: string | null): void {
        this.selectedYOE = selectedYOE || this.selectedYOE;
        if (selectedQualification && this.selectedQualification !== selectedQualification)
            this.selectedSubjects = null;
         else
            this.selectedSubjects = selectedSubjects || this.selectedSubjects;

        this.selectedQualification = selectedQualification || this.selectedQualification;
        this.storeOnboardingDetails();
    }

    private storeOnboardingDetails (): void {
        const userOnboardingDetails: OnboardingDetails = {
            selectedYOE: this.selectedYOE,
            selectedQualification: this.selectedQualification,
            selectedSubjects: this?.selectedSubjects
        }
        localStorage.setItem(this.localStorageKey, JSON.stringify(userOnboardingDetails));
    }

    public isOnboardingVisisted (): boolean {
        return !!this.selectedQualification;
    }

    public removSelectedSubject (): void {
        this.selectedSubjects = null;
        this.storeOnboardingDetails();
    }

    public removeStoredOnboardingDetails () {
        if (localStorage.getItem(this.localStorageKey))
            localStorage.removeItem(this.localStorageKey);

        this.selectedYOE = null;
        this.selectedQualification = null;
        this.selectedSubjects = null;
    }
}