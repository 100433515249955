import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import * as CryptoJS from 'crypto-js';
import { ApiService } from '../../services/api.service'
import { environment } from '../../../environments/environment'
import { ModalController } from '@ionic/angular';
import { EnquiryOnboardingComponent } from '../enquiry-onboarding/enquiry-onboarding.component';
import { Subscription } from 'rxjs';
import { clickStreamConsts } from '../../utilities/constants/click-stream-const';
import { ClickstreamService } from '../../services/clickstream.service';
import { AnalyticsService } from '../../services/analytics.service';
import { constants } from '../../utilities/constants/constants';
import { EnquiryData, EnquiryTypes } from '../../models/enquiry.model';
import { EnquiryModalComponent } from '../../modals/enquiry-modal/enquiry-modal.component';
import { MODALS } from '../../enums/modals.enum';
import { Auth } from 'aws-amplify';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss']
})
export class SignInPage implements OnInit {
  @Input() enquiryType: EnquiryTypes;
  @Input() modal: ModalController;
  @Input() enquiryData: EnquiryData;

  inCorrectFlag: boolean = false;
  isLoaderEnabled: boolean = false;
  isCurrentPwdShow: boolean = false;
  enquiryFlag: boolean = false;
  forgotPasswordCTA = "Forgot password?";
  pageData: any = {};

  clickStreamPV$: Subscription;
  wuniModalCtrlEmitListner$: Subscription;
  isSuccesdfullyPassUpdated = false;

  signInForm = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  constructor(private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private router: Router,
    private apiService: ApiService,
    private modalCtrl: ModalController,
    private wuniModalCtrl: WuniModalControllerService,
    private clickstreamService: ClickstreamService,
    private analytics: AnalyticsService) { }

  ngOnInit() {
    this.enquiryFlag = !!this.enquiryType;
    // this.getPageData();
    this.pageData.heading = this.commonService.getSignInSignUpModalsHeading('signIn', this.enquiryType);
  }

  ionViewDidEnter() {
    this.commonService.setCurrentPage(clickStreamConsts.signInPage);
    this.clickStreamPV$ = this.clickstreamService.pageViewedClickstreamLogging();
    this.analytics.uxCamScreenView(clickStreamConsts.signInPage);
  }

  async checkUserExist() {
    this.commonService.userOnboardingDetails.removeStoredOnboardingDetails();
    this.isLoaderEnabled = true;
    const username = this.commonService.userDetails.userName || localStorage.getItem('email');
    const password = this.signInForm.controls['password'].value;
    Auth.signIn(username.toLowerCase(), password).then(async res => {
      if (res.username)
        this.getUserInfo(res.username)
      if (this.enquiryType === EnquiryTypes.book_event)
        this.clickstreamService.logButtonClickedEvent('Log in', clickStreamConsts.eventType.signedIn, null, null, null, null, 'Open days');
      else if (this.enquiryType === EnquiryTypes.final_5)
        this.clickstreamService.logButtonClickedEvent('Log in', clickStreamConsts.eventType.signedIn, null, null, null, null, 'Final 5');
    }).catch(error => {
      this.isLoaderEnabled = false;
      const code = error.code;
      console.log(error, 'error');
      switch (code) {
        case 'UsernameExistsException':
          this.inCorrectFlag = true;
          break;
        case 'NotAuthorizedException':
          this.inCorrectFlag = true;
          break;
        case 'InvalidPasswordException':
          this.inCorrectFlag = true;
          break;
        case 'UserNotFoundException':
          this.checkUserExistInDB(username, password);
          break;
        default:
          this.inCorrectFlag = true;
          return false;
      }
    });
  }

  setEmailInHeap(emailAddress: any) {
    (window as any).heap.identify(emailAddress);
    (window as any).heap.addUserProperties({
      "email": emailAddress
    });
  }

  showHide() {
    this.isCurrentPwdShow = !this.isCurrentPwdShow;
    if (this.isCurrentPwdShow)
      this.analytics.pauseEvent();
  }

  getUserInfo(userSubId) {
    localStorage.setItem('userid', userSubId);
    const username = this.commonService.userDetails.userName;
    this.apiService.getUserid(username.toLowerCase(), userSubId).subscribe(res => {
      this.setUserDetails(res);
      localStorage.setItem('userid', 'true');
      localStorage.setItem('userSource', res.userSource);
      this.isLoaderEnabled = false;
      if (this.enquiryType?.includes('favourite') || this.enquiryType === EnquiryTypes.guest_login || this.enquiryType === EnquiryTypes.career_matches || this.enquiryType === EnquiryTypes.final_5)
        this.modaldismiss(true);
      else if (this.enquiryFlag)
        this.enquiryFormModal();
      else if (this.commonService.isOnBoardingVisited())
        this.router.navigate(['/tab/home']);
      else
        this.router.navigate(['/on-boarding']);

      this.setEmailInHeap(username);
      // Firebase log for quiz sucessful signin
      // const source =  this.enquiryData.pageName.includes('quiz') ? 'career-quiz' : this.enquiryData?.pageName === 'event-detail' ? 'open_days': localStorage.getItem('userSource') ? localStorage.getItem('userSource') : 'not-set'
      const param = { page_name: this.enquiryData?.pageName, source: localStorage.getItem('userSource') ? localStorage.getItem('userSource') : 'not-set', method: 'sign-in' }
      if (this.enquiryData.pageName.includes('quiz'))
        param.source = 'career_quiz'
      else if (EnquiryTypes.book_event)
        param.source = 'open_days'
      this.analytics.logEvent(constants.firebaseEvents.signin_submit, param);
    })
  }

  setUserDetails(data?: any): void {
    console.log(data, 'setUserDetails');
    this.commonService.userOnboardingDetails?.setInitialOnboardingDetails(data?.selectedYOE, data?.selectedQualification, data?.selectedSubjects);
  }

  set(keys, value) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }

  checkUserExistInDB(emailId: string, password: string) {
    const pwdEnc = this.set('whatuni12345@#&!', password);
    this.apiService.checkExistUserName(emailId.toLowerCase(), 'N', pwdEnc, 'Y').subscribe(res => {
      if (res?.passwordResponse) {
        this.signUpExistUser(res);
        localStorage.setItem('userid', 'true');
        this.setEmailInHeap(emailId);
        if (res?.selectedQualCode)
          localStorage.setItem('qualCode', res?.selectedQualCode);

        if (this.enquiryType?.includes('favourite') || this.enquiryType === 'GUEST-LOGIN') {
          if (this.enquiryType?.includes('favourite')) {
            const currentUrl = this.router.url;
            this.router.navigateByUrl(currentUrl);
          }
          this.modaldismiss(true);
        } else if (this.enquiryFlag) {
          this.enquiryFormModal();
        } else { this.router.navigate(['tab/home']); }
      } else {
        this.inCorrectFlag = true;
      }
    })
  }

  signUpExistUser(data: any) {
    Auth.signUp({
      username: data.email.toLowerCase(),
      password: this.signInForm.controls['password'].value,
      //optional parameters
      attributes: {
        "custom:app_flag": "N",
        "given_name": data.forename,
        "family_name": data.surname,
        "email": data.email,
        "website": environment.affiliateId.toString()
      }
    }).catch(err => {
      console.log(err, 'err');
    })
  }

  forgotPassword() {
    if (this.enquiryData)
      this.wuniModalCtrl.openModal(MODALS.FORGOT_PASSWORD, null, null, clickStreamConsts.signInPage);

    else
      this.router.navigate(['forgot-password']);
    this.wuniModalCtrl.emittedData.subscribe((res: any) => {
      if ((res.modalId === MODALS.FORGOT_PASSWORD || res.modalId === MODALS.UPDATE_PASSWORD) && res?.data?.updatePassStatus === 'updated')
        this.setPasswordUpdateStatus();
    });
    this.clickstreamService.logForgotPasswordEvents(clickStreamConsts.eventType.forgotPasswordClicked, this.forgotPasswordCTA);
  }

  setPasswordUpdateStatus() {
    this.isSuccesdfullyPassUpdated = true;
    setTimeout(() => {
      this.isSuccesdfullyPassUpdated = false;
    }, 15000);
    console.log(this.isSuccesdfullyPassUpdated)
  }

  async enquiryFormModal() {
    const param = {
      coupon: 'interaction_request_email', location: this.enquiryData.institutionName, page_name: this.enquiryData.pageName, item_name: this.enquiryData.subject
    }
    this.analytics.logEvent(constants.firebaseEvents.ecommerce_purchase, param);
    if (this.enquiryType === EnquiryTypes.book_event) {
      this.modalCtrl.dismiss({
        dismissed: true
      }, null, MODALS.SIGN_IN);
      return;
    }
    const modal = await this.modalCtrl.create({
      component: this.enquiryType === EnquiryTypes.get_prospectus ? EnquiryModalComponent : EnquiryOnboardingComponent,
      cssClass: 'my-modal-styles',
      id: MODALS.ENQUIRY_YOE,
      componentProps: {
        enquiryType: this.enquiryType,
        enquiryData: this.enquiryData,
        previousModalId: MODALS.SIGN_IN
      }
    })
    modal.onWillDismiss().then(res => {
      this.isLoaderEnabled = false;
      if (res?.data?.dismissed)
        this.modaldismiss(res?.data?.dismissed, res?.data?.isSubmitted);
    })
    await modal.present();
  }

  modaldismiss(dismissed?: any, isSubmitted?: any) {
    this.modalCtrl.dismiss({
      dismissed, isSubmitted
    }, null, MODALS.SIGN_IN);
  }

  ionViewWillLeave() {
    this.clickStreamPV$?.unsubscribe();
    this.wuniModalCtrlEmitListner$?.unsubscribe();
  }
}
