import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@services/common.service';
import { GetProspectus } from 'src/app/utilities/common/getprospectus';
import { NavController } from '@ionic/angular';
import { SocialLoginState } from 'src/app/models/social-login-state';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';
import { EnquiryData, EnquiryTypes } from '../../models/enquiry.model';
@Injectable({
  providedIn: 'root'
})
export class DeeplinksService {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private getProspectus: GetProspectus,
    private navCtrl: NavController,
    private wuniModalCtrl: WuniModalControllerService
  ) { }

  navigateLinks(match) {
    //Initialize variables
    let eveid;
    let spitData = [];
    let splittedPath = [];
    let path = match.$link?.path;
    const queryparam = match?.$link?.queryString;

    //Split the url string
    const scheme = match.$link?.scheme;
    spitData = path.split('/');
    splittedPath = path.split('/');

    if (scheme === 'https')
      path = match.$link.path;
    else if (scheme === 'whatuni')
      path = match.$link.url;
    // Track heap
    if (path.includes('campaignName') && path.includes('capaignSource'))
      this.trackByInHeap(path);

    // Handle the links
    switch (this.getLinkUrl(path)) {
      case 'university-profile':
        this.goToIpPage(spitData, queryparam);
        break;
      case 'cd':
        this.gotoCdPage(spitData, queryparam);
        break;
      case 'event-detail':
        eveid = spitData[spitData.length - 1] === '' ? spitData[spitData.length - 2] : spitData[spitData.length - 1];
        this.router.navigate(['/event-detail', eveid]);
        break;
      case 'rating':
        this.commonService.appRate();
        break;
      case 'user-profile':
        this.router.navigate(['/tab/user-profile']);
        break;
      case 'search':
        this.router.navigate(['/tab/search']);
        break;
      case 'open-days':
        this.gotoOpendayPage(spitData,queryparam);
        break;
      case 'career-matcher':
        this.router.navigate(['quiz-intro']);
        break;
      case 'career-guides':
        this.gotoCareerPage(spitData)
        break;
      case 'federated-redirection':
        this.gotoRedirection(match)
        break;
      default:
        break;
    }
  }
  // Function to handle heap tracking for deeplinking
  trackByInHeap(url: string) {
    const params = this.commonService.getParamsFromString(url);
    (window as any).heap.track('PushNotificationClick',
      {
        dollars: 2,
        item: params?.campaignName,
        source: params?.capaignSource, //params?.campaignName
        medium: 'push notification' //params?.capaignSource
      });
  }
  getQueryParams(params, url) {
    const reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');

    const queryString = reg.exec(url);
    console.log(queryString);
    return queryString ? queryString[1] : null;
  };
  // Function to get the names of all the routed deeplinks
  getLinkUrl(path) {
    if (path.includes('university-profile'))
      return 'university-profile';
    else if (path.includes('cd'))
      return 'cd';
    else if (path.includes('event-detail'))
      return 'event-detail';
    else if (path.includes('rating-trigger'))
      return 'rating';
    else if (path.includes('/shortlist?type=1'))
      this.router.navigate(['/tab/shortlist'], {
        queryParams: {
          type: 1
        }
      });
    else if (path.includes('/shortlist?type=2'))
      this.router.navigate(['/tab/shortlist'], {
        queryParams: {
          type: 2
        }
      });
    else if (path.includes('/user-profile') || path.includes('/mywhatuni'))
      return 'user-profile';
    else if (path.includes('/search') || path.includes('/find-university'))
      return 'search';
    else if (path.includes('open-days'))
      return 'open-days';
    else if (path.includes('career-matcher'))
      return 'career-matcher';
    else if (path.includes('career-guides'))
      return 'career-guides';
    else if (path.includes('federated-redirection'))
      return 'federated-redirection';
  }
  // Function to handle the navigation of IP page with and without get prospectus journey
  goToIpPage(spitData, queryparam) {
    const unId = spitData[spitData.length - 1] === '' ? spitData[spitData.length - 2] : spitData[spitData.length - 1];
    this.router.navigate(['/university-profile', unId]);
    if (queryparam?.includes('getprospectus')) {
      const uid = localStorage.getItem('userid') || '';
      if (uid)
        this.getProspectus.openAddressModal(unId, '');
      else
        this.getProspectus.openGetProspectusModal(unId, '');
    }
  }
  // Function to handle the navigation of CD page with and without get prospectus journey
  gotoCdPage(spitData, queryparam) {
    const unId = spitData[spitData.length - 1] === '' ? spitData[spitData.length - 2] : spitData[spitData.length - 1];
    const cid = spitData[spitData.length - 1] === '' ? spitData[spitData.length - 3] : spitData[spitData.length - 2];
    this.router.navigate(['/course-details', unId, cid]);
    if (queryparam?.includes('getprospectus')) {
      const uid = localStorage.getItem('userid') || '';
      if (uid)
        this.getProspectus.openAddressModal(unId, cid);
      else
        this.getProspectus.openGetProspectusModal(unId, cid);
    }
  }
  // Function to handle the navigation of Opendays
  gotoOpendayPage(spitData,param) {
    const eveid = spitData[spitData.length - 1] === '' ? spitData[spitData.length - 2] : spitData[spitData.length - 1];

    if (eveid === 'open-days')
      {this.router.navigate(['/tab/open-days'], {
        queryParams: {
          tab: true
        }
      });}
    else {
      this.router.navigate(['/event-detail', String(eveid)?.trim()]);
    const eventcalendarid = this.getQueryParams('eventcalid', param);
    console.log(param, eventcalendarid);
      if (param?.includes('opendayform')) {
        const enquiryData: EnquiryData = {
          institutionId: eveid,
          institutionName: '',
          eventCalendarItemId: parseInt(eventcalendarid),
          pageUrl: 'event-detail/'+ eveid,
          pageName: 'event-detail'

        }
        this.wuniModalCtrl.openOpenDayBookingForm(enquiryData);
      }
    }
  }
  // Function to handle the navigation of all the career relates pages
  gotoCareerPage(spitData) {
    const joburl = spitData.length >= 2 ? spitData[spitData.length - 1] : '';
    const subcaturl = spitData.length >= 3 ? spitData[spitData.length - 2] : '';
    console.log('careers', joburl, subcaturl);
    this.router.navigate(['careers']);
    if (joburl !== 'career-guides' && subcaturl !== 'career-guides') {
      const jobRole = {
        jobSlugUrl: '/' + joburl
      }
      this.router.navigate(['careers', subcaturl, joburl]);
    } else if (joburl !== 'career-guides' && subcaturl === 'career-guides') {
      const careerData = {
        subCategorySlugUrl: '/' + joburl
      }
      this.router.navigate(['careers', joburl]);
    }
  }
  // Function to handle the redirection while coming from social login
  gotoRedirection(match) {
    if (!match.$args?.error) {
      localStorage.setItem('userid', 'true');
      const internalPath = `${String(match.$route)}?${String(match.$link.queryString)}`;
      document.location.href = internalPath;
    } else if (match.$args?.error) {
      SocialLoginState.socialLoginState$.next({
        error: true, reason: match.$args?.error
      })
    }
  }
}
