import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MODALS } from '../../enums/modals.enum';
import { FavouriteUniCourses } from '../../pages/favourites/models/favourites.model';
import { WuniModalControllerService } from '../../services/wuni-modal-controller.service';
import { Subscription } from 'rxjs';
import { SocialLoginState } from 'src/app/models/social-login-state';
import { EnquiryTypes } from 'src/app/models/enquiry.model';

@Component({
  selector: 'app-favourites-limit-exceeded',
  templateUrl: './favourites-limit-exceeded.component.html',
  styleUrls: ['./favourites-limit-exceeded.component.scss']
})
export class FavouritesLimitExceededComponent implements OnInit {
  @Input() thirtyFirstFavouriteObj: FavouriteUniCourses;
  limitExceedFavPopUp$: Subscription;

  constructor(private wuniModalCtrl: WuniModalControllerService,
    private modalCtrl: ModalController) { }

  ngOnInit(): void {
    SocialLoginState.clearIfTriggeredByis(EnquiryTypes.favourite);
  }

  editFavourites() {
    this.wuniModalCtrl.openModal(MODALS.FAVOURITES_EDIT, null, this.thirtyFirstFavouriteObj);
    this.limitExceedFavPopUp$?.unsubscribe();
    this.limitExceedFavPopUp$ = this.wuniModalCtrl.emittedData.subscribe((res) => {
      if (res?.modalId === MODALS.FAVOURITES_LIMIT_EECEEDS)
        this.modalCtrl.dismiss(null, null, MODALS.FAVOURITES_LIMIT_EECEEDS);
    });
  }

  ngOnDestroy(): void {
    this.limitExceedFavPopUp$?.unsubscribe();
  }
}
