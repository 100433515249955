import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { WuniModalControllerService } from './wuni-modal-controller.service';

@Injectable()

export class ServerErrorInterceptor implements HttpInterceptor {
  verifyAcc$ = new BehaviorSubject<boolean>(true);
  networkDisconnected: boolean = false;

  constructor(private router: Router,
    private wuniModalCtrl: WuniModalControllerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getIdToken(request, next)).pipe(
      retry(1),
      catchError((httpRes: HttpErrorResponse) => {
        const segments = request.url.split('/');
        if (httpRes?.error?.message?.trim()?.toLocaleLowerCase()?.includes('unauthorized access')) { //For unauthorized error
          localStorage.removeItem('userid');
          this.router.navigate(['/']);
        }
        else if ((Number(httpRes?.error?.status) === 500 || Number(httpRes?.error?.status) === 400) &&
          !segments.includes('home') && !segments.includes('competitors') &&
          !segments.includes('clickstream') && !segments.includes('enquiry-details') &&
          !segments.includes('errors') && !segments.includes('review')) {
          this.router.navigate(['/tab/error'], {
            queryParams: {
              errorCode: httpRes.status
            }
          });
          this.wuniModalCtrl.closeAllModals();
        }
        return throwError(httpRes);
      })
    );
  }

  async getIdToken(req: HttpRequest<any>, next: HttpHandler) {
    if (localStorage.getItem("userid") && !req?.params.get('authRequired')) {
      const user = await Auth.currentAuthenticatedUser().then(res => {
        return res;
      }).catch((err) => {
        localStorage.removeItem('userid');
        this.router.navigate(['/']);
        throwError(err);
      });
      //let subId = user.attributes.sub;
      const current = await Auth.currentSession();
      const idToken = current ? current.getIdToken() : null;

      if (!idToken || idToken.getExpiration() * 1000 <= Date.now()) {
        await user.refreshSession(current.getRefreshToken(), (err, session) => {
          if (err)
            throwError(err);

          if (err && err?.message?.toLocaleLowerCase() !== 'network error') {
            localStorage.removeItem('userid');
            this.router.navigate(['/']);
          } else {
            const authReq = req.clone({
              headers: req.headers
                .set('authorization', session.getIdToken().getJwtToken())
                .set('x-api-key', environment.xapikey)
            });
            return next.handle(authReq).toPromise()
          }
        })
      } else {
        req.headers.set('Authorization', current.getIdToken().getJwtToken())
        const authReq = req.clone({
          headers: req.headers
            .set('authorization', current.getIdToken().getJwtToken())
            .set('x-api-key', environment.xapikey)
        });
        return await next.handle(authReq).toPromise()
      }
      if (localStorage.getItem('isSetId'))
        localStorage.removeItem('isSetId');
    } else {
      let authReq = req;
      if (localStorage.getItem('trackSessionId'))
        authReq = req.clone({
          headers: req.headers.set('tracksessionid', localStorage.getItem('trackSessionId')).set('x-api-key', environment.xapikey),
          params: req.params.delete('authRequired')
        });
      else
        authReq = req.clone({
          headers: req.headers.set('x-api-key', environment.xapikey),
          params: req.params.delete('authRequired')
        });

      return await next.handle(authReq).toPromise()
    }
  }
}
